export const bumperFilters = [

    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "CAR T-2", value: "CAR T-2" },
                    { label: "OMNI", value: "OMNI" },
                    { label: "EECO", value: "EECO" },
                    { label: "ALTO T-2", value: "ALTO T-2" },
                    { label: "SWIFT T-2 FRONT", value: "SWIFT T-2 FRONT" },
                    { label: "SWIFT T-3", value: "SWIFT T-3" },
                    { label: "SWIFT T-4", value: "SWIFT T-4" },
                    { label: "SWIFT T-5", value: "SWIFT T-5" },
                    { label: "K10 T-1", value: "K10 T-1" },
                    { label: "WAGONR T-3", value: "WAGONR T-3" },
                    { label: "WAGONR T-5", value: "WAGONR T-5" },
                    { label: "DZIRE T-1", value: "DZIRE T-1" },
                    { label: "DZIRE T-2", value: "DZIRE T-2" },
                    { label: "DZIRE T-3", value: "DZIRE T-3" },
                    { label: "DZIRE T2/3", value: "DZIRE T2/3" },
                    { label: "DZIRE T-4", value: "DZIRE T-4" },
                    { label: "DZIRE T-5", value: "DZIRE T-5" },
                    { label: "ALTO 800 T1", value: "ALTO 800 T1" },
                    { label: "ALTO 800 T2", value: "ALTO 800 T2" },
                    { label: "ALTO 800 T3", value: "ALTO 800 T3" },
                    { label: "ALTO 800 T1/2/3", value: "ALTO 800 T1/2/3" },
                    { label: "CELERIO T1", value: "CELERIO T1" },
                    { label: "ERTIGA T1", value: "ERTIGA T1" },
                    { label: "ERTIGA T2", value: "ERTIGA T2" },
                    { label: "ERTIGA T1/2", value: "ERTIGA T1/2" },
                    { label: "BALENO T1", value: "BALENO T1" },
                    { label: "BALENO T2", value: "BALENO T2" },
                    { label: "BREZZA", value: "BREZZA" },
                    { label: "CIAZ T-1", value: "CIAZ T-1 FRONT" },

                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "XING", value: "XING" },
                    { label: "ACCENT", value: "ACCENT" },
                    { label: "VERNA FLUIDIC", value: "VERNA FLUIDIC" },
                    { label: "VERNA FLUDIC T3", value: "VERNA FLUDIC T3" },
                    { label: "VERNA T-1", value: "VERNA T-1" },
                    { label: "EON", value: "EON" },
                    { label: "I 10 T1", value: "I 10 T1" },
                    { label: "I 10 T2", value: "I 10 T2" },
                    { label: "I10 GRAND T1", value: "I10 GRAND T1" },
                    { label: "XCENT T2", value: "XCENT T2" },
                    { label: "I10 GRAND T2", value: "I10 GRAND T2" },
                    { label: "ELANTRA FLUIDIC", value: "ELANTRA FLUIDIC" },




                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "INNOVA T1", value: "INNOVA T1" },
                    { label: "INNOVA T2", value: "INNOVA T2" },
                    { label: "INNOVA T-2/3/4", value: "INNOVA T-2/3/4" },
                    { label: "INNOVA T-3", value: "INNOVA T-3" },
                    { label: "INNOVA T4", value: "INNOVA T4" },
                    { label: "INNOVA CRYSTA", value: "INNOVA CRYSTA" },
                    { label: "FORTUNER T1", value: "FORTUNER T1" },
                    { label: "FORTUNER T2", value: "FORTUNER T2" },
                    { label: "FORTUNER T3", value: "FORTUNER T3" },
                    { label: "ETIOS/LIVA T1", value: "ETIOS/LIVA T1" },
                    { label: "ETIOS/LIVA PLATINUM", value: "ETIOS/LIVA PLATINUM" },
                    { label: "ETIOS LIVA", value: "ETIOS LIVA" },
                    { label: "ALTIS T-1", value: "ALTIS T-1" },
                    { label: "ALTIS T-2", value: "ALTIS T-2" },
                    { label: "ALTIS T-3", value: "ALTIS T-3" }


                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "CITY T-3", value: "CITY T-3" },
                    { label: "CITY T-4/ZX", value: "CITY T-4/ZX" },
                    { label: "CITY T-5", value: "CITY T-5" },
                    { label: "CITY T-6", value: "CITY T-6" },
                    { label: "CITY T-7", value: "CITY T-7" },
                    { label: "CITY T-8", value: "CITY T-8" },
                    { label: "CITY T-9", value: "CITY T-9" },
                    { label: "CIVIC T-1", value: "CIVIC T-1" },
                    { label: "CIVIC T1/2", value: "CIVIC T1/2" },
                    { label: "CIVIC T-2", value: "CIVIC T-2" },
                    { label: "CRV T3", value: "CRV T3" },
                    { label: "ACCORD T4", value: "ACCORD T4" },
                    { label: "ACCORD T2/T3", value: "ACCORD T2/T3" },
                    { label: "AMAZE", value: "AMAZE" },
                    { label: "MOBILIO", value: "MOBILIO" },
                    { label: "JAZZ T3", value: "JAZZ T3" }

                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "INDIGO CS", value: "INDIGO CS" },
                    { label: "INDICA EV2", value: "INDICA EV2" },
                    { label: "INDICA XETA", value: "INDICA XETA" },
                    { label: "INDIGO ECS", value: "INDIGO ECS" },
                    { label: "TATA ACE", value: "TATA ACE" },
                    { label: "TATA ACE HT", value: "TATA ACE HT" },
                    { label: "TIAGO/TIGOR T1", value: "TIAGO/TIGOR T1" },
                    { label: "TIAGO T1", value: "TIAGO T1" },
                    { label: "TIGOR T1", value: "TIGOR T1" },
                    { label: "TIAGO/TIGOR T2", value: "TIAGO/TIGOR T2" },
                    { label: "TIGOR T2", value: "TIGOR T2" },
                    { label: "NANO T1 STD", value: "NANO T1 STD" },
                    { label: "NANO T1 DLX", value: "NANO T1 DLX" },
                    { label: "NANO T2 GEN-X", value: "NANO T2 GEN-X" },
                    { label: "NANO STD/DLX", value: "NANO STD/DLX" },
                    { label: "VISTA/MANZA T2", value: "VISTA/MANZA T2" },
                    { label: "VISTA WITH REFLECTOR", value: "VISTA WITH REFLECTOR" },
                    { label: "MANZA", value: "MANZA" },
                    { label: "ZEST", value: "ZEST" },
                    { label: "BOLT", value: "BOLT" },
                    { label: "ALTROZ", value: "ALTROZ" },
                    { label: "HARRIER", value: "HARRIER" }


                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "M HAWK", value: "M HAWK" },
                    { label: "SCORPIO S2", value: "SCORPIO S2" },
                    { label: "SCORPIO S10", value: "SCORPIO S10" },
                    { label: "SCORPIO S11", value: "SCORPIO S11" },
                    { label: "SCORPIO CLASSIC", value: "SCORPIO CLASSIC" },
                    { label: "XYLO T-1", value: "XYLO T-1" },
                    { label: "XYLO", value: "XYLO" }


                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO T-1", value: "POLO T-1" },
                    { label: "POLO T-2", value: "POLO T-2" },
                    { label: "POLO T-3", value: "POLO T-3" },
                    { label: "VENTO T-1", value: "VENTO T-1" },
                    { label: "VENTO T-2", value: "VENTO T-2" },
                    { label: "JETTA T2", value: "JETTA T2" },
                    { label: "LIP GUARD/SPOILER", value: "LIP GUARD/SPOILER" },


                ],
            },
            // {
            //     label: "GM",
            //     value: "GM",
            //     submodel: [
            //         { label: "CRUZE", value: "CRUZE" },
            //         { label: "CRUZE T-2", value: "CRUZE T-2" },
            //         { label: "AVEO", value: "AVEO" },
            //         { label: "BEAT", value: "BEAT" },
            //         { label: "UVA", value: "UVA" },
            //         { label: "SPARK", value: "SPARK" },
            //         { label: "SAIL WITH UPPER GRILL", value: "SAIL WITH UPPER GRILL" },
            //         { label: "ENJOY", value: "ENJOY" },
            //         { label: "OPTRA T-2", value: "OPTRA T-2" },
            //         { label: "OPTRA MAGNUM", value: "OPTRA MAGNUM" },
            //         { label: "OPTRA T1/T2", value: "OPTRA T1/T2" }
            //     ],
            // },
            // {
            //     label: "Renault",
            //     value: "Renault",
            //     submodel: [
            //         { label: "Duster (2008)", value: "Duster (2008)" },
            //     ],
            // },
            {
                label: "GM",
                value: "GM",
                submodel: [
                    { label: "CRUZE", value: "CRUZE" },
                    { label: "CRUZE T-2", value: "CRUZE T-2" },
                    { label: "AVEO", value: "AVEO" },
                    { label: "BEAT", value: "BEAT" },
                    { label: "UVA", value: "UVA" },
                    { label: "SPARK", value: "SPARK" },
                    { label: "SAIL WITH UPPER GRILL", value: "SAIL WITH UPPER GRILL" },
                    { label: "ENJOY", value: "ENJOY" },
                    { label: "OPTRA T-2", value: "OPTRA T-2" },
                    { label: "OPTRA MAGNUM", value: "OPTRA MAGNUM" },
                    { label: "OPTRA T1/T2", value: "OPTRA T1/T2" }


                ],
            },
            {
                label: "SKD",
                value: "SKD",
                submodel: [
                    { label: "FABIA T-1", value: "FABIA T-1" },
                    { label: "FABIA T-2", value: "FABIA T-2" },
                    { label: "RAPID T-1", value: "RAPID T-1" },
                    { label: "LAURA T2", value: "LAURA T2" },
                    { label: "OCTAVIA T2", value: "OCTAVIA T2" },
                    { label: "OCTAVIA T3", value: "OCTAVIA T3" },
                    { label: "SUPERB T2", value: "SUPERB T2" }


                ],
            },
            {
                label: "NSN",
                value: "NSN",
                submodel: [
                    { label: "SUNNY T-1", value: "SUNNY T-1" },
                    { label: "SUNNY T2", value: "SUNNY T2" },
                    { label: "MICRA", value: "MICRA" },
                    { label: "TERRANO", value: "TERRANO" }


                ],
            },


            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "DUSTER", value: "DUSTER" },



                ],
            },

            {
                label: "FRD",
                value: "FRD",
                submodel: [
                    { label: "ECOSPORT T1", value: "ECOSPORT T1" },
                    { label: "ECOSPORT T2", value: "ECOSPORT T2" },
                    { label: "ECOSPORT T1/2", value: "ECOSPORT T1/2" }



                ],
            },


            {
                label: "AUDI",
                value: "AUDI",
                submodel: [
                    { label: "AUDI A4 (2009)", value: "AUDI A4 (2009)" },
                    { label: "AUDI A4 (2013)", value: "AUDI A4 (2013)" },
                    { label: "AUDI A6 (2009)", value: "AUDI A6 (2009)" },
                    { label: "AUDI A6 (2013)", value: "AUDI A6 (2013)" },
                    { label: "AUDI Q5 (2012)", value: "AUDI Q5 (2012)" },
                    { label: "AUDI Q7", value: "AUDI Q7" }




                ],
            },

            {
                label: "BMW",
                value: "BMW",
                submodel: [
                    { label: "BMW 3 SERIES (2008)", value: "BMW 3 SERIES (2008)" },
                    { label: "BMW 5 SERIES (2010)", value: "BMW 5 SERIES (2010)" },
                    { label: "BMW X1", value: "BMW X1" },





                ],
            },
            {
                label: "T.MEMBE R",
                value: "T.MEMBE R",
                submodel: [
                    { label: "TIE MEMBER I10", value: "TIE MEMBER I10" },
                    { label: "TIE MEMBER I20", value: "TIE MEMBER I20" },
                    { label: "TIE MEMBER CRETA", value: "TIE MEMBER CRETA" },
                    { label: "TIE MEMBER POLO", value: "TIE MEMBER POLO" },
                    { label: "TIE MEMBER VERNA FLUIDIC T1", value: "TIE MEMBER VERNA FLUIDIC T1" },
                    { label: "TIE MEMBER VERNA FLUIDIC T3", value: "TIE MEMBER VERNA FLUIDIC T3" }





                ],
            },
        ],
    },
];

export const FENDERLININGFilter = [
    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "SWIFT T1/T2/DZIRE T1", value: "SWIFT T1/T2/DZIRE T1" },
                    { label: "SWIFT T3/T4/DZIRE T2/T3", value: "SWIFT T3/T4/DZIRE T2/T3" },
                    { label: "SWIFT T5/T6/DZIRE T4/T5", value: "SWIFT T5/T6/DZIRE T4/T5" },
                    { label: "K-SERIES", value: "K-SERIES" },
                    { label: "WAGONR T6", value: "WAGONR T6" },
                    { label: "CAR 800", value: "CAR 800" },
                    { label: "ALTO", value: "ALTO" },
                    { label: "ZEN", value: "ZEN" },
                    { label: "ALTO 800 T1/T2/T3", value: "ALTO 800 T1/T2/T3" },
                    { label: "ALTO K-10 T2", value: "ALTO K-10 T2" },
                    { label: "BALENO/GLANZA", value: "BALENO/GLANZA" },
                    { label: "BREZZA", value: "BREZZA" },
                    { label: "ERTIGA", value: "ERTIGA" }


                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "i10 GRAND T1/XCENT", value: "i10 GRAND T1/XCENT" },
                    { label: "i10 GRAND T2", value: "i10 GRAND T2" },
                    { label: "i10 T1/2", value: "i10 T1/2" },
                    { label: "I20", value: "I20" },
                    { label: "EON", value: "EON" },
                    { label: "VERNA T1", value: "VERNA T1" },
                    { label: "VERNA FLUIDIC T1", value: "VERNA FLUIDIC T1" },
                    { label: "VERNA FLUIDIC T3", value: "VERNA FLUIDIC T3" },
                    { label: "ELANTRA FLUIDIC", value: "ELANTRA FLUIDIC" },
                    { label: "CRETA T1/T2", value: "CRETA T1/T2" }


                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "INNOVA", value: "INNOVA" },
                    { label: "ETIOS", value: "ETIOS" },
                    { label: "ALTIS T1/T2", value: "ALTIS T1/T2" },
                    { label: "ALTIS T3", value: "ALTIS T3" },
                    { label: "FORTUNER T1/T2", value: "FORTUNER T1/T2" },
                    { label: "FORTUNER T3", value: "FORTUNER T3" }

                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "CITY T3/T4", value: "CITY T3/T4" },
                    { label: "CITY T5/T6", value: "CITY T5/T6" },
                    { label: "CITY T7/T8", value: "CITY T7/T8" },
                    { label: "CIVIC", value: "CIVIC" },
                    { label: "AMAZE T1/T2", value: "AMAZE T1/T2" },
                    { label: "WRV", value: "WRV" }


                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "TIAGO/TIGOR", value: "TIAGO/TIGOR" },
                    { label: "NEXON", value: "NEXON" }

                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "SCORPIO S-SERIES", value: "SCORPIO S-SERIES" },

                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "Duster", value: "Duster" },
                    { label: "KWID", value: "KWID" },

                ],
            },
            {
                label: "NSN",
                value: "NSN",
                submodel: [
                    { label: "MICRA", value: "MICRA" },
                    { label: "KWISUNNY T1/T2", value: "SUNNY T1/T2" },

                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO/VENTO/RAPID/FABIA", value: "POLO/VENTO/RAPID/FABIA" },

                ],
            },
            {
                label: "GM",
                value: "GM",
                submodel: [
                    { label: "CRUZE T1/T2", value: "CRUZE T1/T2" },
                    { label: "AVEO", value: "AVEO" },
                    { label: "ECOSPORT", value: "ECOSPORT" },


                ],
            },
            {
                label: "FORD",
                value: "FORD",
                submodel: [
                    { label: "ECOSPORT  RH/LH", value: "ECOSPORT  RH/LH" },


                ],
            },
        ],
    },
];


export const FRONTGRILLSANDBUMPERGRILLSFilter = [
    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "BUMPER GRILLE SWIFT T2/DZIRE T1", value: "BUMPER GRILLE SWIFT T2/DZIRE T1" },
                    { label: "BUMPER GRILLE SWIFT T3", value: "BUMPER GRILLE SWIFT T3" },
                    { label: "BUMPER GRILLE SWIFT T4", value: "BUMPER GRILLE SWIFT T4" },
                    { label: "BUMPER GRILLE DZIRE T2", value: "BUMPER GRILLE DZIRE T2" },
                    { label: "BUMPER GRILLE DZIRE T3", value: "BUMPER GRILLE DZIRE T3" },
                    { label: "BUMPER GRILLE BREZZA", value: "BUMPER GRILLE BREZZA" },
                    { label: "BUMPER GRILLE ALTO 800 T1", value: "BUMPER GRILLE ALTO 800 T1" },
                    { label: "BUMPER GRILLE ALTO 800 T2", value: "BUMPER GRILLE ALTO 800 T2" },
                    { label: "BUMPER GRILLE ALTO 800 T3", value: "BUMPER GRILLE ALTO 800 T3" },
                    { label: "BUMPER GRILLE WAGONR T5", value: "BUMPER GRILLE WAGONR T5" },
                    { label: "FRONT GRILLE CELERIO T1", value: "FRONT GRILLE CELERIO T1" },
                    { label: "FRONT GRILLE DZIRE T2", value: "FRONT GRILLE DZIRE T2" },
                    { label: "FRONT GRILLE DZIRE T3 - CHROME", value: "FRONT GRILLE DZIRE T3 - CHROME" },
                    { label: "FRONT GRILLE DZIRE T3 - BLACK", value: "FRONT GRILLE DZIRE T3 - BLACK" },
                    { label: "FRONT GRILLE DZIRE T4 - BASE", value: "FRONT GRILLE DZIRE T4 - BASE" },
                    { label: "FRONT GRILLE DZIRE T4 - CHROME PART", value: "FRONT GRILLE DZIRE T4 - CHROME PART" },
                    { label: "FRONT GRILLE DZIRE T5 - CHROME", value: "FRONT GRILLE DZIRE T5 - CHROME" },
                    { label: "FRONT GRILLE ERTIGA T2", value: "FRONT GRILLE ERTIGA T2" },
                    { label: "FRONT GRILLE ERTIGA T4", value: "FRONT GRILLE ERTIGA T4" },
                    { label: "FRONT GRILLE K10 T2", value: "FRONT GRILLE K10 T2" },
                    { label: "FRONT GRILLE CIAZ T2", value: "FRONT GRILLE CIAZ T2" },
                    { label: "FRONT GRILLE WAGONR T6", value: "FRONT GRILLE WAGONR T6" },
                    { label: "FRONT GRILLE ZEN ESTILO", value: "FRONT GRILLE ZEN ESTILO" },
                    { label: "FRONT GRILLE XL 6", value: "FRONT GRILLE XL 6" },
                    { label: "FRONT GRILLE S-CROSS", value: "FRONT GRILLE S-CROSS" }


                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "BUMPER GRILLE VERNA FLUIDIC T3 - CHROME", value: "BUMPER GRILLE VERNA FLUIDIC T3 - CHROME" },
                    { label: "BUMPER GRILLE VERNA FLUIDIC T3 LOWER", value: "BUMPER GRILLE VERNA FLUIDIC T3 LOWER" },
                    { label: "BUMPER GRILLE I10 T2", value: "BUMPER GRILLE I10 T2" },
                    { label: "FRONT GRILLE I10 T2 - CHROME", value: "FRONT GRILLE I10 T2 - CHROME" },
                    { label: "BUMPER GRILLE I10 GRAND T1 - CHROME", value: "BUMPER GRILLE I10 GRAND T1 - CHROME" },
                    { label: "BUMPER GRILLE I10 GRAND T2", value: "BUMPER GRILLE I10 GRAND T2" },
                    { label: "FRONT GRILLE I10 GRAND T2 - CHROME", value: "FRONT GRILLE I10 GRAND T2 - CHROME" },
                    { label: "BUMPER GRILLE XCENT T2 - CHROME", value: "BUMPER GRILLE XCENT T2 - CHROME" },
                    { label: "BUMPER GRILLE VERNA T1", value: "BUMPER GRILLE VERNA T1" },
                    { label: "BUMPER GRILLE VERNA FLUIDIC T1", value: "BUMPER GRILLE VERNA FLUIDIC T1" },
                    { label: "FRONT GRILLE VERNA FLUIDIC T1", value: "FRONT GRILLE VERNA FLUIDIC T1" },
                    { label: "BUMPER GRILLE ELANTRA FLUIDIC T1", value: "BUMPER GRILLE ELANTRA FLUIDIC T1" },
                    { label: "FRONT GRILLE ELANTRA FLUIDIC T1", value: "FRONT GRILLE ELANTRA FLUIDIC T1" },
                    { label: "FRONT GRILLE CRETA SILVER", value: "FRONT GRILLE CRETA SILVER" },
                    { label: "FRONT GRILLE CRETA CHROME", value: "FRONT GRILLE CRETA CHROME" }


                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "BUMPER GRILLE ETIOS T1", value: "BUMPER GRILLE ETIOS T1" },
                    { label: "FRONT GRILLE INNOVA T4 - CHROME", value: "FRONT GRILLE INNOVA T4 - CHROME" },
                    { label: "FRONT GRILLE INNOVA CRYSTA T1 OEM QLTY", value: "FRONT GRILLE INNOVA CRYSTA T1 OEM QLTY" },
                    { label: "FRONT GRILLE FORTUNER T3 - CHROME", value: "FRONT GRILLE FORTUNER T3 - CHROME" }


                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "FRONT GRILLE SUMO GOLD", value: "FRONT GRILLE SUMO GOLD" },
                    { label: "FRONT GRILLE SAFARI STORME", value: "FRONT GRILLE SAFARI STORME" },
                    { label: "BUMPER GRILLE TIAGO/TIGOR T1", value: "BUMPER GRILLE TIAGO/TIGOR T1" },
                    { label: "FRONT GRILLE TIAGO/TIGOR T1", value: "FRONT GRILLE TIAGO/TIGOR T1" },
                    { label: "FRONT GRILLE TIAGO/TIGOR T2", value: "FRONT GRILLE TIAGO/TIGOR T2" },
                    { label: "FRONT GRILLE NEXON T1", value: "FRONT GRILLE NEXON T1" },
                    { label: "FRONT GRILLE ZEST", value: "FRONT GRILLE ZEST" },
                    { label: "FRONT GRILLE HARRIER", value: "FRONT GRILLE HARRIER" }
                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "BUMPER GRILLE SCORPIO S SERIES", value: "BUMPER GRILLE SCORPIO S SERIES" },
                    { label: "FRONT GRILLE BOLERO T2 - BLACK WITH CHROME", value: "FRONT GRILLE BOLERO T2 - BLACK WITH CHROME" },
                    { label: "FRONT GRILLE BOLERO T2 - WHITE WITH WHITE", value: "FRONT GRILLE BOLERO T2 - WHITE WITH WHITE" },
                    { label: "FRONT GRILLE BOLERO T2 - WHITE WITH CHROME", value: "FRONT GRILLE BOLERO T2 - WHITE WITH CHROME" },
                    { label: "FRONT GRILLE BOLERO T3 - BLACK", value: "FRONT GRILLE BOLERO T3 - BLACK" },
                    { label: "FRONT GRILLE BOLERO T3 - BLACK WITH CHROME", value: "FRONT GRILLE BOLERO T3 - BLACK WITH CHROME" },
                    { label: "FRONT GRILLE BOLERO T3 - WHITE", value: "FRONT GRILLE BOLERO T3 - WHITE" },
                    { label: "FRONT GRILLE BOLERO T3 - WHITE WITH CHROME", value: "FRONT GRILLE BOLERO T3 - WHITE WITH CHROME" },
                    { label: "FRONT GRILLE BOLERO T3 - GREY WITH CHROME", value: "FRONT GRILLE BOLERO T3 - GREY WITH CHROME" },
                    { label: "FRONT GRILLE BOLERO T3 - FULL CHROME", value: "FRONT GRILLE BOLERO T3 - FULL CHROME" },
                    { label: "FRONT GRILLE BOLERO T2 - WHITE W/WHITE NEW LOGO", value: "FRONT GRILLE BOLERO T2 - WHITE W/WHITE NEW LOGO" },
                    { label: "FRONT GRILLE BOLERO T3 - BLACK W/CHROME NEW LOGO", value: "FRONT GRILLE BOLERO T3 - BLACK W/CHROME NEW LOGO" },
                    { label: "FRONT GRILLE BOLERO T3 - WHITE W/CHROME NEW LOGO", value: "FRONT GRILLE BOLERO T3 - WHITE W/CHROME NEW LOGO" }


                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "FRONT GRILLE CITY T3", value: "FRONT GRILLE CITY T3" },
                    { label: "FRONT GRILLE CITY T5", value: "FRONT GRILLE CITY T5" },
                    { label: "BUMPER GRILLE CITY T6", value: "BUMPER GRILLE CITY T6" },
                    { label: "FRONT GRILLE CITY T7", value: "FRONT GRILLE CITY T7" },
                    { label: "FRONT GRILLE MOBILIO", value: "FRONT GRILLE MOBILIO" }

                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "FRONT GRILLE POLO T1/VENTO T1", value: "FRONT GRILLE POLO T1/VENTO T1" },
                    { label: "FRONT GRILLE POLO T2", value: "FRONT GRILLE POLO T2" },
                    { label: "BUMPER GRILL POLO T1 BLACK", value: "BUMPER GRILL POLO T1 BLACK" },
                    { label: "BUMPER GRILLE POLO T1 CHROME", value: "BUMPER GRILLE POLO T1 CHROME" },
                    { label: "BUMPER GRILLE POLO T2 CHROME", value: "BUMPER GRILLE POLO T2 CHROME" },
                    { label: "BUMPER GRILLE VENTO T1 CHROME", value: "BUMPER GRILLE VENTO T1 CHROME" },
                    { label: "BUMPER GRILLE VENTO T2 CHROME", value: "BUMPER GRILLE VENTO T2 CHROME" }


                ],
            },
            {
                label: "GM",
                value: "GM",
                submodel: [
                    { label: "BUMPER GRILLE UPPER CRUZE T1", value: "BUMPER GRILLE UPPER CRUZE T1" },
                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "BUMPER GRILLE DUSTER", value: "BUMPER GRILLE DUSTER" },
                    { label: "FRONT GRILLE DUSTER T2", value: "FRONT GRILLE DUSTER T2" },
                    { label: "FRONT GRILLE KWID T2", value: "FRONT GRILLE KWID T2" },
                ],
            },
            {
                label: "FORD",
                value: "FORD",
                submodel: [
                    { label: "BUMPER GRILLE ECOSPORT BLACK", value: "BUMPER GRILLE ECOSPORT BLACK" },
                    { label: "BUMPER GRILLE ECOSPORT GREY", value: "BUMPER GRILLE ECOSPORT GREY" },
                    { label: "BUMPER GRILLE ECOSPORT CHROME", value: "BUMPER GRILLE ECOSPORT CHROME" },
                    { label: "BUMPER GRILLE ECOSPORT T2 CHROME", value: "BUMPER GRILLE ECOSPORT T2 CHROME" }

                ],
            },
            {
                label: "SKD",
                value: "SKD",
                submodel: [
                    { label: "BUMPER GRILLE OCTAVIA T2", value: "BUMPER GRILLE OCTAVIA T2" },
                    { label: "BUMPER GRILLE OCTAVIA T3", value: "BUMPER GRILLE OCTAVIA T3" },
                    { label: "CHROME STRIP BG OCTAVIA T3", value: "CHROME STRIP BG OCTAVIA T3" }


                ],
            },
            {
                label: "NSN",
                value: "NSN",
                submodel: [
                    { label: "BUMPER GRILLE TERRANO", value: "BUMPER GRILLE TERRANO" },
                    { label: "FRONT GRILLE SUNNY T1 CHROME", value: "FRONT GRILLE SUNNY T1 CHROME" },
                    { label: "FRONT GRILLE SUNNY T2 CHROME", value: "FRONT GRILLE SUNNY T2 CHROME" },
                    { label: "GRILL LAN-CER TYPE-1", value: "GRILL LAN-CER TYPE-1" }

                ],
            },
        ],
    },
];







export const FOGLAMPCOVERSFILTER = [
    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "SWIFT T3 - OPEN/CLOSE RH/LH", value: "SWIFT T3 - OPEN/CLOSE RH/LH" },
                    { label: "SWIFT T4 - BLACK RH/LH", value: "SWIFT T4 - BLACK RH/LH" },
                    { label: "SWIFT T4 - SILVER RH/LH", value: "SWIFT T4 - SILVER RH/LH" },
                    { label: "SWIFT T5 - OPEN/CLOSE RH/LH", value: "SWIFT T5 - OPEN/CLOSE RH/LH" },
                    { label: "DZIRE T3 - CHROME OEM QLTY RH/LH", value: "DZIRE T3 - CHROME OEM QLTY RH/LH" },
                    { label: "ERTIGA T2 - BLACK RH/LH", value: "ERTIGA T2 - BLACK RH/LH" },
                    { label: "ERTIGA T2 - CHROME RH/LH", value: "ERTIGA T2 - CHROME RH/LH" }



                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "INNOVA T3", value: "INNOVA T3" },
                    { label: "INNOVA T4 - BLACK RH/LH", value: "INNOVA T4 - BLACK RH/LH" },
                    { label: "INNOVA T4 - CHROME RH/LH", value: "INNOVA T4 - CHROME RH/LH" }


                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "AMAZE T2", value: "AMAZE T2" },
                    { label: "CITY T6", value: "CITY T6" },
                    { label: "CITY T7", value: "CITY T7" },

                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "I10 GRAND T2 - OPEN - CHROME RH/LH", value: "I10 GRAND T2 - OPEN - CHROME RH/LH" },
                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "I10 GRAND T2", value: "I10 GRAND T2" },
                    { label: "POLO T1", value: "POLO T1" },
                    { label: "POLO T2", value: "POLO T2" },
                    { label: "VENTO T1", value: "VENTO T1" },
                    { label: "VENTO T2", value: "VENTO T2" }


                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "SCORPIO S-11", value: "SCORPIO S-11" },

                ],
            },
            {
                label: "SKD",
                value: "SKD",
                submodel: [
                    { label: "OCTAVIA T2", value: "OCTAVIA T2" },
                    { label: "OCTAVIA T3", value: "OCTAVIA T3" },


                ],
            },

        ],
    },
];
export const FOGLAMPFilter = [

    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "SWIFT", value: "SWIFT" },


                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "INNOVA", value: "INNOVA" },
                    { label: "ETIOS", value: "ETIOS" },

                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "FLUIDIC T1", value: "FLUIDIC T1" },


                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "TIAGO/TIGOR T1", value: "TIAGO/TIGOR T1" },
                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO T1/VENTO T2", value: "POLO T1/VENTO T2" },
                    { label: "POLO T2/AMEO", value: "POLO T2/AMEO" },
                    { label: "VENTO T1", value: "VENTO T1" },


                ],
            },

        ],
    },
];
export const BUMPERBRACKETFilter = [
    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    [
                        { "label": "SWIFT T2 FRONT/DZIRE T1 FRONT", "value": "SWIFT T2 FRONT/DZIRE T1 FRONT" },
                        { "label": "WAGONR K-SERIES FRONT", "value": "WAGONR K-SERIES FRONT" },
                        { "label": "K-10 T1 FRONT", "value": "K-10 T1 FRONT" },
                        { "label": "RITZ FRONT", "value": "RITZ FRONT" },
                        { "label": "SWIFT T3/T4 FRONT/DZIRE T2/T3 FRONT", "value": "SWIFT T3/T4 FRONT/DZIRE T2/T3 FRONT" },
                        { "label": "SWIFT T5/6 DZIRE T4/5 FRONT", "value": "SWIFT T5/6 DZIRE T4/5 FRONT" },
                        { "label": "ALTO 800 T1/2/3 FRONT/K-10 T2", "value": "ALTO 800 T1/2/3 FRONT/K-10 T2" },
                        { "label": "WAGONR T6 FRONT", "value": "WAGONR T6 FRONT" },
                        { "label": "BALENO T2/3 FRONT", "value": "BALENO T2/3 FRONT" },
                        { "label": "BREZZA T1/2 FRONT", "value": "BREZZA T1/2 FRONT" },
                        { "label": "CIAZ FRONT", "value": "CIAZ FRONT" },
                        { "label": "ERTIGA T1/2 FRONT", "value": "ERTIGA T1/2 FRONT" }
                    ]


                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { "label": "I10 T1/T2", "value": "I10 T1/T2" },
                    { "label": "I20 T1", "value": "I20 T1" },
                    { "label": "I20 T2", "value": "I20 T2" },
                    { "label": "I10 GRAND T1/2", "value": "I10 GRAND T1/2" },
                    { "label": "I20 ELITE", "value": "I20 ELITE" },
                    { "label": "EON", "value": "EON" },
                    { "label": "VERNA FLUIDIC", "value": "VERNA FLUIDIC" },
                    { "label": "ELANTRA FLUIDIC", "value": "ELANTRA FLUIDIC" },
                    { "label": "XING", "value": "XING" },
                    { "label": "VERNA T1", "value": "VERNA T1" },
                    { "label": "CRETA T1", "value": "CRETA T1" },

                ],
            },
            {
                label: "KA",
                value: "KA",
                submodel: [
                    { label: "SELTOS", value: "SELTOS" },

                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    [
                        { "label": "INNOVA T2", "value": "INNOVA T2" },
                        { "label": "INNOVA T3/T4", "value": "INNOVA T3/T4" },
                        { "label": "INNOVA CRYSTA", "value": "INNOVA CRYSTA" },
                        { "label": "FORTUNER T3", "value": "FORTUNER T3" },
                        { "label": "ETIOS", "value": "ETIOS" },
                        { "label": "COROLLA LH/RH", "value": "COROLLA LH/RH" },
                        { "label": "ALTIS T1/2 LH/RH", "value": "ALTIS T1/2 LH/RH" },
                        { "label": "ALTIS T1/2 BIG LH/RH", "value": "ALTIS T1/2 BIG LH/RH" },
                        { "label": "ALTIS T3", "value": "ALTIS T3" }
                    ]

                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    [
                        { "label": "HONDA T3", "value": "HONDA T3" },
                        { "label": "HONDA T4", "value": "HONDA T4" },
                        { "label": "HONDA T5/6 HEAD LAMP BRACKET", "value": "HONDA T5/6 HEAD LAMP BRACKET" },
                        { "label": "HONDA T7", "value": "HONDA T7" },
                        { "label": "HONDA T7 HEAD LAMP BRACKET", "value": "HONDA T7 HEAD LAMP BRACKET" },
                        { "label": "HONDA T8 HEAD LAMP BRACKET", "value": "HONDA T8 HEAD LAMP BRACKET" },
                        { "label": "AMAZE T1/2", "value": "AMAZE T1/2" },
                        { "label": "AMAZE T3", "value": "AMAZE T3" },
                        { "label": "WRV", "value": "WRV" },
                        { "label": "CIVIC", "value": "CIVIC" },
                        { "label": "ACCORD", "value": "ACCORD" }
                    ]

                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "TIAGO", value: "TIAGO" },
                    { label: "NEXON", value: "NEXON" },


                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "SCORPIO S SERIES", value: "SCORPIO S SERIES" },

                ],
            },
            {
                label: "GM",
                value: "GM",
                submodel: [
                    { label: "CRUZE", value: "CRUZE" },
                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO/VENTO", value: "POLO/VENTO" },
                    { label: "POLO T1", value: "POLO T" },
                    { label: "POLO T2", value: "POLO T2" },
                    { label: "VENTO T1/2", value: "VENTO T1/2" },

                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "DUSTER", value: "DUSTER" },
                    { label: "KWID", value: "KWID" },

                ],
            },
            {
                label: "NSN",
                value: "NSN",
                submodel: [
                    { label: "MICRA", value: "MICRA" },
                    { label: "SUNNY T1", value: "SUNNY T1" },
                    { label: "Superb (2007)", value: "Superb (2007)" },

                ],
            },
            {
                label: "SKD",
                value: "SKD",
                submodel: [
                    { label: "LAURA", value: "LAURA" },


                ],
            },
            {
                label: "Ford",
                value: "Ford",
                submodel: [
                    { label: "ECO", value: "ECO" },


                ],
            },
        ],
    },
];





// please change from here  - Rishi 


export const MudFlapsFilter = [
    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "ALTO", value: "ALTO" },
                    { label: "WAGON R", value: "WAGON R" },
                    { label: "SWIFT", value: "SWIFT" },
                    { label: "SWIFT DZIRE", value: "SWIFT DZIRE" },
                    { label: "EECO", value: "EECO" },
                    { label: "ERTIGA", value: "ERTIGA" },
                    { label: "CELERIO", value: "CELERIO" },
                    { label: "BALENO", value: "BALENO" },
                    { label: "BREZZA", value: "BREZZA" },
                    { label: "ZEN", value: "ZEN" },
                    { label: "ESTILO", value: "ESTILO" },
                    { label: "RITZ", value: "RITZ" },
                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "I10", value: "I10" },
                    { label: "I10 GRAND", value: "I10 GRAND" },
                    { label: "I20", value: "I20" },
                    { label: "I20 ELITE", value: "I20 ELITE" },
                    { label: "XCENT", value: "XCENT" },
                    { label: "EON", value: "EON" },
                    { label: "ACCENT", value: "ACCENT" },
                    { label: "CRETA", value: "CRETA" },
                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "TIGOR", value: "TIGOR" },
                    { label: "TIAGO", value: "TIAGO" },
                    { label: "NEXON", value: "NEXON" },
                    { label: "PUNCH", value: "PUNCH" },
                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "XUV 500", value: "XUV 500" },
                    { label: "M-HAWK", value: "M-HAWK" },
                    { label: "BOLERO", value: "BOLERO" },
                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "ETIOS", value: "ETIOS" },
                    { label: "ETIOS LIVA", value: "ETIOS LIVA" },
                    { label: "INNOVA", value: "INNOVA" },
                    { label: "INNOVA CRYSTA", value: "INNOVA CRYSTA" },
                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "KWID", value: "KWID" },
                    { label: "DUSTER", value: "DUSTER" },
                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "CITY", value: "CITY" },
                    { label: "AMAZE", value: "AMAZE" },
                ],
            },
            {
                label: "GM",
                value: "GM",
                submodel: [{ label: "CRUZE", value: "CRUZE" }],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO", value: "POLO" },
                    { label: "VENTO", value: "VENTO" },
                ],
            },
            {
                label: "FRD",
                value: "FRD",
                submodel: [{ label: "ECOSPORT", value: "ECOSPORT" }],
            },
        ],
    },
];



export const subMirrorFilter = [
    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "ALTO Vxi", value: "ALTO Vxi" },
                    { label: "ALTO K-10 T1", value: "ALTO K-10 T1" },
                    { label: "ZEN", value: "ZEN" },
                    { label: "IGNIS MANUAL", value: "IGNIS MANUAL" },
                    { label: "CELERIO MANUAL", value: "CELERIO MANUAL" },
                    { label: "RITZ", value: "RITZ" },
                    { label: "SWIFT T3 MANUAL", value: "SWIFT T3 MANUAL" },
                    { label: "DZIRE T2 MANUAL", value: "DZIRE T2 MANUAL" },
                    { label: "ERTIGA MANUAL", value: "ERTIGA MANUAL" },
                    { label: "SWIFT Vdi", value: "SWIFT Vdi" },
                    { label: "SWIFT T1/T2 DZIRE T1", value: "SWIFT T1/T2 DZIRE T1" },
                    { label: "ALTO K-10 T2", value: "ALTO K-10 T2" },
                    { label: "CIAZ", value: "CIAZ" },
                    { label: "SWIFT T5/DZIRE T4 MOTORIZED", value: "SWIFT T5/DZIRE T4 MOTORIZED" },
                    { label: "WAGONR T4/T5 MOTORIZED", value: "WAGONR T4/T5 MOTORIZED" },
                    { label: "SX4", value: "SX4" },
                    { label: "ALTO 800", value: "ALTO 800" },
                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "INDICA T1", value: "INDICA T1" },
                    { label: "INDICA LS", value: "INDICA LS" },
                    { label: "INDICA VISTA/MANZA", value: "INDICA VISTA/MANZA" },
                    { label: "INDICA V2", value: "INDICA V2" },
                    { label: "NEXON/PUNCH", value: "NEXON/PUNCH" },
                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "i10 VX", value: "i10 VX" },
                    { label: "i10 1.2", value: "i10 1.2" },
                    { label: "i20 T2", value: "i20 T2" },
                    { label: "i10 GRAND", value: "i10 GRAND" },
                    { label: "XCENT", value: "XCENT" },
                    { label: "i20 T1", value: "i20 T1" },
                    { label: "XING", value: "XING" },
                    { label: "i10 ERA", value: "i10 ERA" },
                    { label: "EON", value: "EON" },
                    { label: "i20 ELITE", value: "i20 ELITE" },
                    { label: "CRETA T1/T2", value: "CRETA T1/T2" },
                    { label: "VERNA FLUIDIC", value: "VERNA FLUIDIC" },
                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "CITY T3/T4", value: "CITY T3/T4" },
                    { label: "AMAZE T1/T2", value: "AMAZE T1/T2" },
                    { label: "BRIO", value: "BRIO" },
                    { label: "MOBILIO T1", value: "MOBILIO T1" },
                    { label: "CITY T7/T8 ALL NEW MODELS", value: "CITY T7/T8 ALL NEW MODELS" },
                    { label: "CITY T5/T6", value: "CITY T5/T6" },
                ],
            },
            {
                label: "FRD",
                value: "FRD",
                submodel: [
                    { label: "FIGO", value: "FIGO" },
                    { label: "FIESTA OLD", value: "FIESTA OLD" },
                    { label: "ECOSPORT", value: "ECOSPORT" },
                ],
            },
            {
                label: "GM",
                value: "GM",
                submodel: [{ label: "BEAT", value: "BEAT" }],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "M-HAWK", value: "M-HAWK" },
                    { label: "SCORPIO", value: "SCORPIO" },
                    { label: "XUV 500", value: "XUV 500" },
                    { label: "KUV 100", value: "KUV 100" },
                    { label: "XYLO", value: "XYLO" },
                    { label: "VERITO", value: "VERITO" },
                    { label: "LOGAN", value: "LOGAN" },
                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "INNOVA WITH INDICATOR", value: "INNOVA WITH INDICATOR" },
                    { label: "FORTUNER WITH INDICATOR", value: "FORTUNER WITH INDICATOR" },
                    { label: "ETIOS", value: "ETIOS" },
                    { label: "LIVA", value: "LIVA" },
                    { label: "ETIOS T2/ALTIS", value: "ETIOS T2/ALTIS" },
                    { label: "INNOVA W/O INDICATOR", value: "INNOVA W/O INDICATOR" },
                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "DUSTER T1", value: "DUSTER T1" },
                    { label: "KWID", value: "KWID" },
                ],
            },
            {
                label: "NSN",
                value: "NSN",
                submodel: [
                    { label: "MICRA", value: "MICRA" },
                    { label: "TERRANO", value: "TERRANO" },
                    { label: "PULSE", value: "PULSE" },
                    { label: "SUNNY T1", value: "SUNNY T1" },
                    { label: "LODGY", value: "LODGY" },
                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [{ label: "POLO T1/T2/VENTO", value: "POLO T1/T2/VENTO" }],
            },
        ],
    },
];

export const SideMirrorFilter = [
    {
        key: "priceOrder",
        label: "Price Order",
        options: [
            { label: "Low to High", value: "low-high" },
            { label: "High to Low", value: "high-low" },
        ],
    },
    {
        key: "model",
        label: "Car Make",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "OMNI SIDE MIRROR", value: "OMNI SIDE MIRROR" },
                    { label: "EECO SIDE MIRROR", value: "EECO SIDE MIRROR" },
                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "BOLERO SIDE MIRROR", value: "BOLERO SIDE MIRROR" },
                ],
            },
        ],
    },
];


export const CoolantBottlesCapsFilter = [
    {
        key: "model",
        label: "Coolant Bottles & Caps",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "ESTEEM", value: "ESTEEM" },
                    { label: "M8", value: "M8" },
                    { label: "ALTO", value: "ALTO" },
                    { label: "OMNI/CHAMPION", value: "OMNI/CHAMPION" },
                    { label: "WAGON R", value: "WAGON R" },
                    { label: "EECO", value: "EECO" },
                    { label: "WOGON R K-SERIES", value: "WOGON R K-SERIES" },
                    { label: "SWIFT T3 PETROL", value: "SWIFT T3 PETROL" },
                    { label: "SWIFT T2 DIESEL", value: "SWIFT T2 DIESEL" },
                    { label: "SWIFT T3/4 DZIRE T2/3 / ERTIGA", value: "SWIFT T3/4 DZIRE T2/3 / ERTIGA" },
                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "SANTRO", value: "SANTRO" },
                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "INDICA", value: "INDICA" },
                    { label: "OIL CAP INDICA", value: "OIL CAP INDICA" },
                    { label: "INDICA VISTA QUATRAJET", value: "INDICA VISTA QUATRAJET" },
                    { label: "INDICA EV2", value: "INDICA EV2" },
                    { label: "TATA ACE", value: "TATA ACE" },

                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "QUALIS", value: "QUALIS" },
                    { label: "INNOVA T2", value: "INNOVA T2" },
                    { label: "INNOVA T3/4", value: "INNOVA T3/4" },
                ],
            },
            {
                label: "FRD",
                value: "FRD",
                submodel: [
                    { label: "FIESTA PETROL/DIESEL", value: "FIESTA PETROL/DIESEL" },
                    { label: "FIESTA DIESEL 2 PIPE (N/M)", value: "FIESTA DIESEL 2 PIPE (N/M)" },
                ],
            },
            {
                label: "GM",
                value: "GM",
                submodel: [
                    { label: "TAVERA", value: "TAVERA" },
                    { label: "SPARK", value: "SPARK" },
                    { label: "AVEO", value: "AVEO" },
                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "SCORPIO", value: "SCORPIO" },
                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "LOGAN/DUSTER", value: "LOGAN/DUSTER" },
                ],
            },
            {
                label: "SKD",
                value: "SKD",
                submodel: [
                    { label: "OCTAVIA", value: "OCTAVIA" },
                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO T1", value: "POLO T1" },
                ],
            },
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "CAP M8", value: "CAP M8" },
                    { label: "CAP ALTO", value: "CAP ALTO" },
                    { label: "CAP ESTEEM", value: "CAP ESTEEM" },
                    { label: "CAP OMNI/CHAMPION", value: "CAP OMNI/CHAMPION" },
                    { label: "CAP SWIFT/INNOVA", value: "CAP SWIFT/INNOVA" },
                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "CAP SANTRO", value: "CAP SANTRO" },

                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "CAP INDICA", value: "CAP INDICA" },
                    { label: "CAP VISTA QUATRAJET", value: "CAP VISTA QUATRAJET" },

                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "CAP SCORPIO", value: "CAP SCORPIO" },

                ],
            },
            {
                label: "FRD",
                value: "FRD",
                submodel: [
                    { label: "CAP FIESTA", value: "CAP FIESTA" },

                ],
            },
        ],
    },
];





export const wiperBoltFilter = [
    {
        key: "model",
        label: "Coolant Bottles & Caps",
        options: [
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "M8", value: "M8" },
                    { label: "CAP M8", value: "CAP M8" },
                    { label: "EECO/VERSA", value: "EECO/VERSA" },
                    { label: "OMNI", value: "OMNI" },
                    { label: "ALTO", value: "ALTO" },
                    { label: "ALTO 800", value: "ALTO 800" },
                    { label: "RITZ", value: "RITZ" },
                    { label: "BALENO SINGLE HOLE", value: "BALENO SINGLE HOLE" },
                    { label: "BALENO DOUBLE HOLE", value: "BALENO DOUBLE HOLE" },
                    { label: "CELERIO", value: "CELERIO" },
                    { label: "DZIRE O/M", value: "DZIRE O/M" },
                    { label: "DZIRE N/M", value: "DZIRE N/M" },
                    { label: "BREZZA", value: "BREZZA" },
                    { label: "CIAZ", value: "CIAZ" },
                    { label: "SX-4", value: "SX-4" },
                    { label: "S-CROSS", value: "S-CROSS" },
                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "SANTRO SINGLE/DOUBLE HOLE", value: "SANTRO SINGLE/DOUBLE HOLE" },
                    { label: "I-10", value: "I-10" },
                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "INDICA", value: "INDICA" },
                    { label: "INDICA PIPE", value: "INDICA PIPE" },
                    { label: "INDICA VISTA", value: "INDICA VISTA" },
                    { label: "INDICA VISTA PIPE", value: "INDICA VISTA PIPE" },
                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "SCORPIO SINGLE/DOUBLE HOLE", value: "SCORPIO SINGLE/DOUBLE HOLE" },
                ],
            },
            {
                label: "MG",
                value: "MG",
                submodel: [
                    { label: "HECTOR", value: "HECTOR" },
                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "INNOVA T1/2/3/4", value: "INNOVA T1/2/3/4" },
                    { label: "INNOVA CRYSTA/FORTUNER T4", value: "INNOVA CRYSTA/FORTUNER T4" },
                    { label: "ETIOS/LIVA", value: "ETIOS/LIVA" },
                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO T1", value: "POLO T1" },
                    { label: "POLO T2", value: "POLO T2" },
                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "AMAZE T1/2", value: "AMAZE T1/2" },
                    { label: "AMAZE T3", value: "AMAZE T3" },
                    { label: "CITY T3/4", value: "CITY T3/4" },
                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "DUSTER", value: "DUSTER" },
                ],
            },
        ],
    },
];
export const bonnetFilter = [
    {
        key: "model",
        label: "Bonnet",
        options: [
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "CITY T5/T6", value: "CITY T5/T6" },
                    { label: "CITY T7", value: "CITY T7" },
                    { label: "INNOVA T3/4", value: "INNOVA T3/4" },
                    { label: "FORTUNER T2", value: "FORTUNER T2" },

                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO/VENTO", value: "POLO/VENTO" },

                ],
            },

        ],
    },
];





export const FENDERFilter = [
    {
        key: "model",
        label: "Coolant Bottles & Caps",
        options: [
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "CITY T5 WITH HOLE RH/LH", value: "CITY T5 WITH HOLE RH/LH" },
                    { label: "CITY T6 W/O HOLE RH/LH", value: "CITY T6 W/O HOLE RH/LH" },
                    { label: "CITY T7 W/HOLE RH/LH", value: "CITY T7 W/HOLE RH/LH" },
                    { label: "CITY T7 W/O HOLE RH/LH", value: "CITY T7 W/O HOLE RH/LH" },
                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "INNOVA T3/4 W/HOLE RH/LH", value: "INNOVA T3/4 W/HOLE RH/LH" },
                    { label: "INNOVA T3/4 W/O HOLE RH/LH", value: "INNOVA T3/4 W/O HOLE RH/LH" },
                    { label: "FORTUNER T2 RH/LH", value: "FORTUNER T2 RH/LH" },
                ],
            },
            {
                label: "VW",
                value: "VW",
                submodel: [
                    { label: "POLO T1 W/HOLE RH/LH", value: "POLO T1 W/HOLE RH/LH" },
                    { label: "POLO T2 W/O HOLE RH/LH", value: "POLO T2 W/O HOLE RH/LH" },
                ],
            },
        ],

    },
]


export const CLADDINGilter = [
    {
        key: "model",
        label: "Coolant Bottles & Caps",
        options: [
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "BOLERO MAXI TRUCK WHITE RH/LH", value: "BOLERO MAXI TRUCK WHITE RH/LH" },
                    { label: "BOLERO PICKUP FB WHITE RH/LH", value: "BOLERO PICKUP FB WHITE RH/LH" },
                    { label: "BOLERO CAMPER WHITE RH/LH", value: "BOLERO CAMPER WHITE RH/LH" },
                    { label: "BOLERO MAXI TRUCK GREY RH/LH", value: "BOLERO MAXI TRUCK GREY RH/LH" },
                    { label: "BOLERO PICKUP FB GREY RH/LH", value: "BOLERO PICKUP FB GREY RH/LH" },
                    { label: "BOLERO CAMPER GREY RH/LH", value: "BOLERO CAMPER GREY RH/LH" },
                    { label: "BOLERO SLE (SET OF 4) - BLACK RH/LH", value: "BOLERO SLE (SET OF 4) - BLACK RH/LH" },
                    { label: "BOLERO SLE FRONT (SET OF 2) - BLACK RH/LH", value: "BOLERO SLE FRONT (SET OF 2) - BLACK RH/LH" },
                    { label: "BOLERO SLE REAR (SET OF 2) - BLACK RH/LH", value: "BOLERO SLE REAR (SET OF 2) - BLACK RH/LH" },
                    { label: "BOLERO SLE (SET OF 4) - WHITE RH/LH", value: "BOLERO SLE (SET OF 4) - WHITE RH/LH" },
                    { label: "BOLERO SLE FRONT (SET OF 2) - WHITE RH/LH", value: "BOLERO SLE FRONT (SET OF 2) - WHITE RH/LH" },
                    { label: "BOLERO SLE REAR (SET OF 2) - WHITE RH/LH", value: "BOLERO SLE REAR (SET OF 2) - WHITE RH/LH" },
                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "FORTUNER T2 RH/LH", value: "FORTUNER T2 RH/LH" },
                ],
            },
        ],

    },
]
export const otherPartsFilter = [
    {
        key: "model",
        label: "Coolant Bottles & Caps",
        options: [
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "OIL CHAMBER KWID", value: "CBH-OC001" },
                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "HOOD/BONNET GARNISH INNOVA T3/4", value: "CBH-BN008" },
                    { label: "HOOD HINGE INNOVA", value: "CBH-BN009/010" },
                ],
            },
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "HOOD GARNISH SAFARI STORME", value: "CBH-BN011" },
                ],
            },
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "TRUNK GARNISH WAGONR T5", value: "CBH-TG007" },
                    { label: "TRUNK GARNISH BALENO", value: "CBH-TG001" },
                    { label: "TRUNK GARNISH CIAZ", value: "CBH-TG003" },
                    { label: "TRUNK GARNISH DZIRE T1 OEM QLTY", value: "TRUNK GARNISH DZIRE T1 OEM QLTY" },
                    { label: "TRUNK GARNISH DZIRE T3", value: "CBH-TG006" },
                    { label: "TRUNK GARNISH DZIRE T3 OEM QLTY", value: "CBH-TG005" },
                    { label: "TRUNK GARNISH DZIRE T4", value: "CBH-TG002" },
                    { label: "TRUNK GARNISH BREZZA", value: "CBH-TG009" },
                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "TRUNK GARNISH SCORPIO S-SERIES - BLACK", value: "CBH-TG008" },
                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "TRUNK GARNISH WRV", value: "CBH-TG004" },
                ],
            },
        ],

    },
]



export const wiperBladeFilter = [
    {
        key: "model",
        label: "Coolant Bottles & Caps",
        options: [
            {
                label: "UNIVERSAL",
                value: "UNIVERSAL",
                submodel: [
                    { label: "12", value: "12" },
                    { label: "13", value: "13" },
                    { label: "14", value: "14" },
                    { label: "15", value: "15" },
                    { label: "16", value: "16" },
                    { label: "17", value: "17" },
                    { label: "18", value: "18" },
                    { label: "19", value: "19" },
                    { label: "20", value: "20" },
                    { label: "21", value: "21" },
                    { label: "22", value: "22" },
                    { label: "24", value: "24" },
                    { label: "26", value: "26" },
                ],
            },
        ],

    },
]


export const REARWIPERBLADEFIlter = [
    {
        key: "model",
        label: "Coolant Bottles & Caps",
        options: [
            {
                label: "TAT",
                value: "TAT",
                submodel: [
                    { label: "REAR WIPER - TIAGO", value: "REAR WIPER - TIAGO" },
                    { label: "REAR WIPER - HEXA", value: "REAR WIPER - HEXA" },
                    { label: "REAR WIPER - 33333333333", value: "REAR WIPER - 33333333333" },
                    { label: "REAR WIPER - HARRIER", value: "REAR WIPER - HARRIER" },
                    { label: "REAR WIPER - NEXON", value: "REAR WIPER - NEXON" },
                ],
            },
            {
                label: "FRD",
                value: "FRD",
                submodel: [
                    { label: "REAR WIPER - FIGO", value: "REAR WIPER - FIGO" },
                    { label: "REAR WIPER - FIGO NEW", value: "REAR WIPER - FIGO NEW" },
                    { label: "REAR WIPER - ECOSPORT NEW", value: "REAR WIPER - ECOSPORT NEW" },
                    { label: "REAR WIPER - ECOSPORT", value: "REAR WIPER - ECOSPORT" },
                    { label: "REAR WIPER - ENDEAVOUR", value: "REAR WIPER - ENDEAVOUR" },
                    { label: "REAR WIPER - FIESTA", value: "REAR WIPER - FIESTA" },
                    { label: "REAR WIPER - ENDEAVOUR TITANIUM", value: "REAR WIPER - ENDEAVOUR TITANIUM" },
                ],
            },
            {
                label: "HND",
                value: "HND",
                submodel: [
                    { label: "REAR WIPER - WRV", value: "REAR WIPER - WRV" },
                    { label: "REAR WIPER - CRV LATEST", value: "REAR WIPER - CRV LATEST" },
                    { label: "REAR WIPER - JAZZ", value: "REAR WIPER - JAZZ" },
                ],
            },
            {
                label: "GM",
                value: "GM",
                submodel: [
                    { label: "REAR WIPER - ENJOY", value: "REAR WIPER - ENJOY" },
                    { label: "REAR WIPER - BEAT", value: "REAR WIPER - BEAT" },
                ],
            },
            {
                label: "Ft",
                value: "Ft",
                submodel: [
                    { label: "REAR WIPER - PUNTO", value: "REAR WIPER - PUNTO" },
                    { label: "REAR WIPER - PALIO", value: "REAR WIPER - PALIO" },
                ],
            },
            {
                label: "NSN",
                value: "NSN",
                submodel: [
                    { label: "REAR WIPER - MICRA", value: "REAR WIPER - MICRA" },
                    { label: "REAR WIPER - XTRAIL OLD", value: "REAR WIPER - XTRAIL OLD" },
                    { label: "REAR WIPER - XTRAIL NEW", value: "REAR WIPER - XTRAIL NEW" },
                ],
            },
            {
                label: "RNT",
                value: "RNT",
                submodel: [
                    { label: "REAR WIPER - LODGY", value: "REAR WIPER - LODGY" },
                    { label: "REAR WIPER - DUSTER NEW", value: "REAR WIPER - DUSTER NEW" },
                ],
            },
            {
                label: "MSZ",
                value: "MSZ",
                submodel: [
                    { label: "REAR WIPER - WAGONR", value: "REAR WIPER - WAGONR" },
                    { label: "REAR WIPER - SWIFT", value: "REAR WIPER - SWIFT" },
                    { label: "REAR WIPER - S CROSS", value: "REAR WIPER - S CROSS" },
                    { label: "REAR WIPER - ERTIGA", value: "REAR WIPER - ERTIGA" },
                    { label: "REAR WIPER - ZEN ESTILO", value: "REAR WIPER - ZEN ESTILO" },
                    { label: "REAR WIPER - IGNIS", value: "REAR WIPER - IGNIS" },
                    { label: "REAR WIPER - BALENO", value: "REAR WIPER - BALENO" },
                    { label: "REAR WIPER - BALENO NEW", value: "REAR WIPER - BALENO NEW" },
                    { label: "REAR WIPER - GRAND VITARA", value: "REAR WIPER - GRAND VITARA" },
                ],
            },
            {
                label: "HYD",
                value: "HYD",
                submodel: [
                    { label: "REAR WIPER - I10", value: "REAR WIPER - I10" },
                    { label: "REAR WIPER - I10 GRAND", value: "REAR WIPER - I10 GRAND" },
                    { label: "REAR WIPER - I20", value: "REAR WIPER - I20" },
                    { label: "REAR WIPER - I20 ELITE", value: "REAR WIPER - I20 ELITE" },
                    { label: "REAR WIPER - CRETA", value: "REAR WIPER - CRETA" },
                ],
            },
            {
                label: "TYT",
                value: "TYT",
                submodel: [
                    { label: "REAR WIPER - ETIOS LIVA", value: "REAR WIPER - ETIOS LIVA" },
                    { label: "REAR WIPER - FORTUNER", value: "REAR WIPER - FORTUNER" },
                    { label: "REAR WIPER - INNOVA", value: "REAR WIPER - INNOVA" },
                ],
            },
            {
                label: "MHD",
                value: "MHD",
                submodel: [
                    { label: "REAR WIPER - XUV 500", value: "REAR WIPER - XUV 500" },
                    { label: "REAR WIPER - TUV 300", value: "REAR WIPER - TUV 300" },
                ],
            },
            {
                label: "SKD",
                value: "SKD",
                submodel: [
                    { label: "REAR WIPER - FABIA", value: "REAR WIPER - FABIA" },
                    { label: "REAR WIPER - KODIAQ", value: "REAR WIPER - KODIAQ" },
                    { label: "REAR WIPER - POLO", value: "REAR WIPER - POLO" },
                    { label: "REAR WIPER - TIGUAN", value: "REAR WIPER - TIGUAN" },
                ],
            },
        ],

    },
]
