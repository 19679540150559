import React, { useState } from "react";
import { FaChevronDown, FaBars, FaTimes, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaYoutube, FaGoogle } from "react-icons/fa";
import SearchBox from "./SearchBox.jsx";
import { navlinks } from "../../data/navlink.js";
import logo from "../../assets/logo.png";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const toggleSubmenu = (index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div>
      <nav className="bg-white text-black py-3 lg:py-0  shadow-lg">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-3">
          <div className="flex items-center justify-center lg:h-28 w-24">
            <Link
              to="/"
              className="flex items-center"
              onClick={closeMobileMenu}
            >
              <img src={logo} alt="Logo" className="h-full w-auto" />
            </Link>
          </div>

          <div className="flex items-center flex-grow justify-center lg:ml-32 ml-10">
            <button
              onClick={toggleSearch}
              className="text-white w-52 md:w-[37vw] bg-gray-200 rounded-md lg:p-3 p-2 hover:bg-gray-300 transition duration-300"
            >
              <FaSearch className="text-black" />
            </button>
            <button
              onClick={toggleMobileMenu}
              className="lg:hidden text-2xl ml-5"
            >
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
            <SearchBox isOpen={isSearchOpen} toggleSearch={toggleSearch} />
          </div>
          <div className="hidden lg:flex flex-grow items-center justify-end">
            <ul className="flex space-x-8">
              <a
                href="#about"
                className="text-lg font-semibold flex items-center border-b-4 border-transparent hover:border-red-600 transition-all duration-300"
              >
                About
              </a>
              {navlinks?.map((link, index) => (
                <li key={index} className="relative group">
                  <Link
                    to={link.path}
                    className="text-lg font-semibold flex items-center border-b-4 border-transparent hover:border-red-600 transition-all duration-300"
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                    {link.sublink && (
                      <FaChevronDown className="inline ml-1 mt-[3px] transition-transform duration-200" />
                    )}
                  </Link>
                  {link.sublink && (
                    <ul className="invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-opacity duration-300 fixed bottom-24 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-700 border rounded-md shadow-lg z-50 w-screen max-w-7xl grid grid-cols-2 lg:grid-cols-5 gap-4 p-4">
                      {link.sublink.map((sublink, index) => (
                        <li
                          key={index}
                          className="px-4 py-2 hover:bg-orange-600 transition-all duration-300 flex items-center"
                        >
                          <Link
                            to={sublink.path}
                            className="text-white border-transparent flex items-center space-x-4"
                          >
                            <img
                              src={sublink.image}
                              alt={sublink.name}
                              className="w-12 h-12 object-cover rounded-md"
                            />
                            <span>{sublink.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <a
                href="#contact"
                className="text-lg font-semibold flex items-center border-b-4 border-transparent hover:border-red-600 transition-all duration-300"
              >
                Contact
              </a>
            </ul>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 right-0 w-3/4 h-full bg-white border-l border-gray-700 z-50 transform transition-transform duration-300 ease-in-out ${
          isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <p className="text-xl text-black font-semibold">Menu</p>
          <button onClick={toggleMobileMenu} className="text-2xl text-black">
            <FaTimes />
          </button>
        </div>

        <ul className="flex flex-col mt-4 space-y-2">
          <div className="flex justify-between items-center px-4 py-2 text-lg font-semibold text-black">
            <a href="#about" className="w-full">
              About
            </a>
          </div>
          {navlinks?.map((link, index) => (
            <li key={index} className="relative">
              <div className="flex justify-between items-center px-4 py-2 text-lg font-semibold text-black">
                <Link
                  to={link.path}
                  onClick={closeMobileMenu}
                  className="w-full"
                >
                  {link.title}
                </Link>
                {link.sublink && (
                  <FaChevronDown
                    onClick={() => toggleSubmenu(index)}
                    className="cursor-pointer"
                  />
                )}
              </div>
              {link.sublink && openSubmenuIndex === index && (
                <ul className="ml-4 space-y-2 px-10">
                  {link.sublink.map((sublink, subIndex) => (
                    <li key={subIndex}>
                      <Link
                        to={sublink.path}
                        className="text-black"
                        onClick={toggleMobileMenu}
                      >
                        {sublink.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          <div className="flex justify-between items-center px-4 py-2 text-lg font-semibold text-black">
            <a href="#contact" className="w-full">
              Contact
            </a>
          </div>
        </ul>

        <div className="flex space-x-4 mt-8 justify-center">
          {/* Social links */}
          <a
            href="https://www.facebook.com/RKSHOME.CO.IN"
            className="text-blue-600 hover:text-blue-400 border border-blue-600 hover:border-blue-400 rounded-full p-3 transition-all duration-300"
            aria-label="Facebook"
          >
            <FaFacebook size={22} />
          </a>
          <a
            href="https://www.instagram.com/rks.homes/"
            className="text-pink-600 hover:text-pink-400 border border-pink-600 hover:border-pink-400 rounded-full p-3 transition-all duration-300"
            aria-label="Instagram"
          >
            <FaInstagram size={22} />
          </a>
          <a
            href="https://www.youtube.com/@rkshomes5376"
            className="text-red-600 hover:text-red-400 border border-red-600 hover:border-red-400 rounded-full p-3 transition-all duration-300"
            aria-label="YouTube"
          >
            <FaYoutube size={22} />
          </a>
          <a
            href="https://www.google.com/maps?q=23.1630267,77.2736317&z=17&hl=en"
            className="text-blue-600 hover:text-blue-400 border border-blue-600 hover:border-blue-400 rounded-full p-3 transition-all duration-300"
            aria-label="Google Maps"
          >
            <FaGoogle size={22} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
