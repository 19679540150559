import React from "react";
import { Link } from "react-router-dom";

const ProductList = ({ filteredProducts, viewMode }) => {
  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : description;
  };

  return (
    <div
      className={`col-span-3 grid ${
        viewMode === "grid"
          ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          : "grid-cols-1 gap-4"
      }`}
    >
      {filteredProducts.length > 0 ? (
        filteredProducts.map((product) => (
          <Link
            to={`${product.link}/${product.id}`}
            key={product.id}
            className={`bg-white p-4 rounded-lg shadow-lg shadow-orange-500 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer relative group ${
              viewMode === "row" ? "flex items-start" : ""
            }`}
          >
            <div
              className={`${
                viewMode === "row" ? "w-1/3 mr-4" : "w-full mb-4"
              } h-48 relative flex items-center justify-center`}
            >
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-full object-contain rounded-lg"
              />
            </div>

            {/* Content Section */}
            <div
              className={`flex flex-col justify-between w-full ${
                viewMode === "row" ? "w-2/3" : ""
              }`}
            >
              <h3 className="text-xl font-semibold mb-2 text-gray-900">
                {product.title}
              </h3>
              <p className="text-gray-700 mb-2 font-medium">
                Price: {product.price}
              </p>
              <p className="text-gray-600 mb-2">
                {truncateDescription(product.description, 8)}
              </p>
            </div>

            {/* Hover Effect */}
            <div
              className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 bg-gray-800 bg-opacity-75`}
            >
              <p className="border-2 border-white  text-xl  text-white px-4 py-2 rounded-full hover:border-orange-500 hover:text-orange-500">
                View Details
              </p>
            </div>
          </Link>
        ))
      ) : (
        <p className="col-span-3 text-center">No products found.</p>
      )}
    </div>
  );
};

export default ProductList;
