import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

const MobileFilterModal = ({
  filters,
  selectedFilters,
  handleFilterChange,
  toggleFilterModal,
  handleResetFilters,
}) => {
  const [submodelOptions, setSubmodelOptions] = useState([]);

  const handleModelChange = (modelValue) => {
    handleFilterChange("model", modelValue);

    const selectedModel = filters
      .find((filter) => filter.key === "model")
      ?.options.find((option) => option.value === modelValue);

    if (selectedModel && selectedModel.submodel) {
      setSubmodelOptions(selectedModel.submodel);
    } else {
      setSubmodelOptions([]);
    }

    // Reset submodel filter when the model changes
    handleFilterChange("submodel", "");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-md w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Filters</h3>
          <button onClick={toggleFilterModal}>
            <FaTimes className="text-xl" />
          </button>
        </div>

        <div className="space-y-4">
          {/* Model Filter */}
          {filters
            .filter((filter) => filter.key === "model")
            .map((filter, index) => (
              <div key={index}>
                <label className="text-lg font-semibold">{filter.label}:</label>
                <select
                  className="ml-2 p-2 border rounded w-full"
                  value={selectedFilters["model"] || "All"}
                  onChange={(e) => handleModelChange(e.target.value)}
                >
                  <option value="All">All</option>
                  {filter.options.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            ))}

          {/* Submodel Filter */}
          <div>
            <label className="text-lg font-semibold">Submodel:</label>
            <select
              className="ml-2 p-2 border rounded w-full"
              value={selectedFilters["submodel"] || "All"}
              onChange={(e) =>
                handleFilterChange(
                  "submodel",
                  e.target.value === "All" ? "" : e.target.value
                )
              }
            >
              <option value="All">All</option>
              {submodelOptions.length === 0 ? (
                <option value="" disabled>
                  Select a model first
                </option>
              ) : (
                submodelOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))
              )}
            </select>
          </div>

          {/* Other Filters */}
          {filters
            .filter((filter) => filter.key !== "model")
            .map((filter, index) => (
              <div key={index}>
                <label className="text-lg font-semibold">{filter.label}:</label>
                <select
                  className="ml-2 p-2 border rounded w-full"
                  value={selectedFilters[filter.key] || "All"}
                  onChange={(e) =>
                    handleFilterChange(
                      filter.key,
                      e.target.value === "All" ? "" : e.target.value
                    )
                  }
                >
                  <option value="All">All</option>
                  {filter.options.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            ))}
        </div>

        <div className="mt-6 flex justify-between">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={handleResetFilters}
          >
            Reset Filters
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={toggleFilterModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileFilterModal;
