import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaFilter, FaThLarge, FaList } from "react-icons/fa";
import ProductList from "../components/core/product/ProductList";
import ProductFilters from "../components/core/product/ProductFilters";
import MobileFilterModal from "../components/core/product/MobileFilterModal";

const Product = ({ products, filters, title }) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [visibleProducts, setVisibleProducts] = useState(6);
  const [viewMode, setViewMode] = useState("grid");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisible) => prevVisible + 6);
  };

  const filteredProducts = products
    .filter((product) => {
      return Object.keys(selectedFilters).every((filterKey) => {
        if (filterKey === "priceOrder") {
          return true;
        }
        if (!selectedFilters[filterKey] || selectedFilters[filterKey] === "") {
          return true;
        }
        if (filterKey === "priceRange") {
          const [minPrice, maxPrice] = JSON.parse(selectedFilters[filterKey]);
          return product.price >= minPrice && product.price <= maxPrice;
        }
        if (filterKey === "year") {
          return (
            product[filterKey] === parseInt(selectedFilters[filterKey], 10)
          );
        }
        return product[filterKey] === selectedFilters[filterKey];
      });
    })
    .sort((a, b) => {
      if (selectedFilters.priceOrder === "low-high") {
        return a.price - b.price;
      }
      if (selectedFilters.priceOrder === "high-low") {
        return b.price - a.price;
      }
      return 0;
    })
    .slice(0, visibleProducts);

  const toggleViewMode = () => {
    setViewMode(viewMode === "grid" ? "row" : "grid");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="GDR Group 72: A dynamic conglomerate specializing in Agriculture, Dairy, Farm Land, and Commercial Property with a mission to drive growth in rural communities through innovation and sustainability"
        />
      </Helmet>
      <div className="bg-gray-200">
        <div className="mb-4 flex justify-between items-center max-w-7xl mx-auto">
          <div className="hidden lg:flex gap-4">
            <button
              className="bg-gray-200 text-gray-700 px-3 py-2 rounded flex items-center"
              onClick={toggleViewMode}
            >
              {viewMode === "grid" ? (
                <FaList className="mr-2" />
              ) : (
                <FaThLarge className="mr-2" />
              )}
              {viewMode === "grid" ? "Row View" : "Grid View"}
            </button>
          </div>

          {/* Desktop Filters */}
          <div className="hidden lg:block">
            <ProductFilters
              filters={filters}
              selectedFilters={selectedFilters}
              handleFilterChange={handleFilterChange}
            />
          </div>

          {/* Mobile Filter Button */}
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center lg:hidden"
            onClick={toggleFilterModal}
          >
            <FaFilter className="mr-2" />
            Filters
          </button>
        </div>
      </div>

      <div className="max-w-7xl mx-auto p-5">
        <div className="mb-4 flex justify-between items-center">
          <h2 className="text-2xl font-semibold">Products</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <ProductList
            filteredProducts={filteredProducts}
            viewMode={viewMode}
          />
        </div>

        {visibleProducts < products.length && (
          <div className="flex justify-center mt-6">
            <button
              onClick={loadMoreProducts}
              className="bg-blue-500 text-white px-6 py-3 rounded"
            >
              Load More
            </button>
          </div>
        )}
      </div>

      {/* Mobile Filter Modal */}
      {isFilterModalOpen && (
        <MobileFilterModal
          filters={filters}
          selectedFilters={selectedFilters}
          handleFilterChange={handleFilterChange}
          toggleFilterModal={toggleFilterModal}
          handleResetFilters={handleResetFilters}
        />
      )}
    </div>
  );
};

export default Product;
