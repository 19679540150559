import React from "react";
import { Slide } from "react-awesome-reveal";

import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="" id="about">
      <div className="bg-black py-5">
        <div className="relative z-10 max-w-7xl mx-auto px-4 lg:gap-32 py-1 bg-opacity-90 bg-transparent text-white">
          <div className="main  items-center grid  gap-5 lg:grid-cols-2">
            <Slide direction="left">
              {/* <div className="second border overflow-hidden grid gap-3 p-5">
                <p className="text-yellow-400 font-semibold text-2xl text-center">
                  As Seen On
                </p>
                <div className="scroll-container">
                  <div className="scroll-left-to-right flex gap-2 lg:gap-1">
                    <img
                      src={about}
                      alt="not found"
                      
                    />
                    <img
                      src={a2}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a3}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a4}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a4}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a5}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a6}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                  </div>
                </div>
                <div className="scroll-container">
                  <div className="scroll-right-to-left flex gap-2 lg:gap-1">
                    <img
                      src={a5}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a6}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a7}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a8}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a1}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                    <img
                      src={a2}
                      alt="not found"
                      className="lg:w-36 lg:h-36 w-28 h-28 rounded-full"
                    />
                  </div>
                </div>
              </div> */}

              <img
                src={
                  "https://proqc.com/blog/wp-content/uploads/2023/12/auto-parts-header.webp"
                }
                alt="not found"
              />
            </Slide>
            <Slide direction="right">
              <div className="first grid gap-4 p-4 md:gap-6 md:p-8">
                <p className="text-xl md:text-3xl font-semibold  border-l-4 border-yellow-400 pl-4">
                  About Car Body Parts- A Well Known Moto Parts Agency in India
                </p>
                <p className="text-sm md:text-xl ">
                We are your one-stop solution for all your spare part needs, providing the highest quality products at competitive prices. Our extensive selection ensures that you can find everything you need to keep your vehicle in top condition. With a commitment to offering reliable and durable parts, we make sure you get the best value for your money without compromising on quality.                </p>

                <div>
                  <Link
                    to={"/about"}
                    className="bg-orange-600 mt-3  rounded-full text-white  w-fit px-5 py-4 text-xl lg:text-xl"
                  >
                    About Car Body Parts
                  </Link>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
