import React, { useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import about from "../assets/about.png";
import AboutGrid from "../components/core/about/AboutGrid";
import { Helmet } from "react-helmet";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>About Us - Car Body Parts</title>
        <meta
          name="description"
          content="GDR Group 72: A dynamic conglomerate specializing in Agriculture, Dairy, Farm Land, and Commercial Property with a mission to drive growth in rural communities through innovation and sustainability"
        />
      </Helmet>
      <div className="relative flex justify-center items-center" id="aboutpage">
        <img
          src={about}
          alt="Service"
          className="w-full max-h-[85vh] object-cover"
        />

        <div className="absolute flex flex-col items-center text-center">
          <h1 className="text-4xl md:text-6xl text-white font-bold">
            About Us
          </h1>
          <br />
          <br />
          <br />
          <FaChevronDown className="text-white text-3xl mt-4 animate-bounce" />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center text-center max-w-5xl mx-auto p-5">
        <img
          src="https://www.dilzer.net/images/about-us/mission-statement.png"
          alt="Mission Statement"
          className="w-54 h-52 mb-4"
        />
        <p className="font-bold text-2xl mb-2">Mission Statement</p>
        <p className="text-xl ">
          "Our Parts Are Not Original But They Are Interchangeable With The
          Original One. Above Brands/car Names Are For Reference Purpose Only.
          Above Images Are For Illustration Purpose Only. .”
        </p>
      </div>

      <AboutGrid />
    </div>
  );
};

export default About;
