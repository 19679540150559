import p1 from "../assets/popular/bumper_cover.webp"
import p2 from "../assets/popular/headlights.webp"
import p3 from "../assets/popular/part-mirrors.webp"
import p4 from "../assets/popular/fenders_-and-_components.webp"



export const slider = [
    {
        id: 1,
        text: "High-Performance Motorcycle Exhaust System",
        image: "https://proqc.com/blog/wp-content/uploads/2023/12/auto-parts-header.webp",
        description:
            "Upgrade your ride with our high-performance motorcycle exhaust system, designed to enhance both power and sound. Crafted from durable stainless steel, this exhaust system reduces weight and increases horsepower, delivering a deep, aggressive tone.",
        link: "/bumper"
    },
    {
        id: 2,
        image:
            "https://media.licdn.com/dms/image/v2/D4D12AQH-i0XaW-7Qeg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1724053205796?e=2147483647&v=beta&t=XzUdwR86kWEMdOfCIN1IWR28g_gg7_FC73tE3Vohzn8",
        text: "Heavy-Duty Motorcycle Chain and Sprocket Kit.",
        description:
            "Keep your bike running smoothly with our heavy-duty motorcycle chain and sprocket kit. Engineered for durability and precision, this kit includes a high-strength chain and matching sprockets to ensure optimal performance and longevity.",
        link: "/fender"

    },
    {
        id: 3,
        image:
            "https://etimg.etb2bimg.com/photo/75465038.cms",
        text: "Heavy-Duty Motorcycle Chain and Sprocket Kit.",
        description:
            "Keep your bike running smoothly with our heavy-duty motorcycle chain and sprocket kit. Engineered for durability and precision, this kit includes a high-strength chain and matching sprockets to ensure optimal performance and longevity.",
        link: "/bumperbracket"

    },

    {
        id: 4,
        image:
            "https://garagev8.pl/wp-content/uploads/2021/01/slide4-1920x900.jpg",
        text: "Heavy-Duty Motorcycle Chain and Sprocket Kit.",
        description:
            "Keep your bike running smoothly with our heavy-duty motorcycle chain and sprocket kit. Engineered for durability and precision, this kit includes a high-strength chain and matching sprockets to ensure optimal performance and longevity.",
        link: "/foglamp"

    },

]

export const services = [
    {
        image:
            "https://www.carbodyparts.in/static/media/bumper_cover.f76a0387cc1d6a859f1f.webp",
        title: "Bumper",
        description:
            "Enhance your vehicle's protection and style with premium bumpers designed for durability and performance.",
        link: "/bumper",
    },
    {
        image:
            "http://localhost:3000/static/media/headlights.2b41f5b66aab185feada.webp",
        title: "Fender Line",
        description:
            "Keep your car safe from debris and ensure a sleek look with high-quality fender liners.",
        link: "/fenderline",
    },
    {
        image:
            "https://m.media-amazon.com/images/I/81D0VQbxWPL._AC_UF1000,1000_QL80_.jpg",
        title: "Grills",
        description:
            "Upgrade your car's front-end look with stylish and durable grills that provide protection and ventilation.",
        link: "/frontgrillsandbumpergrills",
    },
];

export const popularProducts = [

    { name: "Bumper", path: "/bumper", image: p1 },
    { name: "Fender Line", path: "/fenderline", image: p2 },
    { name: "Fender ", path: "/fender", image: p2 },
    { name: "Grills", path: "/frontgrillsandbumpergrills", image: p3 },
    { name: "Fog Lamp Covers", path: "/foglampcover", image: p4 },
    { name: "Fog Lamps", path: "/foglamp", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/foglamp_1.jpg" },
    { name: "Bumper Brackets", path: "/bumperbracket", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/bumperbracket_1.jpg" },
    { name: "Mud Flaps", path: "/mudflaps", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/mudflaps_1.jpg" },
    { name: "Sub Mirrors", path: "/submirror", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/submirror_1.jpg" },
    { name: "Side Mirrors", path: "/sidemirror", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/sidemirror_1.jpg" },
    { name: "Coolant Bottles & Caps", path: "/coolantbottlescaps", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/coolantbottlescaps_1.jpg" },
    { name: "Wiper Bolts", path: "/wiperbolt", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/wiperbolt_1.jpg" },
    { name: "Cladding", path: "/cladding", image: "https://4.imimg.com/data4/QB/PJ/GLADMIN-182459/car-fender-lining-250x250.jpg" },
    { name: "Other Parts", path: "/other", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/otherparts_1.jpg" },
    { name: "Wiper Blades", path: "/wiperblade", image: "https://images-eu.ssl-images-amazon.com/images/I/61TfcPvRxyL._AC_UL210_SR210,210_.jpg" },
    { name: "Rear Wiper Blades", path: "/rearwiperblade", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/rearwiperblades_1.jpg" },
    { name: "Bonnet", path: "/bonnet", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/rearwiperblades_1.jpg" }


]