import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title> Car Body Parts - Home </title>
        <meta
          name="description"
          content="GDR Group 72: A dynamic conglomerate specializing in Agriculture, Dairy, Farm Land, and Commercial Property with a mission to drive growth in rural communities through innovation and sustainability"
        />
      </Helmet>
      <div className="max-w-7xl mx-auto p-5" id="contact">
        <iframe
          title="rishi"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.048988773075!2d75.88919437508137!3d22.68922017940876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd2384a34bbf%3A0x81535c71ca2ecefb!2sSuvidha%20Auto%20Gas%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1733820263260!5m2!1sen!2sin"
          allowFullScreen
          className="w-full h-[500px]"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <div className=" grid gap-4 main lg:grid lg:grid-cols-2 my-36 items-center">
          <div className="first flex flex-col gap-8">
            <div className="flex items-center gap-4">
              <span className="px-4 py-3 bg-gray-900 text-white rounded-md text-xl">
                <FaLocationDot />
              </span>
              <span>
                {" "}
                3rd floor above suvidha autogas pvt ltd ,1/1, durga nagar,
                eastern ring road Indore MP
              </span>
            </div>
            <div className="flex items-center gap-4">
              <span className="px-4 py-3 bg-gray-900  text-white  rounded-md  text-xl">
                <MdEmail />
              </span>
              <span>carbodyparts1@gmail.com</span>
            </div>
            <div className="flex items-center gap-4">
              <span className="px-4 py-3 bg-gray-900  text-white rounded-md  text-xl">
                <FaPhone />
              </span>
              <span>+91 9351014634</span>
            </div>
          </div>
          <form className="second flex flex-col gap-2">
            <div className="grid grid-cols-2 gap-2">
              <input
                type="text"
                placeholder="Enter your name"
                className="p-3 outline-none h-14 border border-gray-300 bg-transparent"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="email"
                placeholder="Enter your email"
                className="p-3 outline-none h-14 border border-gray-300 bg-transparent"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <input
              type="text"
              placeholder="Enter your phone "
              className="p-3 outline-none h-14 border border-gray-300 bg-transparent"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
            />
            <textarea
              className="p-3 outline-none h-28 border border-gray-300 bg-transparent"
              placeholder="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button
              type="submit"
              className="px-5 py-2 border-gray-900 border-2 hover:border-red-600 hover:text-red-600
            "
            >
              Send Message!
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
