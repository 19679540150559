import React, { useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";
import { slider } from "../../../data/home";
const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div className="relative">
      <Swiper
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
          EffectFade,
        ]}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        spaceBetween={0}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        onSlideChange={({ activeIndex }) => setCurrentIndex(activeIndex)}
      >
        {slider?.map((item, index) => (
          <SwiperSlide key={index} className="relative">
            <div
              className={`relative transition-opacity duration-500 ease-in-out ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
            >
              <img
                src={item.image}
                alt={item.text}
                className="w-full lg:h-[85vh] h-[45vh] object-cover"
                style={{ filter: "brightness(0.5)" }}
              />
            </div>
            <div
              className={`absolute left-8 top-1/4 flex justify-start flex-col transform -translate-y-1/2 p-6 transition-all duration-500 ease-in-out ${
                index === currentIndex
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-4"
              }`}
            >
              <p className="text-orange-600 text-3xl w-[92%]  md:text-3xl lg:text-6xl font-bold text-left lg:w-[65%] mb-4">
                {item.text}
              </p>
              <p className="text-white text-sm md:text-lg w-[50%] lg:text-xl text-left mb-4 hidden lg:block">
                {item.description}
              </p>
              <Link
                to={item?.link}
                className="block border-4 border-orange-600 text-center font-bold py-2 text-white text-sm md:text-lg lg:text-xl rounded-lg transition-transform duration-500 transform hover:scale-105"
                style={{ width: "200px" }}
              >
                Visit Now
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
