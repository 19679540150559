import React, { useEffect } from "react";
import Slider from "../components/core/home/Slider";
import Services from "../components/core/home/Services";
import PopuralParts from "../components/core/home/PopuralParts";
// import Banner from "../components/core/home/Banner";
import { Slide } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import ButtomNavbar from "../components/comman/ButtomNavbar";
import About from "../components/core/home/About";
import Contact from "./Contact";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Car Body Parts - Home</title>
        <meta
          name="description"
          content="GDR Group 72: A dynamic conglomerate specializing in Agriculture, Dairy, Farm Land, and Commercial Property with a mission to drive growth in rural communities through innovation and sustainability"
        />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Car Body Parts Automotive Parts",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Janki Nagar, Neelkant Mahadev Mandir, Near Mashroom Factory Ratibad Bhopal",
                "addressLocality": "Bhopal",
                "addressRegion": "MP",
                "postalCode": "462001",
                "addressCountry": "IN"
              },
              "telephone": "+91-1234567890",
              "url": "https://gdrgroup72.com"
            }
          `}
        </script>
      </Helmet>
      <ButtomNavbar />
      <Slider />
      <About />

      <PopuralParts />
      <Services />
      <br />
      <Slide direction="left">{/* <Banner /> */}</Slide>
      <Contact />
    </div>
  );
};

export default Home;
