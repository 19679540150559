import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          Terms and Conditions
        </h1>

        <ul className="space-y-4 text-lg text-gray-700">
          <li className="flex items-center">
            <FaExclamationTriangle className="text-yellow-500 mr-2" />
            Rates are applicable only for Indore godown.
          </li>
          <li className="flex items-center">
            <FaExclamationTriangle className="text-yellow-500 mr-2" />
            Freight charges and shipping charges are extra for sending parts to
            locations outside Indore.
          </li>
          <li className="flex items-center">
            <FaExclamationTriangle className="text-yellow-500 mr-2" />
            We are not responsible for any breakage or damage that occurs during
            transportation.
          </li>
        </ul>

        <p className="text-red-600 mt-6 text-lg font-semibold">
          Note: Please ensure all shipments are checked upon delivery to avoid
          any discrepancies.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
