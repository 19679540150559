import React, { useState } from "react";

const ProductFilters = ({ filters, selectedFilters, handleFilterChange }) => {
  const [submodelOptions, setSubmodelOptions] = useState([]);

  const handleModelChange = (modelValue) => {
    console.log("Model selected:", modelValue); // Log selected model
    handleFilterChange("model", modelValue);

    const selectedModel = filters
      .find((filter) => filter.key === "model")
      .options.find((option) => option.value === modelValue);

    if (selectedModel && selectedModel.submodel) {
      console.log("Submodel options:", selectedModel.submodel); // Log submodel options
      setSubmodelOptions(selectedModel.submodel);
    } else {
      setSubmodelOptions([]);
    }

    handleFilterChange("submodel", "");
  };

  return (
    <div className="hidden lg:flex gap-4">
      {/* Filter for model */}
      {filters
        .filter((filter) => filter.key === "model")
        .map((filter, index) => (
          <div key={index} className="flex items-center p-2">
            <label className="text-lg font-semibold">{filter.label}:</label>
            <select
              className="ml-2 px-10 py-2 border-2 rounded"
              value={selectedFilters["model"] || "All"}
              onChange={(e) => handleModelChange(e.target.value)}
            >
              <option value="All">All</option>
              {filter.options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        ))}

      {/* Filter for submodel */}
      <div className="flex items-center p-2">
        <label className="text-lg font-semibold">Model:</label>
        <select
          className="ml-2 px-10 py-2 border-2 rounded"
          value={selectedFilters["submodel"] || "All"}
          onChange={(e) =>
            handleFilterChange(
              "submodel",
              e.target.value === "All" ? "" : e.target.value
            )
          }
        >
          <option value="All">All</option>
          {submodelOptions.length === 0 ? (
            <option value="" disabled>
              Select a model first
            </option>
          ) : (
            submodelOptions.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))
          )}
        </select>
      </div>

      {/* Filter for price order */}
      {filters
        .filter((filter) => filter.key === "priceOrder")
        .map((filter, index) => (
          <div key={index} className="flex items-center p-2">
            <label className="text-lg font-semibold">{filter.label}:</label>
            <select
              className="ml-2 px-10 py-2 border-2 rounded"
              value={selectedFilters["priceOrder"] || "All"}
              onChange={(e) =>
                handleFilterChange(
                  "priceOrder",
                  e.target.value === "All" ? "" : e.target.value
                )
              }
            >
              <option value="All">All</option>
              {filter.options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        ))}
    </div>
  );
};

export default ProductFilters;
