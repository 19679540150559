import React, { useState } from "react";
import { Link } from "react-router-dom";
import { all } from "../../data/search";

const SearchBox = ({ isOpen, toggleSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter products based on search query
  const filteredProducts = all.filter((product) => {
    const title = product.title || "";
    const subtitle = product.subtitle || "";
    const description = product.description || "";

    return (
      title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      subtitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
      description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Function to truncate text
  const truncateText = (text, wordLimit = 18) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  return (
    isOpen && (
      <div className="absolute z-50 right-0 lg:right-10 top-12 mt-2 w-full lg:w-[90%] h-[80vh] bg-gray-100 text-black rounded-lg shadow-lg p-4 overflow-y-auto">
        {/* Search Input */}
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            className="w-full p-2 border border-gray-300 rounded bg-white"
            placeholder="Search products"
          />
          <button
            onClick={toggleSearch}
            className="ml-2 text-gray-600 hover:text-gray-900 focus:outline-none"
          >
            &#10005; {/* Unicode for 'X' */}
          </button>
        </div>

        {/* Filtered Results */}
        {filteredProducts.length > 0 ? (
          <ul className="mt-4">
            {filteredProducts.map((product) => (
              <li key={product.id} className="mb-4">
                <Link
                  to={`/${product.slug}`}
                  className="flex gap-4 flex-col sm:flex-row"
                  onClick={toggleSearch} // Close search box on click
                >
                  {/* Image */}
                  <img
                    src={product?.images[0]?.url}
                    alt={product.title}
                    className="w-16 h-16 rounded-full"
                  />

                  {/* Text Info */}
                  <div>
                    <h3 className="font-bold text-base sm:text-xl">
                      {truncateText(product.title)}
                    </h3>
                    {product.description && (
                      <p className="text-sm sm:text-base text-gray-600">
                        {truncateText(product.description, 10)}
                      </p>
                    )}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center font-bold text-lg">No products found...</p>
        )}
      </div>
    )
  );
};

export default SearchBox;
