import React from "react";
import Navbar from "./components/comman/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/comman/Footer";
import SocialMediaBar from "./components/comman/SocialMedia";
import ScrollToTop from "./components/comman/ScrollToTop";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Product from "./pages/Product";
import SingleProduct from "./pages/SingleProduct";
import CallButton from "./components/comman/CallButton";
import TopNav from "./components/comman/TopNav";
import {
  BUMPERBRACKETProduct,
  bumperProducts,
  CLADDINGProduct,
  CoolantBottlesCaps,
  FENDERLININGProduct,
  FOGLAMPCOVERSProduct,
  FOGLAMPProduct,
  FRONTGRILLSANDBUMPERGRILProduct,
  MudFlapsproduct,
  otherPartsProduct,
  REARWIPERBLADEProduct,
  SideMirrorProduct,
  subMirrorProduct,
  wiperBladeProduct,
  wiperBoltProduct,
  FENDERProduct,
  bonnetProduct,
} from "./data/product";
import {
  BUMPERBRACKETFilter,
  bumperFilters,
  CLADDINGilter,
  CoolantBottlesCapsFilter,
  FENDERLININGFilter,
  FOGLAMPCOVERSFILTER,
  FOGLAMPFilter,
  FRONTGRILLSANDBUMPERGRILLSFilter,
  MudFlapsFilter,
  otherPartsFilter,
  REARWIPERBLADEFIlter,
  SideMirrorFilter,
  subMirrorFilter,
  wiperBladeFilter,
  wiperBoltFilter,
  FENDERFilter,
  bonnetFilter,
} from "./data/filter";
import TermsAndConditions from "./pages/TermsAndConditions";

const App = () => {
  const productRoutes = [
    {
      path: "/bumper",
      products: bumperProducts,
      filters: bumperFilters,
      title: "Bumper - Car Body Parts Automotive Parts",
    },
    {
      path: "/fenderline",
      products: FENDERLININGProduct,
      filters: FENDERLININGFilter,
      title: "Fender Line - Car Body Parts Automotive Parts",
    },
    {
      path: "/fender",
      products: FENDERProduct,
      filters: FENDERFilter,
      title: "Fender - Car Body Parts Automotive Parts",
    },
    {
      path: "/frontgrillsandbumpergrills",
      products: FRONTGRILLSANDBUMPERGRILProduct,
      filters: FRONTGRILLSANDBUMPERGRILLSFilter,
      title: "Grills - Car Body Parts Automotive Parts",
    },
    {
      path: "/foglampcover",
      products: FOGLAMPCOVERSProduct,
      filters: FOGLAMPCOVERSFILTER,
      title: "Fog Lamp Covers - Car Body Parts Automotive Parts",
    },
    {
      path: "/foglamp",
      products: FOGLAMPProduct,
      filters: FOGLAMPFilter,
      title: "Fog Lamps - Car Body Parts Automotive Parts",
    },
    {
      path: "/bumperbracket",
      products: BUMPERBRACKETProduct,
      filters: BUMPERBRACKETFilter,
      title: "Bumper Brackets - Car Body Parts Automotive Parts",
    },
    {
      path: "/mudflaps",
      products: MudFlapsproduct,
      filters: MudFlapsFilter,
      title: "Mud Flaps - Car Body Parts Automotive Parts",
    },
    {
      path: "/submirror",
      products: subMirrorProduct,
      filters: subMirrorFilter,
      title: "Sub Mirrors - Car Body Parts Automotive Parts",
    },
    {
      path: "/sidemirror",
      products: SideMirrorProduct,
      filters: SideMirrorFilter,
      title: "Side Mirrors - Car Body Parts Automotive Parts",
    },
    {
      path: "/coolantbottlescaps",
      products: CoolantBottlesCaps,
      filters: CoolantBottlesCapsFilter,
      title: "Coolant Bottles & Caps - Car Body Parts Automotive Parts",
    },
    {
      path: "/wiperbolt",
      products: wiperBoltProduct,
      filters: wiperBoltFilter,
      title: "Wiper Bolts - Car Body Parts Automotive Parts",
    },
    {
      path: "/cladding",
      products: CLADDINGProduct,
      filters: CLADDINGilter,
      title: "Cladding - Car Body Parts Automotive Parts",
    },
    {
      path: "/other",
      products: otherPartsProduct,
      filters: otherPartsFilter,
      title: "Other Parts - Car Body Parts Automotive Parts",
    },

    {
      path: "/wiperblade",
      products: wiperBladeProduct,
      filters: wiperBladeFilter,
      title: "Wiper Blades - Car Body Parts Automotive Parts",
    },
    {
      path: "/rearwiperblade",
      products: REARWIPERBLADEProduct,
      filters: REARWIPERBLADEFIlter,
      title: "Rear Wiper Blades - Car Body Parts Automotive Parts",
    },
    {
      path: "/bonnet",
      products: bonnetProduct,
      filters: bonnetFilter,
      title: "Rear Wiper Blades - Car Body Parts Automotive Parts",
    },
  ];

  return (
    <div>
      <TopNav />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        {productRoutes.map(({ path, products, filters, title }) => (
          <Route
            key={path}
            path={path}
            element={
              <Product products={products} filters={filters} title={title} />
            }
          />
        ))}
        {productRoutes.map(({ path, products, title }) => (
          <Route
            key={`${path}-id`}
            path={`${path}/:id`}
            element={<SingleProduct products={products} title={title} />}
          />
        ))}
      </Routes>
      <SocialMediaBar />
      <ScrollToTop />
      <CallButton />
      <Footer />
    </div>
  );
};

export default App;
