import React from "react";
import {
  FaHandshake,
  FaShieldAlt,
  FaRegLightbulb,
  FaCog,
  FaCertificate,
  FaCheckCircle,
} from "react-icons/fa";

const cards = [
  {
    title: "Quality Parts",
    description:
      "At Car Body Parts Automotive Parts, we provide only top-quality car parts from trusted manufacturers, ensuring durability and performance. Our selection is carefully curated to meet industry standards.",
    icon: <FaHandshake className="text-4xl mb-4" />,
    bgColor: "bg-[#631f28] text-white",
  },
  {
    title: "Reliable Service",
    description:
      "Car Body Parts Automotive Parts stands behind every product and service. We are transparent with pricing, and you can trust us to deliver parts that meet your car's exact specifications.",
    icon: <FaShieldAlt className="text-4xl mb-4" />,
    bgColor: "bg-[#3b4451] text-white",
  },
  {
    title: "Expert Guidance",
    description:
      "At Car Body Parts Automotive Parts, we focus on providing solutions that fit your car’s needs. Our experts will guide you through the process, ensuring you find the right part for the job.",
    icon: <FaRegLightbulb className="text-4xl mb-4" />,
    bgColor: "bg-gray-200 text-[#631f28]",
  },
  {
    title: "Proven Expertise",
    description:
      "With years of experience in the automotive industry, Car Body Parts Automotive Parts offers recommendations based on in-depth research and real-world data, ensuring you get the best options.",
    icon: <FaCog className="text-4xl mb-4" />,
    bgColor: "bg-gray-200 text-[#631f28]",
  },
  {
    title: "Tech-Driven",
    description:
      "Our inventory system at Car Body Parts Automotive Parts is powered by advanced technology, making it easy to search and find the exact parts you need, with accurate information and availability.",
    icon: <FaCertificate className="text-4xl mb-4" />,
    bgColor: "bg-[#631f28] text-white",
  },
  {
    title: "Trusted Reputation",
    description:
      "Car Body Parts Automotive Parts has earned the trust of thousands of customers with our commitment to quality and service. Our reputation in the industry speaks for itself.",
    icon: <FaCheckCircle className="text-4xl mb-4" />,
    bgColor: "bg-[#3b4451] text-white",
  },
];

const AboutGrid = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 max-w-7xl mx-auto">
      {cards.map((card, index) => (
        <div
          key={index}
          className={`transform hover:rotate-360 hover:scale-95 transition-all duration-500 ease-in-out ${card.bgColor} p-6 flex flex-col items-center justify-center`}
        >
          {card.icon}
          <h2 className="text-2xl font-bold">{card.title}</h2>
          <p className="mt-2 text-center">{card.description}</p>
        </div>
      ))}
    </div>
  );
};

export default AboutGrid;
