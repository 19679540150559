import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const SingleProduct = ({ products }) => {
  const { id } = useParams();
  const product = products.find((prod) => prod.id === parseInt(id));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!product) {
    return <p>Product not found</p>;
  }
  return (
    <div className="max-w-5xl mx-auto p-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Image Section */}
        <div className="flex justify-center">
          <div className="w-full h-96 bg-gray-200 rounded shadow-lg overflow-hidden flex items-center justify-center">
            <img
              src={product.image}
              alt={product.title}
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>

        {/* Product Details Section */}
        <div className="flex flex-col justify-center">
          <h1 className="text-3xl font-bold mb-6">{product.title}</h1>

          <table className="w-full table-auto text-left border-collapse mb-6 bg-gray-100 rounded shadow-lg">
            <tbody>
              <tr className="border-b">
                <td className="font-semibold py-2 pr-4">Price:</td>
                <td className="py-2 text-gray-700">${product.price}</td>
              </tr>
              <tr className="border-b">
                <td className="font-semibold py-2 pr-4">Description:</td>
                <td className="py-2 text-gray-700">{product.description}</td>
              </tr>
              <tr className="border-b">
                {/* <td className="font-semibold py-2 pr-4">Brand:</td> */}
                {/* <td className="py-2 text-gray-700">{product.brand}</td> */}
              </tr>
              <tr className="border-b">
                <td className="font-semibold py-2 pr-4">Year:</td>
                <td className="py-2 text-gray-700">{product.year}</td>
              </tr>
              <tr className="border-b">
                <td className="font-semibold py-2 pr-4">Model:</td>
                <td className="py-2 text-gray-700">{product.model}</td>
              </tr>
              <tr className="border-b">
                <td className="font-semibold py-2 pr-4">Submodel:</td>
                <td className="py-2 text-gray-700">{product.submodel}</td>
              </tr>
              <tr className="border-b">
                <td className="font-semibold py-2 pr-4">Engine:</td>
                <td className="py-2 text-gray-700">{product.engine}</td>
              </tr>
            </tbody>
          </table>

          <a
            href="https://wa.me/+919351014634"
            // href="https://wa.me/+918103452652"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#e2571a] text-base font-medium text-white hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Send Inquiry
          </a>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
