import React from "react";
import { subnav } from "../../data/navlink";
import { Link } from "react-router-dom";

const BottomNavbar = () => {
  return (
    <div className="bg-gray-200 shadow">
      <div className="max-w-7xl mx-auto">
        <div className="flex whitespace-nowrap overflow-x-auto scroll-smooth">
          {subnav.map((currElem, index) => (
            <Link
              to={currElem?.path}
              key={index}
              className="flex-shrink-0 px-4 py-2 font-bold"
            >
              {currElem?.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
