export const all = [
    {
        title: "Front Car Bumper",
        description: "Durable front car bumper for Toyota vehicles.",
        images: [{ url: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/repn010348p_1?_a=BAVAfVGd0" }],
        slug: "bumper",
    },
    {
        title: "Toyota Headlight",
        description: "High-quality Toyota headlight for improved visibility.",
        images: [{ url: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/morlf268_1?_a=BAVAfVGd0" }],
        slug: "headlights",
    },
    {
        title: "Toyota Side Mirror",
        description: "Durable side mirror for Toyota cars.",
        images: [{ url: "https://cld.partsimg.com/image/upload/c_limit,w_582/e_sharpen/f_auto/q_auto/v1/images/c7345000_1?_a=BAVAfVGd0" }],
        slug: "mirrors",
    },
    {
        title: "Toyota Fender",
        description: "Durable side mirror for Toyota cars.",
        images: [{ url: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/repd220101_1?_a=BAVAfVGd0" }],
        slug: "fenders",
    },
]