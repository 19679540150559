export const navlinks = [



    {
        id: 6,
        title: "Products",
        path: "#",
        sublink: [
            { name: "Bumper", path: "/bumper", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/repn010348p_1?_a=BAVAfVGd0" },
            { name: "Fender Line", path: "/fenderline", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/fenderline_1.jpg" },
            { name: "Fender ", path: "/fender", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/fenderline_1.jpg" },
            { name: "Grills", path: "/frontgrillsandbumpergrills", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/grills_1.jpg" },
            { name: "Fog Lamp Covers", path: "/foglampcover", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/foglampcover_1.jpg" },
            { name: "Fog Lamps", path: "/foglamp", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/foglamp_1.jpg" },
            { name: "Bumper Brackets", path: "/bumperbracket", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/bumperbracket_1.jpg" },
            { name: "Mud Flaps", path: "/mudflaps", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/mudflaps_1.jpg" },
            { name: "Sub Mirrors", path: "/submirror", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/submirror_1.jpg" },
            { name: "Side Mirrors", path: "/sidemirror", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/sidemirror_1.jpg" },
            { name: "Coolant Bottles & Caps", path: "/coolantbottlescaps", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/coolantbottlescaps_1.jpg" },
            { name: "Wiper Bolts", path: "/wiperbolt", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/wiperbolt_1.jpg" },
            { name: "Cladding", path: "/cladding", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/cladding_1.jpg" },
            { name: "Other Parts", path: "/other", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/otherparts_1.jpg" },
            { name: "Wiper Blades", path: "/wiperblade", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/wiperblades_1.jpg" },
            { name: "Rear Wiper Blades", path: "/rearwiperblade", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/rearwiperblades_1.jpg" },
            { name: "Bonnet", path: "/bonnet", image: "https://cld.partsimg.com/image/upload/d_noimage.jpg/c_limit,w_200/e_sharpen/f_auto/q_auto/v1/images/rearwiperblades_1.jpg" }
        ]


    },

]

export const subnav = [


    { name: "Bumper", path: "/bumper" },
    { name: "Fender Line", path: "/fenderline" },
    { name: "Fender ", path: "/fender" },
    { name: "Grills", path: "/frontgrillsandbumpergrills" },
    { name: "Fog Lamp Covers", path: "/foglampcover" },
    { name: "Fog Lamps", path: "/foglamp" },
    { name: "Bumper Brackets", path: "/bumperbracket" },
    { name: "Mud Flaps", path: "/mudflaps" },
    { name: "Sub Mirrors", path: "/submirror" },
    { name: "Side Mirrors", path: "/sidemirror" },
    // { name: "Coolant Bottles & Caps", path: "/coolantbottlescaps" },
    // { name: "Wiper Bolts", path: "/wiperbolt" },
    { name: "Cladding", path: "/cladding" },
    // { name: "Other Parts", path: "/other" },
    // { name: "Wiper Blades", path: "/wiperblade" },
    // { name: "Rear Wiper Blades", path: "/rearwiperblade" },
    // { name: "Bonnet", path: "/bonnet" }


]