import React from "react";

const TopNav = () => {
  return (
    <div className="bg-gray-200">
      <div className="flex gap-3 md:gap-5 p-3 max-w-7xl mx-auto justify-center items-center text-center">
        {/* Show these on large screens */}
        <p className="hidden lg:block text-xs md:text-base">
          Guaranteed to Fit Convenient vehicle selection
        </p>
        <span className="hidden lg:inline"> | </span>
        <p className="hidden lg:block text-xs md:text-base">
          Fast Shipping Get the part when you need it
        </p>
        <span className="hidden lg:inline"> | </span>
        <p className="hidden lg:block text-xs md:text-base">
          Hassle-Free Returns 
        </p>

        {/* Show these on small screens (below lg) */}
        <p className="block lg:hidden text-xs md:text-base">
          Guaranteed to Fit
        </p>
        <p className="block lg:hidden text-xs md:text-base">Fast Shipping</p>
        <p className="block lg:hidden text-xs md:text-base">
          Hassle-Free Returns
        </p>
      </div>
    </div>
  );
};

export default TopNav;
