export const bumperProducts = [

    {
        id: 1,
        title: "CAR T-2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 850,
        description: "CBH-001",
        model: "MSZ",
        submodel: "CAR T-2",
        link: "/bumper"
    },
    {
        id: 2,
        title: "CAR T-2 REAR",
        image: "https://via.placeholder.com/800",
        price: 850,
        description: "CBH-002",
        model: "MSZ",
        submodel: "CAR T-2",
        link: "/bumper"
    },
    {
        id: 3,
        title: "OMNI FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5.jpg",
        price: 850,
        description: "CBH-003",
        model: "MSZ",
        submodel: "OMNI",
        link: "/bumper"
    },
    {
        id: 4,
        title: "OMNI REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6.jpg",
        price: 850,
        description: "CBH-004",
        model: "MSZ",
        submodel: "OMNI",
        link: "/bumper"
    },
    {
        id: 5,
        title: "EECO FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2.jpg",
        price: 1000,
        description: "CBH-005",
        model: "MSZ",
        submodel: "EECO",
        link: "/bumper"
    },
    {
        id: 6,
        title: "EECO REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_9.jpg",
        price: 1000,
        description: "CBH-006",
        model: "MSZ",
        submodel: "EECO",
        link: "/bumper"
    },
    {
        id: 7,
        title: "ALTO T-2 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000.jpg",
        price: 900,
        description: "CBH-007",
        model: "MSZ",
        submodel: "ALTO T-2",
        link: "/bumper"
    },
    {
        id: 8,
        title: "ALTO T-2 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_4.jpg",
        price: 900,
        description: "CBH-008",
        model: "MSZ",
        submodel: "ALTO T-2",
        link: "/bumper"
    },
    {
        id: 9,
        title: "SWIFT T-2 FRONT /dzire t-1",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_8.jpg",
        price: 1300,
        description: "CBH-009",
        model: "MSZ",
        submodel: "SWIFT T-2",
        link: "/bumper"
    },
    {
        id: 10,
        title: "SWIFT T2 FRONT WITH GRILLE",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_8.jpg",
        price: 1350,
        description: "CBH-146",
        model: "MSZ",
        submodel: "SWIFT T-2",
        link: "/bumper"
    },
    {
        id: 11,
        title: "SWIFT T-2 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_12.jpg",
        price: 1250,
        description: "CBH-010",
        model: "MSZ",
        submodel: "SWIFT T-2",
        link: "/bumper"
    },
    {
        id: 12,
        title: "SWIFT T-3 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_10.jpg",
        price: 1250,
        description: "CBH-011",
        model: "MSZ",
        submodel: "SWIFT T-3",
        link: "/bumper"
    },
    {
        id: 13,
        title: "SWIFT T-3 FRONT WITH GRILLE",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/0/0/001_10.jpg",
        price: 1400,
        description: "CBH-012",
        model: "MSZ",
        submodel: "SWIFT T-3",
        link: "/bumper"
    },
    {
        id: 14,
        title: "SWIFT T-3/4 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_14.jpg",
        price: 1450,
        description: "CBH-013",
        model: "MSZ",
        submodel: "SWIFT T-3",
        link: "/bumper"
    },
    {
        id: 15,
        title: "SWIFT T-4 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1250,
        description: "CBH-123",
        model: "MSZ",
        submodel: "SWIFT T-4",
        link: "/bumper"
    },
    {
        id: 16,
        title: "SWIFT T-4 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 1400,
        description: "CBH-175",
        model: "MSZ",
        submodel: "SWIFT T-4",
        link: "/bumper"
    },
    {
        id: 17,
        title: "SWIFT T-5 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1400,
        description: "CBH-258",
        model: "MSZ",
        submodel: "SWIFT T-5",
        link: "/bumper"
    },
    {
        id: 18,
        title: "K10 T-1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_13.jpg",
        price: 1200,
        description: "CBH-014",
        model: "MSZ",
        submodel: "K10 T-1",
        link: "/bumper"
    },
    {
        id: 19,
        title: "K10 T-2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1200,
        description: "CBH-262",
        model: "MSZ",
        submodel: "K10 T-1",
        link: "/bumper"
    },
    {
        id: 20,
        title: "WAGONR T-3 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_14.jpg",
        price: 1350,
        description: "CBH-015",
        model: "MSZ",
        submodel: "WAGONR T-3",
        link: "/bumper"
    },
    {
        id: 21,
        title: "WAGONR T-5 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1200,
        description: "CBH-272",
        model: "MSZ",
        submodel: "WAGONR T-5",
        link: "/bumper"
    },
    {
        id: 22,
        title: "WAGONR T-5 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 1500,
        description: "CBH-295",
        model: "MSZ",
        submodel: "WAGONR T-5",
        link: "/bumper"
    },
    {
        id: 23,
        title: "DZIRE T-1 FRONT/SWIFT T-2",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_16.jpg",
        price: 1250,
        description: "CBH-009",
        model: "MSZ",
        submodel: "DZIRE T-1",
        link: "/bumper"
    },
    {
        id: 24,
        title: "DZIRE T-1 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_16.jpg",
        price: 1700,
        description: "CBH-017",
        model: "MSZ",
        submodel: "DZIRE T-1",
        link: "/bumper"
    },
    {
        id: 25,
        title: "DZIRE T-2 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_14.jpg",
        price: 1300,
        description: "CBH-016",
        model: "MSZ",
        submodel: "DZIRE T-2",
        link: "/bumper"
    },
    {
        id: 26,
        title: "DZIRE T-2 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 1400,
        description: "CBH-168",
        model: "MSZ",
        submodel: "DZIRE T-2",
        link: "/bumper"
    },
    {
        id: 27,
        title: "DZIRE T-3 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_15.jpg",
        price: 1400,
        description: "CBH-116",
        model: "MSZ",
        submodel: "DZIRE T-3",
        link: "/bumper"
    },
    {
        id: 28,
        title: "DZIRE T-3 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 1500,
        description: "CBH-176",
        model: "MSZ",
        submodel: "DZIRE T-3",
        link: "/bumper"
    },
    {
        id: 29,
        title: "DZIRE T2/3 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_18.jpg",
        price: 1500,
        description: "CBH-069",
        model: "MSZ",
        submodel: "DZIRE T-3",
        link: "/bumper"
    },
    {
        id: 30,
        title: "DZIRE T-4 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1350,
        description: "CBH-124",
        model: "MSZ",
        submodel: "DZIRE T-4",
        link: "/bumper"
    },
    {
        id: 31,
        title: "DZIRE T-5 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2000,
        description: "CBH-281",
        model: "MSZ",
        submodel: "DZIRE T-5",
        link: "/bumper"
    },
    {
        id: 32,
        title: "DZIRE T-5 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 3600,
        description: "CBH-296",
        model: "MSZ",
        submodel: "DZIRE T-5",
        link: "/bumper"
    },
    {
        id: 33,
        title: "ALTO 800 T1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_16.jpg",
        price: 900,
        description: "CBH-018",
        model: "MSZ",
        submodel: "ALTO 800 T1",
        link: "/bumper"
    },
    {
        id: 34,
        title: "ALTO 800 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 1300,
        description: "CBH-169",
        model: "MSZ",
        submodel: "ALTO 800",
        link: "/bumper"
    },
    {
        id: 35,
        title: "ALTO 800 T2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 950,
        description: "CBH-210",
        model: "MSZ",
        submodel: "ALTO 800 T2",
        link: "/bumper"
    },
    {
        id: 36,
        title: "ALTO 800 T2 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 1600,
        description: "CBH-242",
        model: "MSZ",
        submodel: "ALTO 800 T2",
        link: "/bumper"
    },
    {
        id: 37,
        title: "ALTO 800 T3 FRONT",
        image: "https://via.placeholder.com/800",
        price: 950,
        description: "CBH-282",
        model: "MSZ",
        submodel: "ALTO 800 T3",
        link: "/bumper"
    },
    {
        id: 38,
        title: "ALTO 800 T1/2/3 REAR",
        image: "https://via.placeholder.com/800",
        price: 1150,
        description: "CBH-181",
        model: "MSZ",
        submodel: "ALTO 800",
        link: "/bumper"
    },
    {
        id: 39,
        title: "CELERIO T1 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1500,
        description: "CBH-140",
        model: "MSZ",
        submodel: "CELERIO T1",
        link: "/bumper"
    },
    {
        id: 40,
        title: "CELERIO T1 WITH HOLE FRONT",
        image: "https://via.placeholder.com/800",
        price: 1500,
        description: "CBH-143",
        model: "MSZ",
        submodel: "CELERIO T1",
        link: "/bumper"
    },
    {
        id: 41,
        title: "CELERIO T1 REAR",
        image: "https://via.placeholder.com/800",
        price: 1700,
        description: "CBH-141",
        model: "MSZ",
        submodel: "CELERIO T1",
        link: "/bumper"
    },
    {
        id: 42,
        title: "ERTIGA T1 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2200,
        description: "CBH-109",
        model: "MSZ",
        submodel: "ERTIGA T1",
        link: "/bumper"
    },
    {
        id: 43,
        title: "ERTIGA T2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2250,
        description: "CBH-183",
        model: "MSZ",
        submodel: "ERTIGA T2",
        link: "/bumper"
    },
    {
        id: 44,
        title: "ERTIGA T1/2 REAR",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-184",
        model: "MSZ",
        submodel: "ERTIGA T1/2",
        link: "/bumper"
    },
    {
        id: 45,
        title: "BALENO T1 FRONT",
        image: "https://via.placeholder.com/800",
        price: 3100,
        description: "CBH-300",
        model: "MSZ",
        submodel: "BALENO T1",
        link: "/bumper"
    },
    {
        id: 46,
        title: "BALENO T2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1600,
        description: "CBH-150",
        model: "MSZ",
        submodel: "BALENO T2",
        link: "/bumper"
    },
    {
        id: 47,
        title: "BREZZA FRONT",
        image: "https://via.placeholder.com/800",
        price: 1800,
        description: "CBH-151",
        model: "MSZ",
        submodel: "BREZZA",
        link: "/bumper"
    },
    {
        id: 48,
        title: "CIAZ T-1 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1900,
        description: "CBH-147",
        model: "MSZ",
        submodel: "CIAZ T-1",
        link: "/bumper"
    },

    {
        id: 49,
        title: "XING FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_21.jpg",
        price: 1150,
        description: "CBH-019",
        model: "HYD",
        submodel: "XING",
        link: "/bumper"
    },
    {
        id: 50,
        title: "ACCENT FRONT",
        image: "https://via.placeholder.com/800",
        price: 2200,
        description: "CBH-125",
        model: "HYD",
        submodel: "ACCENT",
        link: "/bumper"
    },
    {
        id: 51,
        title: "ACCENT REAR",
        image: "https://via.placeholder.com/800",
        price: 2300,
        description: "CBH-126",
        model: "HYD",
        submodel: "ACCENT",
        link: "/bumper"
    },
    {
        id: 52,
        title: "VERNA FLUIDIC FRONT WITH NP BOX",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_20.jpg",
        price: 2200,
        description: "CBH-070",
        model: "HYD",
        submodel: "VERNA FLUIDIC",
        link: "/bumper"
    },
    {
        id: 53,
        title: "VERNA FLUIDIC FRONT W/O NP",
        image: "https://via.placeholder.com/800",
        price: 2350,
        description: "CBH-070P",
        model: "HYD",
        submodel: "VERNA FLUIDIC",
        link: "/bumper"
    },
    {
        id: 54,
        title: "VERNA FLUDIC REAR",
        image: "https://via.placeholder.com/800",
        price: 3200,
        description: "CBH-113",
        model: "HYD",
        submodel: "VERNA FLUDIC",
        link: "/bumper"
    },
    {
        id: 55,
        title: "VERNA FLUDIC T3 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2300,
        description: "CBH-127",
        model: "HYD",
        submodel: "VERNA FLUDIC T3",
        link: "/bumper"
    },
    {
        id: 56,
        title: "VERNA FLUDIC T3 REAR",
        image: "https://via.placeholder.com/800",
        price: 2600,
        description: "CBH-252",
        model: "HYD",
        submodel: "VERNA FLUDIC T3",
        link: "/bumper"
    },
    {
        id: 57,
        title: "VERNA T-1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_19.jpg",
        price: 2500,
        description: "CBH-045",
        model: "HYD",
        submodel: "VERNA T-1",
        link: "/bumper"
    },
    {
        id: 58,
        title: "VERNA T-1 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 2250,
        description: "CBH-128",
        model: "HYD",
        submodel: "VERNA T-1",
        link: "/bumper"
    },
    {
        id: 59,
        title: "VERNA T-1 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/1/01.jpg",
        price: 2400,
        description: "CBH-046",
        model: "HYD",
        submodel: "VERNA T-1",
        link: "/bumper"
    },
    {
        id: 60,
        title: "EON FRONT W/O HOLE",
        image: "https://via.placeholder.com/800",
        price: 1150,
        description: "CBH-284",
        model: "HYD",
        submodel: "EON",
        link: "/bumper"
    },
    {
        id: 61,
        title: "EON FRONT WITH HOLE",
        image: "https://via.placeholder.com/800",
        price: 1150,
        description: "CBH-284A",
        model: "HYD",
        submodel: "EON",
        link: "/bumper"
    },
    {
        id: 62,
        title: "I 10 T1 FRONT COMPLETE",
        image: "https://via.placeholder.com/800",
        price: 2200,
        description: "CBH-142",
        model: "HYD",
        submodel: "I 10 T1",
        link: "/bumper"
    },
    {
        id: 63,
        title: "I 10 T2 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_16.jpg",
        price: 1250,
        description: "CBH-115",
        model: "HYD",
        submodel: "I 10 T2",
        link: "/bumper"
    },
    {
        id: 64,
        title: "I 10 T2 REAR",
        image: "https://via.placeholder.com/800",
        price: 2250,
        description: "CBH-190",
        model: "HYD",
        submodel: "I 10 T2",
        link: "/bumper"
    },
    {
        id: 65,
        title: "I10 GRAND T1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_17.jpg",
        price: 2200,
        description: "CBH-117",
        model: "HYD",
        submodel: "I10 GRAND T1",
        link: "/bumper"
    },
    {
        id: 66,
        title: "I10 GRAND T1 REAR",
        image: "https://via.placeholder.com/800",
        price: 2300,
        description: "CBH-283",
        model: "HYD",
        submodel: "I10 GRAND T1",
        link: "/bumper"
    },
    {
        id: 67,
        title: "XCENT T2 FRONT/i10 grand t2",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-193",
        model: "HYD",
        submodel: "XCENT T2",
        link: "/bumper"
    },
    {
        id: 68,
        title: "XCENT T2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2250,
        description: "CBH-193",
        model: "HYD",
        submodel: "XCENT T2",
        link: "/bumper"
    },
    {
        id: 69,
        title: "XCENT T2 REAR BUMPER WITH SPOILER",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-303",
        model: "HYD",
        submodel: "XCENT T2",
        link: "/bumper"
    },
    {
        id: 70,
        title: "I10 GRAND T2 REAR",
        image: "https://via.placeholder.com/800",
        price: 3250,
        description: "CBH-194",
        model: "HYD",
        submodel: "I10 GRAND T2",
        link: "/bumper"
    },
    {
        id: 71,
        title: "REAR BUMPER SPOILER i10 GRAND T2",
        image: "https://via.placeholder.com/800",
        price: 2850,
        description: "CBH-274",
        model: "HYD",
        submodel: "I10 GRAND T2",
        link: "/bumper"
    },
    {
        id: 72,
        title: "ELANTRA FLUIDIC FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_19.jpg",
        price: 2000,
        description: "CBH-049",
        model: "HYD",
        submodel: "ELANTRA FLUIDIC",
        link: "/bumper"
    },
    {
        id: 73,
        title: "ELANTRA FLUIDIC REAR",
        image: "https://via.placeholder.com/800",
        price: 2650,
        description: "CBH-259",
        model: "HYD",
        submodel: "ELANTRA FLUIDIC",
        link: "/bumper"
    },
    {
        id: 102222,
        title: "INNOVA T1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_29.jpg",
        price: 2500,
        description: "CBH-129",
        model: "TYT",
        submodel: "INNOVA T1",
        link: "/bumper"
    },
    {
        id: 74,
        title: "INNOVA T1 FRONT WITH F/L HOLE",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-213",
        model: "TYT",
        submodel: "INNOVA T1",
        link: "/bumper"
    },
    {
        id: 75,
        title: "INNOVA T2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1400,
        description: "CBH-022",
        model: "TYT",
        submodel: "INNOVA T2",
        link: "/bumper"
    },
    {
        id: 76,
        title: "INNOVA T-2/3/4 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_2_7.jpg",
        price: 1400,
        description: "CBH-023",
        model: "TYT",
        submodel: "INNOVA T-2/3/4",
        link: "/bumper"
    },
    {
        id: 77,
        title: "INNOVA T-3 FRONT WITH GRILLE IMPORTED",
        image: "https://via.placeholder.com/800",
        price: 2100,
        description: "CBH-053",
        model: "TYT",
        submodel: "INNOVA T-3",
        link: "/bumper"
    },
    {
        id: 78,
        title: "INNOVA T-3 FRONT W/O GRILLE",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_1_1.jpg",
        price: 1500,
        description: "CBH-292",
        model: "TYT",
        submodel: "INNOVA T-3",
        link: "/bumper"
    },
    {
        id: 79,
        title: "INNOVA T-3 FRONT WITH GRILLE",
        image: "https://via.placeholder.com/800",
        price: 2000,
        description: "CBH-294",
        model: "TYT",
        submodel: "INNOVA T-3",
        link: "/bumper"
    },
    {
        id: 80,
        title: "INNOVA T4 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_5.jpg",
        price: 1400,
        description: "CBH-066",
        model: "TYT",
        submodel: "INNOVA T4",
        link: "/bumper"
    },
    {
        id: 81,
        title: "INNOVA T4 FRONT WITH F/LAMP COVERS - CHR",
        image: "https://via.placeholder.com/800",
        price: 1650,
        description: "CBH-246",
        model: "TYT",
        submodel: "INNOVA T4",
        link: "/bumper"
    },
    {
        id: 82,
        title: "INNOVA T4 FRONT WITH F/LAMP COVERS - BLA",
        image: "https://via.placeholder.com/800",
        price: 1600,
        description: "CBH-253",
        model: "TYT",
        submodel: "INNOVA T4",
        link: "/bumper"
    },
    {
        id: 83,
        title: "INNOVA CRYSTA FRONT WITH FIXED GRILLE",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_25.jpg",
        price: 1900,
        description: "CBH-214",
        model: "TYT",
        submodel: "INNOVA CRYSTA",
        link: "/bumper"
    },
    {
        id: 84,
        title: "INNOVA CRYSTA FRONT WITH DETACHABLE GRI",
        image: "https://via.placeholder.com/800",
        price: 3100,
        description: "CBH-130",
        model: "TYT",
        submodel: "INNOVA CRYSTA",
        link: "/bumper"
    },
    {
        id: 85,
        title: "INNOVA CRYSTA REAR WITH SENSOR HOLE",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_26.jpg",
        price: 2700,
        description: "CBH-122",
        model: "TYT",
        submodel: "INNOVA CRYSTA",
        link: "/bumper"
    },
    {
        id: 86,
        title: "INNOVA CRYSTA REAR W/O SENSOR HOLE",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_26.jpg",
        price: 1950,
        description: "CBH-293",
        model: "TYT",
        submodel: "INNOVA CRYSTA",
        link: "/bumper"
    },
    {
        id: 87,
        title: "FORTUNER T1 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2800,
        description: "CBH-114",
        model: "TYT",
        submodel: "FORTUNER T1",
        link: "/bumper"
    },
    {
        id: 88,
        title: "FORTUNER T1 REAR",
        image: "https://via.placeholder.com/800",
        price: 3000,
        description: "CBH-131",
        model: "TYT",
        submodel: "FORTUNER T1",
        link: "/bumper"
    },
    {
        id: 89,
        title: "FORTUNER T1 REAR WITH SENSOR HOLE",
        image: "https://via.placeholder.com/800",
        price: 3000,
        description: "CBH-132",
        model: "TYT",
        submodel: "FORTUNER T1",
        link: "/bumper"
    },
    {
        id: 90,
        title: "FORTUNER T2 FRONT WITH HOLE",
        image: "https://via.placeholder.com/800",
        price: 2250,
        description: "CBH-105",
        model: "TYT",
        submodel: "FORTUNER T2",
        link: "/bumper"
    },
    {
        id: 91,
        title: "FORTUNER T2 FRONT W/O HOLE",
        image: "https://via.placeholder.com/800",
        price: 2250,
        description: "CBH-212",
        model: "TYT",
        submodel: "FORTUNER T2",
        link: "/bumper"
    },
    {
        id: 92,
        title: "FORTUNER T2 REAR",
        image: "https://via.placeholder.com/800",
        price: 2650,
        description: "CBH-152",
        model: "TYT",
        submodel: "FORTUNER T2",
        link: "/bumper"
    },
    {
        id: 93,
        title: "FORTUNER T3 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2800,
        description: "CBH-106",
        model: "TYT",
        submodel: "FORTUNER T3",
        link: "/bumper"
    },
    {
        id: 94,
        title: "FORTUNER T3 REAR",
        image: "https://via.placeholder.com/800",
        price: 3000,
        description: "CBH-195",
        model: "TYT",
        submodel: "FORTUNER T3",
        link: "/bumper"
    },
    {
        id: 95,
        title: "ETIOS/LIVA T1 FRONT",
        image: "https://via.placeholder.com/800",
        price: 1200,
        description: "CBH-208",
        model: "TYT",
        submodel: "ETIOS/LIVA T1",
        link: "/bumper"
    },
    {
        id: 96,
        title: "ETIOS/LIVA T1 FRONT W/GRILLE",
        image: "https://via.placeholder.com/800",
        price: 1200,
        description: "CBH-209",
        model: "TYT",
        submodel: "ETIOS/LIVA T1",
        link: "/bumper"
    },
    {
        id: 97,
        title: "ETIOS T1 REAR",
        image: "https://via.placeholder.com/800",
        price: 2800,
        description: "CBH-256",
        model: "TYT",
        submodel: "ETIOS/LIVA T1",
        link: "/bumper"
    },
    {
        id: 98,
        title: "ETIOS/LIVA PLATINUM FRONT",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-260",
        model: "TYT",
        submodel: "ETIOS/LIVA PLATINUM",
        link: "/bumper"
    },
    {
        id: 99,
        title: "ETIOS PLATINUM REAR",
        image: "https://via.placeholder.com/800",
        price: 2800,
        description: "CBH-254",
        model: "TYT",
        submodel: "ETIOS PLATINUM",
        link: "/bumper"
    },
    {
        id: 100,
        title: "ETIOS LIVA T1 REAR",
        image: "https://via.placeholder.com/800",
        price: 2800,
        description: "CBH-257",
        model: "TYT",
        submodel: "ETIOS LIVA",
        link: "/bumper"
    },
    {
        id: 101,
        title: "ETIOS LIVA PLATINUM REAR",
        image: "https://via.placeholder.com/800",
        price: 3000,
        description: "CBH-255",
        model: "TYT",
        submodel: "ETIOS/LIVA PLATINUM",
        link: "/bumper"
    },
    {
        id: 102,
        title: "ALTIS T-1 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-071",
        model: "TYT",
        submodel: "ALTIS T-1",
        link: "/bumper"
    },
    {
        id: 103,
        title: "ALTIS T-1 REAR",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-072",
        model: "TYT",
        submodel: "ALTIS T-1",
        link: "/bumper"
    },
    {
        id: 104,
        title: "ALTIS T-2 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-073",
        model: "TYT",
        submodel: "ALTIS T-2",
        link: "/bumper"
    },
    {
        id: 105,
        title: "ALTIS T-2 REAR",
        image: "https://via.placeholder.com/800",
        price: 2500,
        description: "CBH-074",
        model: "TYT",
        submodel: "ALTIS T-2",
        link: "/bumper"
    },
    {
        id: 106,
        title: "ALTIS T-3 FRONT",
        image: "https://via.placeholder.com/800",
        price: 2600,
        description: "CBH-050",
        model: "TYT",
        submodel: "ALTIS T-3",
        link: "/bumper"
    },
    {
        id: 107,
        title: "ALTIS T-3 REAR",
        image: "https://via.placeholder.com/800",
        price: 2800,
        description: "CBH-118",
        model: "TYT",
        submodel: "ALTIS T-3",
        link: "/bumper"
    },
    {
        "id": 108,
        "title": "CITY T-3 FRONT",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_24.jpg",
        "price": 2400,
        "description": "CBH-037",
        "model": "HND",
        "submodel": "CITY T-3",
        "link": "/bumper"
    },
    {
        "id": 109,
        "title": "CITY T-3 REAR",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_30.jpg",
        "price": 2800,
        "description": "CBH-065",
        "model": "HND",
        "submodel": "CITY T-3",
        "link": "/bumper"
    },
    {
        "id": 110,
        "title": "CITY T-4 / ZX FRONT",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_22.jpg",
        "price": 1600,
        "description": "CBH-020",
        "model": "HND",
        "submodel": "CITY T-4/ZX",
        "link": "/bumper"
    },
    {
        "id": 111,
        "title": "CITY T-4 / ZX REAR",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_25.jpg",
        "price": 2400,
        "description": "CBH-038",
        "model": "HND",
        "submodel": "CITY T-4/ZX",
        "link": "/bumper"
    },
    {
        "id": 112,
        "title": "CITY T-5 FRONT W/O HOLE",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_26.jpg",
        "price": 2400,
        "description": "CBH-067",
        "model": "HND",
        "submodel": "CITY T-5",
        "link": "/bumper"
    },
    {
        "id": 113,
        "title": "CITY T-5 FRONT WITH HOLE",
        "image": "https://via.placeholder.com/800",
        "price": 2400,
        "description": "CBH-039",
        "model": "HND",
        "submodel": "CITY T-5",
        "link": "/bumper"
    },
    {
        "id": 114,
        "title": "CITY T-5 REAR",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/1/01_1.jpg",
        "price": 2500,
        "description": "CBH-040",
        "model": "HND",
        "submodel": "CITY T-5",
        "link": "/bumper"
    },
    {
        "id": 115,
        "title": "CITY T-6 FRONT",
        "image": "https://via.placeholder.com/800",
        "price": 3000,
        "description": "CBH-041",
        "model": "HND",
        "submodel": "CITY T-6",
        "link": "/bumper"
    },
    {
        "id": 116,
        "title": "CITY T-6 REAR",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_24.jpg",
        "price": 3000,
        "description": "CBH-042",
        "model": "HND",
        "submodel": "CITY T-6",
        "link": "/bumper"
    },
    {
        "id": 117,
        "title": "CITY T-7 FRONT (WITH ENGINE GUARD)",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_25.jpg",
        "price": 2200,
        "description": "CBH-055",
        "model": "HND",
        "submodel": "CITY T-7",
        "link": "/bumper"
    },
    {
        "id": 118,
        "title": "CITY T-7 REAR",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_28.jpg",
        "price": 2500,
        "description": "CBH-075",
        "model": "HND",
        "submodel": "CITY T-7",
        "link": "/bumper"
    },
    {
        "id": 119,
        "title": "CITY T-8 FRONT",
        "image": "https://via.placeholder.com/800",
        "price": 2500,
        "description": "CBH-182",
        "model": "HND",
        "submodel": "CITY T-8",
        "link": "/bumper"
    },
    {
        "id": 120,
        "title": "CITY T-8 REAR",
        "image": "https://via.placeholder.com/800",
        "price": 2700,
        "description": "CBH-191",
        "model": "HND",
        "submodel": "CITY T-8",
        "link": "/bumper"
    },
    {
        "id": 121,
        "title": "CITY T-9 FRONT",
        "image": "https://via.placeholder.com/800",
        "price": 3400,
        "description": "CBH-278",
        "model": "HND",
        "submodel": "CITY T-9",
        "link": "/bumper"
    },
    {
        "id": 122,
        "title": "CIVIC T-1 FRONT",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_19.jpg",
        "price": 2600,
        "description": "CBH-133",
        "model": "HND",
        "submodel": "CIVIC T-1",
        "link": "/bumper"
    },
    {
        "id": 123,
        "title": "CIVIC T1/2 REAR",
        "image": "https://via.placeholder.com/800",
        "price": 2600,
        "description": "CBH-134",
        "model": "HND",
        "submodel": "CIVIC T1/2",
        "link": "/bumper"
    },
    {
        "id": 124,
        "title": "CIVIC T-2 FRONT",
        "image": "https://via.placeholder.com/800",
        "price": 3000,
        "description": "CBH-076",
        "model": "HND",
        "submodel": "CIVIC T-2",
        "link": "/bumper"
    },
    {
        "id": 125,
        "title": "CRV T3 FRONT UPPER",
        "image": "https://via.placeholder.com/800",
        "price": 3302,
        "description": "CBH-186",
        "model": "HND",
        "submodel": "CRV T3",
        "link": "/bumper"
    },
    {
        "id": 126,
        "title": "CRV T3 FRONT LOWER",
        "image": "https://via.placeholder.com/800",
        "price": 3237,
        "description": "CBH-185",
        "model": "HND",
        "submodel": "CRV T3",
        "link": "/bumper"
    },
    {
        "id": 127,
        "title": "CRV T3 REAR",
        "image": "https://via.placeholder.com/800",
        "price": 3237,
        "description": "CBH-187",
        "model": "HND",
        "submodel": "CRV T3",
        "link": "/bumper"
    },
    {
        "id": 128,
        "title": "CRV T3 SIDE COVER",
        "image": "https://via.placeholder.com/800",
        "price": 2327,
        "description": "CBH-188",
        "model": "HND",
        "submodel": "CRV T3",
        "link": "/bumper"
    },
    {
        "id": 129,
        "title": "ACCORD T4 FRONT",
        "image": "https://via.placeholder.com/800",
        "price": 2958,
        "description": "CBH-077",
        "model": "HND",
        "submodel": "ACCORD T4",
        "link": "/bumper"
    },
    {
        "id": 130,
        "title": "ACCORD T4 REAR",
        "image": "https://via.placeholder.com/800",
        "price": 3627,
        "description": "CBH-153",
        "model": "HND",
        "submodel": "ACCORD T4",
        "link": "/bumper"
    },
    {
        "id": 131,
        "title": "ACCORD T2/T3 FRONT",
        "image": "https://via.placeholder.com/800",
        "price": 3757,
        "description": "CBH-080",
        "model": "HND",
        "submodel": "ACCORD T2/T3",
        "link": "/bumper"
    },
    {
        "id": 132,
        "title": "ACCORD T-2/3 REAR",
        "image": "https://via.placeholder.com/800",
        "price": 3845,
        "description": "CBH-084",
        "model": "HND",
        "submodel": "ACCORD T2/T3",
        "link": "/bumper"
    },
    {
        "id": 133,
        "title": "ODYSSEY REAR",
        "image": "https://via.placeholder.com/800",
        "price": 2500,
        "description": "CBH-081",
        "model": "HND",
        "submodel": "ODYSSEY",
        "link": "/bumper"
    },
    {
        "id": 134,
        "title": "INDIGO CS FRONT",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_8.jpg",
        "description": "CBH-024",
        "price": 1550,
        "model": "TAT",
        "submodel": "INDIGO CS",
        "link": "/bumper"
    },
    {
        "id": 135,
        "title": "INDICA EV2 REAR",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_1.jpg",

        "description": "CBH-025",
        "price": 1400,
        "model": "TAT",
        "submodel": "INDICA EV2",
        "link": "/bumper"
    },
    {
        "id": 136,
        "title": "INDICA XETA FRONT",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_7.jpg",

        "description": "CBH-026",
        "price": 1650,
        "model": "TAT",
        "submodel": "INDICA XETA",
        "link": "/bumper"
    },
    {
        "id": 137,
        "title": "INDIGO ECS FRONT",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_8.jpg",
        "description": "CBH-027",
        "price": 1650,
        "model": "TAT",
        "submodel": "INDIGO ECS",
        "link": "/bumper"
    },
    {
        "id": 138,
        "title": "INDIGO ECS REAR",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_1_6.jpg",

        "description": "CBH-028",
        "price": 1400,
        "model": "TAT",
        "submodel": "INDIGO ECS",
        "link": "/bumper"
    },
    {
        "id": 139,
        "title": "INDIGO ECS FRONT W/O GRILLE",
        "description": "CBH-029",
        "price": 1400,
        "model": "TAT",
        "submodel": "INDIGO ECS",
        "link": "/bumper"
    },
    {
        "id": 140,
        "title": "TATA ACE FRONT",
        "image": "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_10.jpg",

        "description": "CBH-030",
        "price": 600,
        "model": "TAT",
        "submodel": "TATA ACE",
        "link": "/bumper"
    },
    {
        "id": 141,
        "title": "TATA ACE HT FRONT",
        "description": "CBH-100",
        "price": 1000,
        "model": "TAT",
        "submodel": "TATA ACE HT",
        "link": "/bumper"
    },
    {
        "id": 142,
        "title": "TIAGO/TIGOR T1 FRONT",
        "description": "CBH-149",
        "price": 1850,
        "model": "TAT",
        "submodel": "TIAGO/TIGOR T1",
        "link": "/bumper"
    },
    {
        "id": 143,
        "title": "TIAGO T1 REAR",
        "description": "CBH-222",
        "price": 2200,
        "model": "TAT",
        "submodel": "TIAGO T1",
        "link": "/bumper"
    },
    {
        "id": 144,
        "title": "TIGOR T1 REAR",
        "description": "CBH-225",
        "price": 2050,
        "model": "TAT",
        "submodel": "TIGOR T1",
        "link": "/bumper"
    },
    {
        "id": 145,
        "title": "TIAGO/TIGOR T2 FRONT",
        "description": "CBH-223",
        "price": 2350,
        "model": "TAT",
        "submodel": "TIAGO/TIGOR T2",
        "link": "/bumper"
    },
    {
        "id": 146,
        "title": "TIGOR T2 REAR",
        "description": "CBH-224",
        "price": 2250,
        "model": "TAT",
        "submodel": "TIGOR T2",
        "link": "/bumper"
    },
    {
        "id": 147,
        "title": "TIGOR T2 REAR LOWER GRILL",
        "description": "CBH-BG059",
        "price": 2250,
        "model": "TAT",
        "submodel": "TIGOR T2",
        "link": "/bumper"
    },
    {
        "id": 148,
        "title": "NANO T1 FRONT STD",
        "description": "CBH-219",
        "price": 2250,
        "model": "TAT",
        "submodel": "NANO T1 STD",
        "link": "/bumper"
    },
    {
        "id": 149,
        "title": "NANO T1 FRONT DLX",
        "description": "CBH-220",
        "price": 2300,
        "model": "TAT",
        "submodel": "NANO T1 DLX",
        "link": "/bumper"
    },
    {
        "id": 150,
        "title": "NANO T2 FRONT GEN-X",
        "description": "CBH-221",
        "price": 2400,
        "model": "TAT",
        "submodel": "NANO T2 GEN-X",
        "link": "/bumper"
    },
    {
        "id": 151,
        "title": "NANO STD/DLX REAR",
        "description": "CBH-261",
        "price": 2200,
        "model": "TAT",
        "submodel": "NANO STD/DLX",
        "link": "/bumper"
    },
    {
        "id": 152,
        "title": "VISTA/MANZA T2 FRONT",
        "description": "CBH-230",
        "price": 4400,
        "model": "TAT",
        "submodel": "VISTA/MANZA T2",
        "link": "/bumper"
    },
    {
        "id": 153,
        "title": "VISTA REAR WITH REFLECTOR",
        "description": "CBH-232",
        "price": 2650,
        "model": "TAT",
        "submodel": "VISTA WITH REFLECTOR",
        "link": "/bumper"
    },
    {
        "id": 154,
        "title": "MANZA REAR",
        "description": "CBH-233",
        "price": 2900,
        "model": "TAT",
        "submodel": "MANZA",
        "link": "/bumper"
    },
    {
        "id": 155,
        "title": "ZEST FRONT",
        "description": "CBH-229",
        "price": 2250,
        "model": "TAT",
        "submodel": "ZEST",
        "link": "/bumper"
    },
    {
        "id": 156,
        "title": "BOLT FRONT",
        "description": "CBH-223",
        "price": 2300,
        "model": "TAT",
        "submodel": "BOLT",
        "link": "/bumper"
    },
    {
        "id": 157,
        "title": "ALTROZ FRONT",
        "description": "CBH-227",
        "price": 2200,
        "model": "TAT",
        "submodel": "ALTROZ",
        "link": "/bumper"
    },
    {
        "id": 158,
        "title": "HARRIER FRONT",
        "description": "CBH-228",
        "price": 3000,
        "model": "TAT",
        "submodel": "HARRIER",
        "link": "/bumper"
    },

    // above mistakes 
    {
        id: 159,
        title: "M HAWK FRONT",
        description: "CBH-031",
        price: 1350,
        model: "MHD",
        submodel: "M HAWK",
        link: "/bumper"
    },
    {
        id: 160,
        title: "M HAWK FRONT WITH HOLE",
        description: "CBH-031A",
        price: 1350,
        model: "MHD",
        submodel: "M HAWK",
        link: "/bumper"
    },
    {
        id: 161,
        title: "SCORPIO S2",
        description: "CBH-211",
        price: 1500,
        model: "MHD",
        submodel: "SCORPIO S2",
        link: "/bumper"
    },
    {
        id: 162,
        title: "SCORPIO S2 WITH GRILLE",
        description: "CBH-275",
        price: 1700,
        model: "MHD",
        submodel: "SCORPIO S2",
        link: "/bumper"
    },
    {
        id: 163,
        title: "SCORPIO S10 (RECTANGLE HOLE)",
        description: "CBH-217",
        price: 1600,
        model: "MHD",
        submodel: "SCORPIO S10",
        link: "/bumper"
    },
    {
        id: 164,
        title: "SCORPIO S10 (RECTANGLE HOLE) WITH GRILLE",
        description: "CBH-276",
        price: 1800,
        model: "MHD",
        submodel: "SCORPIO S10",
        link: "/bumper"
    },
    {
        id: 165,
        title: "SCORPIO S11 (ROUND HOLE)",
        description: "CBH-216",
        price: 1800,
        model: "MHD",
        submodel: "SCORPIO S11",
        link: "/bumper"
    },
    {
        id: 166,
        title: "SCORPIO S11 (ROUND HOLE) WITH GRILLE",
        description: "CBH-277",
        price: 2000,
        model: "MHD",
        submodel: "SCORPIO S11",
        link: "/bumper"
    },
    {
        id: 167,
        title: "SCORPIO CLASSIC",
        description: "CBH-148",
        price: 3200,
        model: "MHD",
        submodel: "SCORPIO CLASSIC",
        link: "/bumper"
    },
    {
        id: 168,
        title: "XYLO T-1 FRONT",
        description: "CBH-231",
        price: 2350,
        model: "MHD",
        submodel: "XYLO T-1",
        link: "/bumper"
    },
    {
        id: 169,
        title: "XYLO REAR",
        description: "CBH-231",
        price: 3250,
        model: "MHD",
        submodel: "XYLO",
        link: "/bumper"
    },
    {
        id: 170,
        title: "POLO T-1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_6.jpg",

        description: "CBH-032",
        price: 1400,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 171,
        title: "POLO T-1 FRONT WITH LIP GUARD/SPOILER",
        description: "CBH-247",
        price: 1950,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 172,
        title: "POLO T-1 FRONT WITH GRILL",
        description: "CBH-170",
        price: 1750,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 173,
        title: "POLO T-1 FRONT COMPLETE (OPEN)",
        description: "CBH-173",
        price: 1950,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 174,
        title: "POLO T-1 FRONT COMPLETE (CLOSE)",
        description: "CBH-174",
        price: 1950,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 175,
        title: "POLO T-1 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_7.jpg",

        description: "CBH-033",
        price: 2550,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 176,
        title: "POLO T-2 FRONT",
        description: "CBH-034",
        price: 2450,
        model: "VW",
        submodel: "POLO T-2",
        link: "/bumper"
    },
    {
        id: 177,
        title: "POLO T-2 FRONT WITH LIP GUARD/SPOILER",
        description: "CBH-243",
        price: 3100,
        model: "VW",
        submodel: "POLO T-2",
        link: "/bumper"
    },
    {
        id: 178,
        title: "POLO T-2 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_14.jpg",

        description: "CBH-035",
        price: 2900,
        model: "VW",
        submodel: "POLO T-2",
        link: "/bumper"
    },
    {
        id: 179,
        title: "POLO T-2 REAR WITH REFLECTOR",
        description: "CBH-244",
        price: 3450,
        model: "VW",
        submodel: "POLO T-2",
        link: "/bumper"
    },
    {
        id: 180,
        title: "POLO T-3 FRONT",
        description: "CBH-286",
        price: 3000,
        model: "VW",
        submodel: "POLO T-3",
        link: "/bumper"
    },
    {
        id: 181,
        title: "POLO T-3 FACELIFT KIT",
        description: "CBH-287",
        price: 10000,
        model: "VW",
        submodel: "POLO T-3",
        link: "/bumper"
    },
    {
        id: 182,
        title: "VENTO T-1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_5_9.jpg",

        description: "CBH-036",
        price: 1650,
        model: "VW",
        submodel: "VENTO T-1",
        link: "/bumper"
    },
    {
        id: 183,
        title: "VENTO T-1 FRONT WITH LIP GUARD/SPOILER",
        description: "CBH-245",
        price: 2350,
        model: "VW",
        submodel: "VENTO T-1",
        link: "/bumper"
    },
    {
        id: 184,
        title: "VENTO T-1 REAR",
        description: "CBH-079",
        price: 2700,
        model: "VW",
        submodel: "VENTO T-1",
        link: "/bumper"
    },
    {
        id: 185,
        title: "VENTO T2 FRONT",
        description: "CBH-102",
        price: 3050,
        model: "VW",
        submodel: "VENTO T-2",
        link: "/bumper"
    },
    {
        id: 186,
        title: "VENTO T-2 FRONT WITH LIP GUARD/SPOILER",
        description: "CBH-248",
        price: 4000,
        model: "VW",
        submodel: "VENTO T-2",
        link: "/bumper"
    },
    {
        id: 187,
        title: "VENTO T2 REAR",
        description: "CBH-119",
        price: 2950,
        model: "VW",
        submodel: "VENTO T-2",
        link: "/bumper"
    },
    {
        id: 188,
        title: "JETTA T2 FRONT",
        description: "CBH-155",
        price: 3600,
        model: "VW",
        submodel: "JETTA T2",
        link: "/bumper"
    },
    {
        id: 189,
        title: "JETTA T2 REAR",
        description: "CBH-156",
        price: 3850,
        model: "VW",
        submodel: "JETTA T2",
        link: "/bumper"
    },
    {
        id: 190,
        title: "LIP GUARD/SPOILER POLO T-1",
        description: "CBH-237",
        price: 800,
        model: "VW",
        submodel: "LIP GUARD/SPOILER",
        link: "/bumper"
    },
    {
        id: 191,
        title: "LIP GUARD/SPOILER POLO T-2",
        description: "CBH-238",
        price: 1000,
        model: "VW",
        submodel: "LIP GUARD/SPOILER",
        link: "/bumper"
    },
    {
        id: 192,
        title: "LIP GUARD/SPOILER VENTO T-1",
        description: "CBH-240",
        price: 1000,
        model: "VW",
        submodel: "LIP GUARD/SPOILER",
        link: "/bumper"
    },
    {
        id: 193,
        title: "LIP GUARD/SPOILER VENTO T-2",
        description: "CBH-241",
        price: 1320,
        model: "VW",
        submodel: "LIP GUARD/SPOILER",
        link: "/bumper"
    },
    {
        id: 194,
        title: "REAR BUMPER REFLECTOR POLO T-2 (SET)",
        description: "CBH-239",
        price: 450,
        model: "VW",
        submodel: "POLO T-2",
        link: "/bumper"
    },
    {
        id: 195,
        title: "ENGINE GUARD(1.4) POLO T-1",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_47.jpg",
        description: "CBH-201",
        price: 450,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 196,
        title: "ENGINE GUARD (1.6) POLO T-1",
        description: "CBH-202",
        price: 700,
        model: "VW",
        submodel: "POLO T-1",
        link: "/bumper"
    },
    {
        id: 197,
        title: "CRUZE FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_1.jpg",

        description: "CBH-043",
        price: 2650,
        model: "GM",
        submodel: "CRUZE",
        link: "/bumper"
    },
    {
        id: 198,
        title: "CRUZE REAR",
        description: "CBH-044",
        price: 3100,
        model: "GM",
        submodel: "CRUZE",
        link: "/bumper"
    },
    {
        id: 199,
        title: "CRUZE T-2 FRONT",
        description: "CBH-104",
        price: 4200,
        model: "GM",
        submodel: "CRUZE T-2",
        link: "/bumper"
    },
    {
        id: 200,
        title: "AVEO FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6_13.jpg",

        description: "CBH-054",
        price: 2700,
        model: "GM",
        submodel: "AVEO",
        link: "/bumper"
    },
    {
        id: 201,
        title: "AVEO REAR",
        description: "CBH-081",
        price: 3000,
        model: "GM",
        submodel: "AVEO",
        link: "/bumper"
    },
    {
        id: 202,
        title: "BEAT FRONT",
        description: "CBH-139",
        price: 2800,
        model: "GM",
        submodel: "BEAT",
        link: "/bumper"
    },
    {
        id: 203,
        title: "UVA FRONT",
        description: "CBH-145",
        price: 2800,
        model: "GM",
        submodel: "UVA",
        link: "/bumper"
    },
    {
        id: 204,
        title: "SPARK FRONT",
        description: "CBH-171",
        price: 1600,
        model: "GM",
        submodel: "SPARK",
        link: "/bumper"
    },
    {
        id: 205,
        title: "SPARK REAR",
        description: "CBH-172",
        price: 1800,
        model: "GM",
        submodel: "SPARK",
        link: "/bumper"
    },
    {
        id: 206,
        title: "SAIL FRONT WITH UPPER GRILL",
        description: "CBH-250",
        price: 3300,
        model: "GM",
        submodel: "SAIL WITH UPPER GRILL",
        link: "/bumper"
    },
    {
        id: 207,
        title: "ENJOY FRONT",
        description: "CBH-235",
        price: 1800,
        model: "GM",
        submodel: "ENJOY",
        link: "/bumper"
    },
    {
        id: 208,
        title: "ENJOY REAR",
        description: "CBH-249",
        price: 2000,
        model: "GM",
        submodel: "ENJOY",
        link: "/bumper"
    },
    {
        id: 209,
        title: "OPTRA T-2 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6_14.jpg",

        description: "CBH-082",
        price: 3000,
        model: "GM",
        submodel: "OPTRA T-2",
        link: "/bumper"
    },
    {
        id: 210,
        title: "OPTRA MAGNUM FRONT",
        description: "CBH-138",
        price: 3000,
        model: "GM",
        submodel: "OPTRA MAGNUM",
        link: "/bumper"
    },
    {
        id: 211,
        title: "OPTRA T1/T2 REAR",
        description: "CBH-083",
        price: 3250,
        model: "GM",
        submodel: "OPTRA T1/T2",
        link: "/bumper"
    },
    {
        id: 212,
        title: "FABIA T-1 FRONT",
        description: "CBH-047",
        price: 3400,
        model: "SKD",
        submodel: "FABIA T-1",
        link: "/bumper"
    },
    {
        id: 213,
        title: "FABIA T-1 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_5_16.jpg",

        description: "CBH-120",
        price: 3500,
        model: "SKD",
        submodel: "FABIA T-1",
        link: "/bumper"
    },
    {
        id: 214,
        title: "FABIA T-2 FRONT",
        description: "CBH-048",
        price: 2100,
        model: "SKD",
        submodel: "FABIA T-2",
        link: "/bumper"
    },
    {
        id: 215,
        title: "FABIA T-2 REAR",
        description: "CBH-084",
        price: 3850,
        model: "SKD",
        submodel: "FABIA T-2",
        link: "/bumper"
    },
    {
        id: 216,
        title: "RAPID T-1 FRONT",
        description: "CBH-097",
        price: 1650,
        model: "SKD",
        submodel: "RAPID T-1",
        link: "/bumper"
    },
    {
        id: 217,
        title: "LAURA T2 FRONT",
        description: "CBH-160",
        price: 4500,
        model: "SKD",
        submodel: "LAURA T2",
        link: "/bumper"
    },
    {
        id: 218,
        title: "LAURA T2 REAR",
        description: "CBH-161",
        price: 4500,
        model: "SKD",
        submodel: "LAURA T2",
        link: "/bumper"
    },
    {
        id: 219,
        title: "OCTAVIA T2 FRONT",
        description: "CBH-158",
        price: 4500,
        model: "SKD",
        submodel: "OCTAVIA T2",
        link: "/bumper"
    },
    {
        id: 220,
        title: "OCTAVIA T2 REAR",
        description: "CBH-159",
        price: 4650,
        model: "SKD",
        submodel: "OCTAVIA T2",
        link: "/bumper"
    },
    {
        id: 221,
        title: "OCTAVIA T3 FRONT",
        description: "CBH-157",
        price: 6600,
        model: "SKD",
        submodel: "OCTAVIA T3",
        link: "/bumper"
    },
    {
        id: 222,
        title: "OCTAVIA T3 REAR",
        description: "CBH-192",
        price: 8000,
        model: "SKD",
        submodel: "OCTAVIA T3",
        link: "/bumper"
    },
    {
        id: 223,
        title: "SUPERB T2 FRONT 2012",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6_16.jpg",

        description: "CBH-085",
        price: 4350,
        model: "SKD",
        submodel: "SUPERB T2",
        link: "/bumper"
    },
    {
        id: 224,
        title: "SUPERB T-2 REAR 2012",
        description: "CBH-086",
        price: 5200,
        model: "SKD",
        submodel: "SUPERB T-2",
        link: "/bumper"
    },
    {
        id: 225,
        title: "SUNNY T-1 FRONT",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_10.jpg",

        description: "CBH-051",
        price: 2650,
        model: "NSN",
        submodel: "SUNNY T-1",
        link: "/bumper"
    },
    {
        id: 226,
        title: "SUNNY T-1 REAR",
        description: "CBH-111",
        price: 2700,
        model: "NSN",
        submodel: "SUNNY T-1",
        link: "/bumper"
    },
    {
        id: 227,
        title: "SUNNY T2 FRONT 2015",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6_18.jpg",

        description: "CBH-087",
        price: 3200,
        model: "NSN",
        submodel: "SUNNY T2",
        link: "/bumper"
    },
    {
        id: 228,
        title: "SUNNY T-2 REAR 2015",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6_19.jpg",

        description: "CBH-121",
        price: 2600,
        model: "NSN",
        submodel: "SUNNY T2",
        link: "/bumper"
    },
    {
        id: 229,
        title: "MICRA FRONT",
        description: "CBH-052",
        price: 2600,
        model: "NSN",
        submodel: "MICRA",
        link: "/bumper"
    },
    {
        id: 230,
        title: "MICRA REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_5_19.jpg",

        description: "CBH-112",
        price: 2650,
        model: "NSN",
        submodel: "MICRA",
        link: "/bumper"
    },
    {
        id: 231,
        title: "TERRANO FRONT",
        description: "CBH-154",
        price: 5100,
        model: "NSN",
        submodel: "TERRANO",
        link: "/bumper"
    },
    {
        id: 232,
        title: "DUSTER FRONT W/O HOLE",
        description: "CBH-088",
        price: 2950,
        model: "RNT",
        submodel: "DUSTER",
        link: "/bumper"
    },
    {
        id: 233,
        title: "DUSTER FRONT WITH HOLE",
        description: "CBH-167",
        price: 2950,
        model: "RNT",
        submodel: "DUSTER",
        link: "/bumper"
    },
    {
        id: 234,
        title: "DUSTER FRONT LOWER PIECE (SILVER)",
        description: "CBH-196",
        price: 1500,
        model: "RNT",
        submodel: "DUSTER",
        link: "/bumper"
    },
    {
        id: 235,
        title: "DUSTER REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6_12.jpg",

        description: "CBH-089",
        price: 3500,
        model: "RNT",
        submodel: "DUSTER",
        link: "/bumper"
    },
    {
        id: 236,
        title: "DUSTER REAR SPOILER",
        description: "CBH-090",
        price: 1875,
        model: "RNT",
        submodel: "DUSTER",
        link: "/bumper"
    },
    {
        id: 237,
        title: "ECOSPORT T1 FRONT",
        description: "CBH-091",
        price: 2100,
        model: "FRD",
        submodel: "ECOSPORT T1",
        link: "/bumper"
    },
    {
        id: 238,
        title: "ECOSPORT T1 FRONT SPOILER UPPER/LOWER",
        description: "CBH-137",
        price: 2600,
        model: "FRD",
        submodel: "ECOSPORT T1",
        link: "/bumper"
    },
    {
        id: 239,
        title: "ECOSPORT T1 FRONT SPOILER UPPER",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_6_9.jpg",

        description: "CBH-098",
        price: 1100,
        model: "FRD",
        submodel: "ECOSPORT T1",
        link: "/bumper"
    },
    {
        id: 240,
        title: "ECOSPORT T1 FRONT SPOILER LOWER",
        description: "CBH-101",
        price: 2200,
        model: "FRD",
        submodel: "ECOSPORT T1",
        link: "/bumper"
    },
    {
        id: 241,
        title: "ECOSPORT T2 FRONT",
        description: "CBH-270",
        price: 4100,
        model: "FRD",
        submodel: "ECOSPORT T2",
        link: "/bumper"
    },
    {
        id: 242,
        title: "ECOSPORT T2 FRONT SPOILER (SILVER)",
        description: "CBH-267",
        price: 2000,
        model: "FRD",
        submodel: "ECOSPORT T2",
        link: "/bumper"
    },
    {
        id: 243,
        title: "ECOSPORT T2 FRONT BUMPER LOWER STRIP",
        description: "CBH-268/271",
        price: 600,
        model: "FRD",
        submodel: "ECOSPORT T2",
        link: "/bumper"
    },
    {
        id: 244,
        title: "ECOSPORT T2 FRONT SIDE COVER RH/LH",
        description: "CBH-269",
        price: 800,
        model: "FRD",
        submodel: "ECOSPORT T2",
        link: "/bumper"
    },
    {
        id: 245,
        title: "ECOSPORT T1/2 REAR",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_5_10.jpg",

        description: "CBH-092",
        price: 3500,
        model: "FRD",
        submodel: "ECOSPORT T1/2",
        link: "/bumper"
    },
    {
        id: 246,
        title: "AUDI A4 FRONT (2009)",
        description: "CBH-056",
        price: 6110,
        model: "AUDI",
        submodel: "AUDI A4 (2009)",
        link: "/bumper"
    },
    {
        id: 247,
        title: "AUDI A4 REAR (2009)",
        description: "CBH-057",
        price: 8060,
        model: "AUDI",
        submodel: "AUDI A4 (2009)",
        link: "/bumper"
    },
    {
        id: 248,
        title: "AUDI A4 FRONT (2013) S.LINE",
        description: "CBH-177",
        price: 12610,
        model: "AUDI",
        submodel: "AUDI A4 (2013)",
        link: "/bumper"
    },
    {
        id: 249,
        title: "AUDI A4 FRONT (2013)",
        description: "CBH-179",
        price: 8060,
        model: "AUDI",
        submodel: "AUDI A4 (2013)",
        link: "/bumper"
    },
    {
        id: 250,
        title: "AUDI A6 FRONT (2009)",
        description: "CBH-058",
        price: 8060,
        model: "AUDI",
        submodel: "AUDI A6 (2009)",
        link: "/bumper"
    },
    {
        id: 251,
        title: "AUDI A6 FRONT (2013)",
        description: "CBH-059",
        price: 8060,
        model: "AUDI",
        submodel: "AUDI A6 (2013)",
        link: "/bumper"
    },
    {
        id: 252,
        title: "AUDI Q5 FRONT (2012)",
        description: "CBH-178",
        price: 8060,
        model: "AUDI",
        submodel: "AUDI Q5 (2012)",
        link: "/bumper"
    },
    {
        id: 253,
        title: "AUDI Q7 FRONT",
        description: "CBH-103",
        price: 18460,
        model: "AUDI",
        submodel: "AUDI Q7",
        link: "/bumper"
    },
    {
        id: 254,
        title: "BMW 3 SERIES FRONT (2008)",
        description: "CBH-060",
        price: 11960,
        model: "BMW",
        submodel: "BMW 3 SERIES (2008)",
        link: "/bumper"
    },
    {
        id: 255,
        title: "BMW 3 SERIES REAR (2008)",
        description: "CBH-061",
        price: 14560,
        model: "BMW",
        submodel: "BMW 3 SERIES (2008)",
        link: "/bumper"
    },
    {
        id: 256,
        title: "BMW 5 SERIES FRONT (2010)",
        description: "CBH-093",
        price: 6760,
        model: "BMW",
        submodel: "BMW 5 SERIES (2010)",
        link: "/bumper"
    },
    {
        id: 257,
        title: "BMW 5 SERIES REAR (2010)",
        description: "CBH-094",
        price: 13260,
        model: "BMW",
        submodel: "BMW 5 SERIES (2010)",
        link: "/bumper"
    },
    {
        id: 258,
        title: "BMW X1 FRONT",
        description: "CBH-095",
        price: 9360,
        model: "BMW",
        submodel: "BMW X1",
        link: "/bumper"
    },
    {
        id: 259,
        title: "BMW X1 FRONT SPOILER",
        description: "CBH-096",
        price: 4900,
        model: "BMW",
        submodel: "BMW X1",
        link: "/bumper"
    },
    {
        id: 260,
        title: "TIE MEMPER I10",
        description: "CBH-063",
        price: 1650,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER I10",
        link: "/bumper"
    },
    {
        id: 261,
        title: "TIE MEMPER I10 GRAND",
        description: "CBH-064",
        price: 2200,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER I10",
        link: "/bumper"
    },
    {
        id: 262,
        title: "TIE MEMBER I20 ELITE",
        description: "CBH-288",
        price: 2900,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER I20",
        link: "/bumper"
    },
    {
        id: 263,
        title: "TIE MEMBER CRETA",
        description: "CBH-251",
        price: 5000,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER CRETA",
        link: "/bumper"
    },
    {
        id: 264,
        title: "TIE MEMBER POLO",
        description: "CBH-062",
        price: 1650,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER POLO",
        link: "/bumper"
    },
    {
        id: 265,
        title: "TIE MEMBER VERNA FLUIDIC T1",
        description: "CBH-165",
        price: 3350,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER VERNA FLUIDIC T1",
        link: "/bumper"
    },
    {
        id: 266,
        title: "TIE MEMBER VERNA FLUIDIC T3",
        description: "CBH-263",
        price: 3500,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER VERNA FLUIDIC T3",
        link: "/bumper"
    },
    {
        id: 267,
        title: "TIE MEMBER FORTUNER",
        description: "CBH-302",
        price: 4000,
        model: "T.MEMBE R",
        submodel: "TIE MEMBER FORTUNER",
        link: "/bumper"
    }

];

export const FENDERLININGProduct = [
    {
        id: 1,
        title: "SWIFT T1/T2/DZIRE T1 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_3_11.jpg",

        description: "CBH-FL45/46",
        price: 250,
        model: "MSZ",
        submodel: "SWIFT T1/T2/DZIRE T1",
        link: "/fenderline"
    },
    {
        id: 2,
        title: "SWIFT T3/T4/DZIRE T2/T3 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_3_11.jpg",

        description: "CBH-FL41/42",
        price: 250,
        model: "MSZ",
        submodel: "SWIFT T3/T4/DZIRE T2/T3",
        link: "/fenderline"
    },
    {
        id: 3,
        title: "SWIFT T3/T4/DZIRE T2/T3 FRONT RH/LH OEM",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_2_12.jpg",

        description: "CBH-FL103/104",
        price: 325,
        model: "MSZ",
        submodel: "SWIFT T3/T4/DZIRE T2/T3",
        link: "/fenderline"
    },
    {
        id: 4,
        title: "SWIFT T5/T6/DZIRE T4/T5 RH/LH",
        description: "CBH-FL85/86",
        price: 300,
        model: "MSZ",
        submodel: "SWIFT T5/T6/DZIRE T4/T5",
        link: "/fenderline"
    },
    {
        id: 5,
        title: "SWIFT T5/T6/DZIRE T4/T5 RH/LH OEM QLTY",
        description: "CBH-FL101/102",
        price: 400,
        model: "MSZ",
        submodel: "SWIFT T5/T6/DZIRE T4/T5",
        link: "/fenderline"
    },
    {
        id: 6,
        title: "K-SERIES FRONT RH/LH",
        description: "CBH-FL53/54",
        price: 200,
        model: "MSZ",
        submodel: "K-SERIES",
        link: "/fenderline"
    },
    {
        id: 7,
        title: "WAGONR T6 FRONT RH/LH",
        description: "CBH-FL119/120",
        price: 250,
        model: "MSZ",
        submodel: "WAGONR T6",
        link: "/fenderline"
    },
    {
        id: 8,
        title: "CAR 800 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5_6.jpg",

        description: "CBH-FL61/62",
        price: 125,
        model: "MSZ",
        submodel: "CAR 800",
        link: "/fenderline"
    },
    {
        id: 9,
        title: "ALTO FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_48.jpg",

        description: "CBH-FL59/60",
        price: 150,
        model: "MSZ",
        submodel: "ALTO",
        link: "/fenderline"
    },
    {
        id: 10,
        title: "ZEN FRONT RH/LH",
        description: "CBH-FL57/58",
        price: 150,
        model: "MSZ",
        submodel: "ZEN",
        link: "/fenderline"
    },
    {
        id: 11,
        title: "ALTO 800 T1/T2/T3 FRONT RH/LH",
        description: "CBH-FL71/72",
        price: 200,
        model: "MSZ",
        submodel: "ALTO 800 T1/T2/T3",
        link: "/fenderline"
    },
    {
        id: 12,
        title: "ALTO K-10 T2 FRONT RH/LH",
        description: "CBH-FL111/112",
        price: 200,
        model: "MSZ",
        submodel: "ALTO K-10 T2",
        link: "/fenderline"
    },
    {
        id: 13,
        title: "BALENO/GLANZA RH/LH",
        description: "CBH-FL99/100",
        price: 325,
        model: "MSZ",
        submodel: "BALENO/GLANZA",
        link: "/fenderline"
    },
    {
        id: 14,
        title: "BREZZA FRONT RH/LH",
        description: "CBH-FL73/74",
        price: 500,
        model: "MSZ",
        submodel: "BREZZA",
        link: "/fenderline"
    },
    {
        id: 15,
        title: "ERTIGA FRONT RH/LH",
        description: "CBH-FL73/74",
        price: 300,
        model: "MSZ",
        submodel: "ERTIGA",
        link: "/fenderline"
    },
    {
        id: 16,
        title: "i10 GRAND T1/XCENT FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_4_13.jpg",

        description: "CBH-FL01/02",
        price: 300,
        model: "HYD",
        submodel: "i10 GRAND T1/XCENT",
        link: "/fenderline"
    },
    {
        id: 17,
        title: "i10 GRAND T2 FRONT RH/LH",
        description: "CBH-FL77/78",
        price: 550,
        model: "HYD",
        submodel: "i10 GRAND T2",
        link: "/fenderline"
    },
    {
        id: 18,
        title: "i10 T1/2 FRONT RH/LH",
        description: "CBH-FL51/52",
        price: 200,
        model: "HYD",
        submodel: "i10 T1/2",
        link: "/fenderline"
    },
    {
        id: 19,
        title: "I20 FRONT RH/LH",
        description: "CBH-FL97/98",
        price: 325,
        model: "HYD",
        submodel: "I20",
        link: "/fenderline"
    },
    {
        id: 20,
        title: "I20 ELITE FRONT RH/LH",
        description: "CBH-FL87/88",
        price: 300,
        model: "HYD",
        submodel: "I20",
        link: "/fenderline"
    },
    {
        id: 21,
        title: "EON FRONT RH/LH",
        description: "CBH-FL95/96",
        price: 225,
        model: "HYD",
        submodel: "EON",
        link: "/fenderline"
    },
    {
        id: 22,
        title: "VERNA T1 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/0022.jpg",

        description: "CBH-FL05/06",
        price: 480,
        model: "HYD",
        submodel: "VERNA T1",
        link: "/fenderline"
    },
    {
        id: 23,
        title: "VERNA FLUIDIC T1 FRONT RH/LH",
        description: "CBH-FL37/38",
        price: 500,
        model: "HYD",
        submodel: "VERNA FLUIDIC T1",
        link: "/fenderline"
    },
    {
        id: 24,
        title: "VERNA FLUIDIC T3 FRONT RH/LH",
        description: "CBH-FL69/70",
        price: 550,
        model: "HYD",
        submodel: "VERNA FLUIDIC T3",
        link: "/fenderline"
    },
    {
        id: 25,
        title: "ELANTRA FLUIDIC FRONT RH/LH",
        description: "CBH-FL105/106",
        price: 500,
        model: "HYD",
        submodel: "ELANTRA FLUIDIC",
        link: "/fenderline"
    },
    {
        id: 26,
        title: "CRETA T1/T2 FRONT RH/LH",
        description: "CBH-FL79/80",
        price: 650,
        model: "HYD",
        submodel: "CRETA T1/T2",
        link: "/fenderline"
    },
    {
        id: 27,
        title: "INNOVA FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_copy_2.jpg",

        description: "CBH-FL07/08",
        price: 300,
        model: "TYT",
        submodel: "INNOVA",
        link: "/fenderline"
    },
    {
        id: 28,
        title: "INNOVA CRYSTA FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_11.jpg",

        description: "CBH-FL35/36",
        price: 500,
        model: "TYT",
        submodel: "INNOVA",
        link: "/fenderline"
    },
    {
        id: 29,
        title: "ETIOS FRONT RH/LH",
        description: "CBH-FL43/44",
        price: 300,
        model: "TYT",
        submodel: "ETIOS",
        link: "/fenderline"
    },
    {
        id: 30,
        title: "ALTIS T1/T2 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_8.jpg",

        description: "CBH-FL11/12",
        price: 600,
        model: "TYT",
        submodel: "ALTIS T1/T2",
        link: "/fenderline"
    },
    {
        id: 31,
        title: "ALTIS T3 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_3_11.jpg",

        description: "CBH-FL13/14",
        price: 700,
        model: "TYT",
        submodel: "ALTIS T3",
        link: "/fenderline"
    },
    {
        id: 32,
        title: "FORTUNER T1/T2 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_4_10.jpg",

        description: "CBH-FL09/10",
        price: 700,
        model: "TYT",
        submodel: "FORTUNER T1/T2",
        link: "/fenderline"
    },
    {
        id: 33,
        title: "FORTUNER T3 FRONT RH/LH",
        description: "CBH-FL33/34",
        price: 750,
        model: "TYT",
        submodel: "FORTUNER T3",
        link: "/fenderline"
    },
    {
        id: 34,
        title: "CITY T3/T4 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5_7.jpg",

        description: "CBH-FL15/16",
        price: 550,
        model: "HND",
        submodel: "CITY T3/T4",
        link: "/fenderline"
    },
    {
        id: 35,
        title: "CITY T5/T6 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5_9.jpg",

        description: "CBH-FL19/20",
        price: 500,
        model: "HND",
        submodel: "CITY T5/T6",
        link: "/fenderline"
    },
    {
        id: 36,
        title: "CITY T5/T6 REAR RH/LH",
        description: "CBH-FL21/22",
        price: 350,
        model: "HND",
        submodel: "CITY T5/T6",
        link: "/fenderline"
    },
    {
        id: 37,
        title: "CITY T7/T8 FRONT RH/LH",
        description: "CBH-FL55/56",
        price: 500,
        model: "HND",
        submodel: "CITY T7/T8",
        link: "/fenderline"
    },
    {
        id: 38,
        title: "CIVIC FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5_10.jpg",

        description: "CBH-FL23/24",
        price: 700,
        model: "HND",
        submodel: "CIVIC",
        link: "/fenderline"
    },
    {
        id: 39,
        title: "AMAZE T1/T2 FRONT RH/LH",
        description: "CBH-FL63/64",
        price: 400,
        model: "HND",
        submodel: "AMAZE T1/T2",
        link: "/fenderline"
    },
    {
        id: 40,
        title: "WRV FRONT RH/LH",
        description: "CBH-FL67/68",
        price: 800,
        model: "HND",
        submodel: "WRV",
        link: "/fenderline"
    },
    {
        id: 41,
        title: "JAZZ T1/T2 FRONT RH/LH",
        description: "CBH-FL75/76",
        price: 750,
        model: "HND",
        submodel: "JAZZ T1/T2",
        link: "/fenderline"
    },
    {
        id: 42,
        title: "JAZZ T3 FRONT RH/LH",
        description: "CBH-FL65/66",
        price: 750,
        model: "HND",
        submodel: "JAZZ T3",
        link: "/fenderline"
    },
    {
        id: 43,
        title: "TIAGO/TIGOR FRONT RH/LH",
        description: "CBH-FL81/82",
        price: 550,
        model: "TAT",
        submodel: "TIAGO/TIGOR",
        link: "/fenderline"
    },
    {
        id: 44,
        title: "TIAGO/TIGOR REAR RH/LH",
        description: "CBH-FL83/84",
        price: 250,
        model: "TAT",
        submodel: "TIAGO/TIGOR",
        link: "/fenderline"
    },
    {
        id: 45,
        title: "NEXON FRONT RH/LH",
        description: "CBH-FL117/118",
        price: 500,
        model: "TAT",
        submodel: "NEXON",
        link: "/fenderline"
    },
    {
        id: 46, // Continuing from the previous ID
        title: "SCORPIO S-SERIES FRONT RH/LH",
        description: "CBH-FL115/116",
        price: 200,
        model: "MHD",
        submodel: "SCORPIO S-SERIES",
        link: "/fenderline"
    },
    {
        id: 47, // Continuing from the previous ID
        title: "DUSTER FRONT RH/LH",
        description: "CBH-FL47/48",
        price: 700,
        model: "RNT",
        submodel: "Duster",
        link: "/fenderline"
    },
    {
        id: 48,
        title: "KWID FRONT RH/LH",
        description: "CBH-FL109/110",
        price: 350,
        model: "RNT",
        submodel: "KWID",
        link: "/fenderline"
    },
    {
        id: 49, // Starting with the next available ID
        title: "MICRA FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5_13.jpg",

        description: "CBH-FL47/48",
        price: 700,
        model: "NSN",
        submodel: "MICRA",
        link: "/fenderline"
    },
    {
        id: 50,
        title: "SUNNY T1/T2 FRONT RH/LH",
        description: "CBH-FL109/110",
        price: 350,
        model: "NSN",
        submodel: "SUNNY T1/T2",
        link: "/fenderline"
    },
    {
        id: 51, // Starting with the next available ID
        title: "POLO/VENTO/RAPID/FABIA FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5_11.jpg",

        description: "CBH-FL25/26",
        price: 650,
        model: "VW",
        submodel: "POLO/VENTO/RAPID/FABIA",
        link: "/fenderline"
    },
    {
        id: 52,
        title: "POLO/VENTO/RAPID/FABIA REAR RH/LH",
        description: "CBH-FL27/28",
        price: 850,
        model: "VW",
        submodel: "POLO/VENTO/RAPID/FABIA",
        link: "/fenderline"
    },
    {
        id: 53, // Starting with the next available ID
        title: "CRUZE T1/T2 FRONT RH/LH",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_5_12.jpg",

        description: "CBH-FL31/32",
        price: 650,
        model: "GM",
        submodel: "CRUZE T1/T2",
        link: "/fenderline"
    },
    {
        id: 54,
        title: "AVEO FRONT RH/LH",
        description: "CBH-FL39/40",
        price: 650,
        model: "GM",
        submodel: "AVEO",
        link: "/fenderline"
    },
    {
        id: 55,
        title: "ECOSPORT FRONT RH/LH",
        description: "CBH-FL39/40",
        price: 750,
        model: "FORD",
        submodel: "ECOSPORT  RH/LH",
        link: "/fenderline"
    }


];


export const FRONTGRILLSANDBUMPERGRILProduct = [
    {
        id: 1,
        title: "BUMPER GRILLE SWIFT T2/DZIRE T1",
        description: "CBH-BG015",
        price: 150,
        model: "MSZ",
        submodel: "BUMPER GRILLE SWIFT T2/DZIRE T1",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 2,
        title: "UPPER GRILLE SWIFT T2/DZIRE T1",
        description: "CBH-BG060",
        price: 250,
        model: "MSZ",
        submodel: "UPPER GRILLE SWIFT T2/DZIRE T1",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 3,
        title: "BUMPER GRILLE SWIFT T3",
        description: "CBH-BG036",
        price: 150,
        model: "MSZ",
        submodel: "BUMPER GRILLE SWIFT T3",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 4,
        title: "BUMPER GRILLE SWIFT T4",
        description: "CBH-BG016",
        price: 150,
        model: "MSZ",
        submodel: "BUMPER GRILLE SWIFT T4",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 5,
        title: "BUMPER GRILLE DZIRE T2",
        description: "CBH-BG008",
        price: 150,
        model: "MSZ",
        submodel: "BUMPER GRILLE DZIRE T2",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 6,
        title: "BUMPER GRILLE DZIRE T3",
        description: "CBH-BG039",
        price: 150,
        model: "MSZ",
        submodel: "BUMPER GRILLE DZIRE T3",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 7,
        title: "BUMPER GRILLE BREZZA",
        description: "CBH-BG001",
        price: 600,
        model: "MSZ",
        submodel: "BUMPER GRILLE BREZZA",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 8,
        title: "BUMPER GRILLE ALTO 800 T1",
        description: "CBH-BG002",
        price: 525,
        model: "MSZ",
        submodel: "BUMPER GRILLE ALTO 800 T1",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 9,
        title: "BUMPER GRILLE ALTO 800 T2",
        description: "CBH-BG053",
        price: 900,
        model: "MSZ",
        submodel: "BUMPER GRILLE ALTO 800 T2",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 10,
        title: "BUMPER GRILLE ALTO 800 T3",
        description: "CBH-BG110",
        price: 900,
        model: "MSZ",
        submodel: "BUMPER GRILLE ALTO 800 T3",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 11,
        title: "BUMPER GRILLE WAGONR T5",
        description: "CBH-BG111",
        price: 650,
        model: "MSZ",
        submodel: "BUMPER GRILLE WAGONR T5",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 12,
        title: "FRONT GRILLE CELERIO T1",
        description: "CBH-BG097",
        price: 1050,
        model: "MSZ",
        submodel: "FRONT GRILLE CELERIO T1",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 13,
        title: "FRONT GRILLE DZIRE T2",
        description: "CBH-BG079",
        price: 350,
        model: "MSZ",
        submodel: "FRONT GRILLE DZIRE T2",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 14,
        title: "FRONT GRILLE DZIRE T3 - CHROME",
        description: "CBH-BG055",
        price: 750,
        model: "MSZ",
        submodel: "FRONT GRILLE DZIRE T3 - CHROME",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 15,
        title: "FRONT GRILLE DZIRE T3 - BLACK",
        description: "CBH-BG057",
        price: 650,
        model: "MSZ",
        submodel: "FRONT GRILLE DZIRE T3 - BLACK",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 16,
        title: "FRONT GRILLE DZIRE T4 - BASE",
        description: "CBH-BG080",
        price: 850,
        model: "MSZ",
        submodel: "FRONT GRILLE DZIRE T4 - BASE",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 17,
        title: "FRONT GRILLE DZIRE T4 - CHROME PART",
        description: "CBH-BG081",
        price: 1875,
        model: "MSZ",
        submodel: "FRONT GRILLE DZIRE T4 - CHROME PART",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 18,
        title: "FRONT GRILLE DZIRE T5 - CHROME",
        description: "CBH-BG114",
        price: 1800,
        model: "MSZ",
        submodel: "FRONT GRILLE DZIRE T5 - CHROME",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 19,
        title: "FRONT GRILLE ERTIGA T2",
        description: "CBH-BG082",
        price: 2400,
        model: "MSZ",
        submodel: "FRONT GRILLE ERTIGA T2",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 20,
        title: "FRONT GRILLE ERTIGA T4",
        description: "CBH-BG083",
        price: 3600,
        model: "MSZ",
        submodel: "FRONT GRILLE ERTIGA T4",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 21,
        title: "FRONT GRILLE K10 T2",
        description: "CBH-BG087",
        price: 800,
        model: "MSZ",
        submodel: "FRONT GRILLE K10 T2",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 22,
        title: "FRONT GRILLE CIAZ T2",
        description: "CBH-BG084",
        price: 3500,
        model: "MSZ",
        submodel: "FRONT GRILLE CIAZ T2",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 23,
        title: "FRONT GRILLE WAGONR T6",
        description: "CBH-BG088",
        price: 1000,
        model: "MSZ",
        submodel: "FRONT GRILLE WAGONR T6",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 24,
        title: "FRONT GRILLE ZEN ESTILO",
        description: "CBH-BG093",
        price: 1000,
        model: "MSZ",
        submodel: "FRONT GRILLE ZEN ESTILO",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 25,
        title: "FRONT GRILLE XL 6",
        description: "CBH-BG100",
        price: 2850,
        model: "MSZ",
        submodel: "FRONT GRILLE XL 6",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 26,
        title: "FRONT GRILLE S-CROSS",
        description: "CBH-BG098",
        price: 3100,
        model: "MSZ",
        submodel: "FRONT GRILLE S-CROSS",
        link: "/frontgrillsandbumpergrills"
    },
    {
        id: 27,
        title: "BUMPER GRILLE VERNA FLUIDIC T3 - CHROME",
        description: "CBH-BG021",
        price: 3200,
        model: "HYD",
        submodel: "BUMPER GRILLE VERNA FLUIDIC T3 - CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 28,
        title: "BUMPER GRILLE VERNA FLUIDIC T3 LOWER",
        description: "CBH-BG022",
        price: 550,
        model: "HYD",
        submodel: "BUMPER GRILLE VERNA FLUIDIC T3 LOWER",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 29,
        title: "BUMPER GRILLE I10 T2",
        description: "CBH-BG102",
        price: 500,
        model: "HYD",
        submodel: "BUMPER GRILLE I10 T2",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 30,
        title: "FRONT GRILLE I10 T2 - CHROME",
        description: "CBH-BG103",
        price: 800,
        model: "HYD",
        submodel: "FRONT GRILLE I10 T2 - CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 31,
        title: "BUMPER GRILLE I10 GRAND T1 - CHROME",
        description: "CBH-BG031",
        price: 2500,
        model: "HYD",
        submodel: "BUMPER GRILLE I10 GRAND T1 - CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 32,
        title: "BUMPER GRILLE I10 GRAND T2",
        description: "CBH-BG045",
        price: 1400,
        model: "HYD",
        submodel: "BUMPER GRILLE I10 GRAND T2",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 33,
        title: "FRONT GRILLE I10 GRAND T2 - CHROME",
        description: "CBH-BG104",
        price: 1125,
        model: "HYD",
        submodel: "FRONT GRILLE I10 GRAND T2 - CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 34,
        title: "BUMPER GRILLE XCENT T2 - CHROME",
        description: "CBH-BG105",
        price: 3100,
        model: "HYD",
        submodel: "BUMPER GRILLE XCENT T2 - CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 35,
        title: "BUMPER GRILLE VERNA T1",
        description: "CBH-BG024",
        price: 500,
        model: "HYD",
        submodel: "BUMPER GRILLE VERNA T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 36,
        title: "BUMPER GRILLE VERNA FLUIDIC T1",
        description: "CBH-BG020",
        price: 500,
        model: "HYD",
        submodel: "BUMPER GRILLE VERNA FLUIDIC T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 37,
        title: "FRONT GRILLE VERNA FLUIDIC T1",
        description: "CBH-BG071",
        price: 1125,
        model: "HYD",
        submodel: "FRONT GRILLE VERNA FLUIDIC T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 38,
        title: "BUMPER GRILLE ELANTRA FLUIDIC T1",
        description: "CBH-BG106",
        price: 1125,
        model: "HYD",
        submodel: "BUMPER GRILLE ELANTRA FLUIDIC T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 39,
        title: "FRONT GRILLE ELANTRA FLUIDIC T1",
        description: "CBH-BG107",
        price: 1500,
        model: "HYD",
        submodel: "FRONT GRILLE ELANTRA FLUIDIC T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 40,
        title: "FRONT GRILLE CRETA SILVER",
        description: "CBH-BG051",
        price: 2500,
        model: "HYD",
        submodel: "FRONT GRILLE CRETA SILVER",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 41,
        title: "FRONT GRILLE CRETA CHROME",
        description: "CBH-BG049",
        price: 2750,
        model: "HYD",
        submodel: "FRONT GRILLE CRETA CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 42,
        title: "BUMPER GRILLE ETIOS T1",
        description: "CBH-BG050",
        price: 200,
        model: "TYT",
        submodel: "BUMPER GRILLE ETIOS T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 43,
        title: "FRONT GRILLE INNOVA T4 - CHROME",
        description: "CBH-BG030",
        price: 1800,
        model: "TYT",
        submodel: "FRONT GRILLE INNOVA T4 - CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 44,
        title: "FRONT GRILLE INNOVA CRYSTA T1 OEM QLTY",
        description: "CBH-BG061",
        price: 3000,
        model: "TYT",
        submodel: "FRONT GRILLE INNOVA CRYSTA T1 OEM QLTY",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 45,
        title: "FRONT GRILLE FORTUNER T3 - CHROME",
        description: "CBH-BG078",
        price: 4100,
        model: "TYT",
        submodel: "FRONT GRILLE FORTUNER T3 - CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 47,
        title: "FRONT GRILLE SUMO GOLD",
        description: "CBH-BG076",
        price: 1875,
        model: "TAT",
        submodel: "FRONT GRILLE SUMO GOLD",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 48,
        title: "FRONT GRILLE SAFARI STORME",
        description: "CBH-BG056",
        price: 3150,
        model: "TAT",
        submodel: "FRONT GRILLE SAFARI STORME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 49,
        title: "BUMPER GRILLE TIAGO/TIGOR T1",
        description: "CBH-BG086",
        price: 700,
        model: "TAT",
        submodel: "BUMPER GRILLE TIAGO/TIGOR T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 50,
        title: "FRONT GRILLE TIAGO/TIGOR T1",
        description: "CBH-BG085",
        price: 2850,
        model: "TAT",
        submodel: "FRONT GRILLE TIAGO/TIGOR T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 51,
        title: "FRONT GRILLE TIAGO/TIGOR T2",
        description: "CBH-BG119",
        price: 2650,
        model: "TAT",
        submodel: "FRONT GRILLE TIAGO/TIGOR T2",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 52,
        title: "FRONT GRILLE NEXON T1",
        description: "CBH-BG101",
        price: 3200,
        model: "TAT",
        submodel: "FRONT GRILLE NEXON T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 53,
        title: "FRONT GRILLE ZEST",
        description: "CBH-BG089",
        price: 3200,
        model: "TAT",
        submodel: "FRONT GRILLE ZEST",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 54,
        title: "FRONT GRILLE HARRIER",
        description: "CBH-BG089",
        price: 3800,
        model: "TAT",
        submodel: "FRONT GRILLE HARRIER",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 55,
        title: "BUMPER GRILLE SCORPIO S SERIES",
        description: "CBH-BG073",
        price: 325,
        model: "MHD",
        submodel: "BUMPER GRILLE SCORPIO S SERIES",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 56,
        title: "FRONT GRILLE BOLERO T2 - BLACK WITH CHROME",
        description: "CBH-BG094",
        price: 550,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T2 - BLACK WITH CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 57,
        title: "FRONT GRILLE BOLERO T2 - WHITE WITH WHITE",
        description: "CBH-BG075",
        price: 500,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T2 - WHITE WITH WHITE",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 58,
        title: "FRONT GRILLE BOLERO T2 - WHITE WITH CHROME",
        description: "CBH-BG077",
        price: 600,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T2 - WHITE WITH CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 59,
        title: "FRONT GRILLE BOLERO T3 - BLACK",
        description: "CBH-BG066",
        price: 450,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - BLACK",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 60,
        title: "FRONT GRILLE BOLERO T3 - BLACK WITH CHROME",
        description: "CBH-BG065",
        price: 550,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - BLACK WITH CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 61,
        title: "FRONT GRILLE BOLERO T3 - WHITE",
        description: "CBH-BG067",
        price: 550,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - WHITE",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 62,
        title: "FRONT GRILLE BOLERO T3 - WHITE WITH CHROME",
        description: "CBH-BG068",
        price: 600,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - WHITE WITH CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 63,
        title: "FRONT GRILLE BOLERO T3 - GREY WITH CHROME",
        description: "CBH-BG069",
        price: 700,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - GREY WITH CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 64,
        title: "FRONT GRILLE BOLERO T3 - FULL CHROME",
        description: "CBH-BG070",
        price: 1000,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - FULL CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 65,
        title: "FRONT GRILLE BOLERO T2 - WHITE W/WHITE NEW LOGO",
        description: "CBH-BG118",
        price: 600,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T2 - WHITE W/WHITE NEW LOGO",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 66,
        title: "FRONT GRILLE BOLERO T3 - BLACK W/CHROME NEW LOGO",
        description: "CBH-BG116",
        price: 600,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - BLACK W/CHROME NEW LOGO",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 67,
        title: "FRONT GRILLE BOLERO T3 - WHITE W/CHROME NEW LOGO",
        description: "CBH-BG117",
        price: 700,
        model: "MHD",
        submodel: "FRONT GRILLE BOLERO T3 - WHITE W/CHROME NEW LOGO",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 68,
        title: "FRONT GRILLE CITY T3",
        description: "CBH-BG026",
        price: 800,
        model: "HND",
        submodel: "FRONT GRILLE CITY T3",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 69,
        title: "FRONT GRILLE CITY T5",
        description: "CBH-BG074",
        price: 1400,
        model: "HND",
        submodel: "FRONT GRILLE CITY T5",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 70,
        title: "BUMPER GRILLE CITY T6",
        description: "CBH-BG007",
        price: 650,
        model: "HND",
        submodel: "BUMPER GRILLE CITY T6",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 71,
        title: "FRONT GRILLE CITY T7",
        description: "CBH-BG052",
        price: 2100,
        model: "HND",
        submodel: "FRONT GRILLE CITY T7",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 72,
        title: "FRONT GRILLE MOBILIO",
        description: "CBH-BG048",
        price: 3000,
        model: "HND",
        submodel: "FRONT GRILLE MOBILIO",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 73,
        title: "FRONT GRILLE POLO T1/VENTO T1",
        description: "CBH-BG034",
        price: 800,
        model: "VW",
        submodel: "FRONT GRILLE POLO T1/VENTO T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 74,
        title: "FRONT GRILLE POLO T2",
        description: "CBH-BG033",
        price: 900,
        model: "VW",
        submodel: "FRONT GRILLE POLO T2",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 75,
        title: "BUMPER GRILL POLO T1 BLACK",
        description: "CBH-BG006",
        price: 375,
        model: "VW",
        submodel: "BUMPER GRILL POLO T1 BLACK",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 76,
        title: "BUMPER GRILLE POLO T1 CHROME",
        description: "CBH-BG063",
        price: 575,
        model: "VW",
        submodel: "BUMPER GRILLE POLO T1 CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 77,
        title: "BUMPER GRILLE POLO T2 CHROME",
        description: "CBH-BG014",
        price: 800,
        model: "VW",
        submodel: "BUMPER GRILLE POLO T2 CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 78,
        title: "BUMPER GRILLE VENTO T1 CHROME",
        description: "CBH-BG018",
        price: 750,
        model: "VW",
        submodel: "BUMPER GRILLE VENTO T1 CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 79,
        title: "BUMPER GRILLE VENTO T2 CHROME",
        description: "CBH-BG019",
        price: 1350,
        model: "VW",
        submodel: "BUMPER GRILLE VENTO T2 CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 80,
        title: "BUMPER GRILLE UPPER CRUZE T1",
        description: "CBH-BG009",
        price: 1000,
        model: "GM",
        submodel: "BUMPER GRILLE UPPER CRUZE T1",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 81,
        title: "BUMPER GRILLE DUSTER",
        description: "CBH-BG047",
        price: 625,
        model: "RNT",
        submodel: "BUMPER GRILLE DUSTER",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 82,
        title: "FRONT GRILLE DUSTER T2",
        description: "CBH-BG090",
        price: 4250,
        model: "RNT",
        submodel: "FRONT GRILLE DUSTER T2",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 83,
        title: "FRONT GRILLE KWID T2",
        description: "CBH-BG091",
        price: 2600,
        model: "RNT",
        submodel: "FRONT GRILLE KWID T2",
        link: "/frontgrillsandbumpergrills",
    }, {
        id: 84,
        title: "BUMPER GRILLE ECOSPORT BLACK",
        description: "CBH-BG009",
        price: 1375,
        model: "FORD",
        submodel: "BUMPER GRILLE ECOSPORT BLACK",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 85,
        title: "BUMPER GRILLE ECOSPORT GREY",
        description: "CBH-BG044",
        price: 3250,
        model: "FORD",
        submodel: "BUMPER GRILLE ECOSPORT GREY",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 86,
        title: "BUMPER GRILLE ECOSPORT CHROME",
        description: "CBH-BG010",
        price: 3125,
        model: "FORD",
        submodel: "BUMPER GRILLE ECOSPORT CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 87,
        title: "BUMPER GRILLE ECOSPORT T2 CHROME",
        description: "CBH-BG072",
        price: 6100,
        model: "FORD",
        submodel: "BUMPER GRILLE ECOSPORT T2 CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 88,
        title: "BUMPER GRILLE OCTAVIA T2",
        description: "CBH-BG025",
        price: 1000,
        model: "SKD",
        submodel: "BUMPER GRILLE OCTAVIA T2",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 89,
        title: "BUMPER GRILLE OCTAVIA T3",
        description: "CBH-BG012",
        price: 1500,
        model: "SKD",
        submodel: "BUMPER GRILLE OCTAVIA T3",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 90,
        title: "CHROME STRIP BG OCTAVIA T3",
        description: "CBH-BG037",
        price: 1000,
        model: "SKD",
        submodel: "CHROME STRIP BG OCTAVIA T3",
        link: "/frontgrillsandbumpergrills",
    },

    {
        id: 91,
        title: "BUMPER GRILLE TERRANO",
        description: "CBH-BG017",
        price: 2000,
        model: "NSN",
        submodel: "BUMPER GRILLE TERRANO",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 92,
        title: "FRONT GRILLE SUNNY T1 CHROME",
        description: "CBH-BG035",
        price: 2500,
        model: "NSN",
        submodel: "FRONT GRILLE SUNNY T1 CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 93,
        title: "FRONT GRILLE SUNNY T2 CHROME",
        description: "CBH-BG092",
        price: 2500,
        model: "NSN",
        submodel: "FRONT GRILLE SUNNY T2 CHROME",
        link: "/frontgrillsandbumpergrills",
    },
    {
        id: 94,
        title: "GRILL LAN-CER TYPE-1",
        description: "CBH-BG092",
        price: 2500,
        model: "NSN",
        submodel: "GRILL LAN-CER TYPE-1",
        link: "/frontgrillsandbumpergrills",
    },



]


export const FOGLAMPCOVERSProduct = [
    {
        id: 1,
        title: "SWIFT T3 - OPEN/CLOSE RH/LH",
        description: "CBH-FG084/085",
        price: 75,
        model: "MSZ",
        submodel: "SWIFT T3 - OPEN/CLOSE RH/LH",
        link: "/foglampcover",
    },
    {
        id: 2,
        title: "SWIFT T4 - BLACK RH/LH",
        description: "CBH-FG060/061",
        price: 125,
        model: "MSZ",
        submodel: "SWIFT T4 - BLACK RH/LH",
        link: "/foglampcover",
    },
    {
        id: 3,
        title: "SWIFT T4 - SILVER RH/LH",
        description: "CBH-FG062/063",
        price: 175,
        model: "MSZ",
        submodel: "SWIFT T4 - SILVER RH/LH",
        link: "/foglampcover",
    },
    {
        id: 4,
        title: "SWIFT T5 - OPEN/CLOSE RH/LH",
        description: "CBH-FG082/083",
        price: 100,
        model: "MSZ",
        submodel: "SWIFT T5 - OPEN/CLOSE RH/LH",
        link: "/foglampcover",
    },
    {
        id: 5,
        title: "DZIRE T3 - CHROME OEM QLTY RH/LH",
        description: "CBH-FG036/037",
        price: 450,
        model: "MSZ",
        submodel: "DZIRE T3 - CHROME OEM QLTY RH/LH",
        link: "/foglampcover",
    },
    {
        id: 6,
        title: "ERTIGA T2 - BLACK RH/LH",
        description: "CBH-FG074/075",
        price: 425,
        model: "MSZ",
        submodel: "ERTIGA T2 - BLACK RH/LH",
        link: "/foglampcover",
    },
    {
        id: 7,
        title: "ERTIGA T2 - CHROME RH/LH",
        description: "CBH-FG078/079",
        price: 300,
        model: "MSZ",
        submodel: "ERTIGA T2 - CHROME RH/LH",
        link: "/foglampcover",
    },
    {
        id: 8,
        title: "INNOVA T3",
        description: "CBH-FG086/087",
        price: 100,
        model: "TYT",
        submodel: "INNOVA T3",
        link: "/foglampcover",
    },
    {
        id: 9,
        title: "INNOVA T4 - BLACK RH/LH",
        description: "CBH-FG026/027",
        price: 75,
        model: "TYT",
        submodel: "INNOVA T4 - BLACK RH/LH",
        link: "/foglampcover",
    },
    {
        id: 10,
        title: "INNOVA T4 - CHROME RH/LJ",
        description: "CBH-FG028/029",
        price: 200,
        model: "TYT",
        submodel: "INNOVA T4 - CHROME RH/LJ",
        link: "/foglampcover",
    },
    {
        id: 11,
        title: "AMAZE T2 - CLOSE RH/LH",
        description: "CBH-FG030/031",
        price: 150,
        model: "HND",
        submodel: "AMAZE T2",
        link: "/foglampcover",
    },
    {
        id: 12,
        title: "AMAZE T2 - OPEN RH/LH",
        description: "CBH-FG38/39",
        price: 150,
        model: "HND",
        submodel: "AMAZE T2",
        link: "/foglampcover",
    },
    {
        id: 13,
        title: "CITY T6 - OPEN RH/LH",
        description: "CBH-FG32/33",
        price: 150,
        model: "HND",
        submodel: "CITY T6",
        link: "/foglampcover",
    },
    {
        id: 14,
        title: "CITY T7 - CLOSE RH/LH",
        description: "CBH-FG34/35",
        price: 150,
        model: "HND",
        submodel: "CITY T7",
        link: "/foglampcover",
    },
    {
        id: 15,
        title: "CITY T7 - OPEN RH/LH",
        description: "CBH-FG40/41",
        price: 150,
        model: "HND",
        submodel: "CITY T7",
        link: "/foglampcover",
    },
    {
        id: 16,
        title: "I10 GRAND T2 - OPEN - CHROME RH/LH",
        description: "CBH-FG052/053",
        price: 400,
        model: "HYD",
        submodel: "I10 GRAND T2",
        link: "/foglampcover",
    },
    {
        id: 17,
        title: "I10 GRAND T2 - CLOSE RH/LH",
        description: "CBH-FG054/055",
        price: 350,
        model: "HYD",
        submodel: "I10 GRAND T2",
        link: "/foglampcover",
    },
    {
        id: 18,
        title: "POLO T1 OPEN RH/LH",
        description: "CBH-FG014/015",
        price: 100,
        model: "VW",
        submodel: "POLO T1",
        link: "/foglampcover",
    },
    {
        id: 19,
        title: "POLO T1 CLOSE RH/LH",
        description: "CBH-FG008/009",
        price: 100,
        model: "VW",
        submodel: "POLO T1",
        link: "/foglampcover",
    },
    {
        id: 20,
        title: "POLO T2 CHROME OPEN RH/LH",
        description: "CBH-FG022/023",
        price: 550,
        model: "VW",
        submodel: "POLO T2",
        link: "/foglampcover",
    },
    {
        id: 21,
        title: "POLO T2 CHROME CLOSE RH/LH",
        description: "CBH-FG018/019",
        price: 550,
        model: "VW",
        submodel: "POLO T2",
        link: "/foglampcover",
    },
    {
        id: 22,
        title: "POLO T2 BLACK OPEN RH/LH",
        description: "CBH-FG016/017",
        price: 400,
        model: "VW",
        submodel: "POLO T2",
        link: "/foglampcover",
    },
    {
        id: 23,
        title: "POLO T2 BLACK CLOSE RH/LH",
        description: "CBH-FG020/021",
        price: 400,
        model: "VW",
        submodel: "POLO T2",
        link: "/foglampcover",
    },
    {
        id: 24,
        title: "VENTO T1 CHROME OPEN RH/LH",
        description: "CBH-FG004/005",
        price: 275,
        model: "VW",
        submodel: "VENTO T1",
        link: "/foglampcover",
    },
    {
        id: 25,
        title: "VENTO T1 W/O HOLE RH/LH",
        description: "CBH-FG46/47",
        price: 250,
        model: "VW",
        submodel: "VENTO T1",
        link: "/foglampcover",
    },
    {
        id: 26,
        title: "VENTO T2 OPEN/CLOSE RH/LH",
        description: "CBH-FG006/007",
        price: 550,
        model: "VW",
        submodel: "VENTO T2",
        link: "/foglampcover",
    },
    {
        id: 27,
        title: "SCORPIO S-11 - CHROME RH/LH",
        description: "CBH-FG44/45",
        price: 250,
        model: "MHD",
        submodel: "SCORPIO S-11",
        link: "/foglampcover",
    },
    {
        id: 28,
        title: "SCORPIO S-11 - BLACK RH/LH",
        description: "CBH-FG42/43",
        price: 125,
        model: "MHD",
        submodel: "SCORPIO S-11",
        link: "/foglampcover",
    },
    {
        id: 29,
        title: "OCTAVIA T2 RH/LH",
        description: "CBH-FG010/011",
        price: 550,
        model: "SKD",
        submodel: "OCTAVIA T2",
        link: "/foglampcover",
    },
    {
        id: 30,
        title: "OCTAVIA T3 RH/LH",
        description: "CBH-FG012/013",
        price: 900,
        model: "SKD",
        submodel: "OCTAVIA T3",
        link: "/foglampcover",
    },
]
export const FOGLAMPProduct = [
    {
        id: 1,
        title: "SWIFT TYPE",
        description: "CBH-FGL001",
        price: 300,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/foglamp",
    },
    {
        id: 2,
        title: "SWIFT VAL TYPE",
        description: "CBH-FGL018",
        price: 350,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/foglamp",
    },
    {
        id: 3,
        title: "REAR BUMPER FOG LAMP SWIFT W/O CONNEC",
        description: "CBH-FGL002",
        price: 250,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/foglamp",
    },
    {
        id: 4,
        title: "REAR BUMPER FOG LAMP SWIFT WITH CONNEC",
        description: "CBH-FGL007",
        price: 250,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/foglamp",
    },

    {
        id: 5,
        title: "INNOVA RH/LH",
        description: "CBH-FGL009/010",
        price: 400,
        model: "TYT",
        submodel: "INNOVA",
        link: "/foglamp",
    },
    {
        id: 6,
        title: "FOG LAMP SET INNOVA (WITH WIRE HARNESS)",
        description: "CBH-FGL008",
        price: 1600,
        model: "TYT",
        submodel: "INNOVA",
        link: "/foglamp",
    },
    {
        id: 7,
        title: "ETIOS RH/LH",
        description: "CBH-FGL015/016",
        price: 400,
        model: "TYT",
        submodel: "ETIOS",
        link: "/foglamp",
    },
    {
        id: 8,
        title: "FLUIDIC T1",
        description: "CBH-FGL003/004",
        price: 800,
        model: "HYD",
        submodel: "FLUIDIC T1",
        link: "/foglamp",
    },
    {
        id: 9,
        title: "TIAGO/TIGOR T1",
        description: "CBH-FGL017",
        price: 400,
        model: "TAT",
        submodel: "TIAGO/TIGOR T1",
        link: "/foglamp",
    },
    {
        id: 10,
        title: "POLO T1/VENTO T2",
        description: "CBH-FGL011/012",
        price: 825,
        model: "VW",
        submodel: "POLO T1/VENTO T2",
        link: "/foglamp",
    },
    {
        id: 11,
        title: "POLO T2/AMEO",
        description: "CBH-FGL013/014",
        price: 1000,
        model: "VW",
        submodel: "POLO T2/AMEO",
        link: "/foglamp",
    },
    {
        id: 12,
        title: "VENTO T1",
        description: "CBH-FGL005/006",
        price: 500,
        model: "VW",
        submodel: "VENTO T1",
        link: "/foglamp",
    },

]
export const BUMPERBRACKETProduct = [
    {
        id: 1,
        title: "SWIFT T2 FRONT/DZIRE T1 FRONT",
        description: "CBH-BB15/16",
        price: 60,
        model: "MSZ",
        submodel: "SWIFT T2 FRONT/DZIRE T1 FRONT",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/5/_/5.jpg"
    },
    {
        id: 2,
        title: "WAGONR K-SERIES FRONT",
        description: "CBH-BB09/10",
        price: 60,
        model: "MSZ",
        submodel: "WAGONR K-SERIES FRONT",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/4/14.jpg'
    },
    {
        id: 3,
        title: "WAGONR T-4/5 REAR",
        description: "CBH-BB159/160",
        price: 60,
        model: "MSZ",
        submodel: "WAGONR T-4/5 REAR",
        link: "/bumperbracket",
    },
    {
        id: 4,
        title: "K-10 T1 FRONT",
        description: "CBH-BB165/166",
        price: 75,
        model: "MSZ",
        submodel: "K-10 T1 FRONT",
        link: "/bumperbracket",
    },
    {
        id: 5,
        title: "RITZ FRONT",
        description: "CBH-BB11/12",
        price: 75,
        model: "MSZ",
        submodel: "RITZ FRONT",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/0/20.jpg"
    },
    {
        id: 6,
        title: "SWIFT T3/T4 FRONT/DZIRE T2/T3 FRONT",
        description: "CBH-BB67/68",
        price: 75,
        model: "MSZ",
        submodel: "SWIFT T3/T4 FRONT/DZIRE T2/T3 FRONT",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/9/19.jpg"
    },
    {
        id: 7,
        title: "SWIFT T3/4 REAR",
        description: "CBH-BB125/126",
        price: 75,
        model: "MSZ",
        submodel: "SWIFT T3/4 REAR",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/9/19.jpg"
    },
    {
        id: 8,
        title: "SWIFT T5/6 DZIRE T4/5 FRONT",
        description: "CBH-BB133/134",
        price: 75,
        model: "MSZ",
        submodel: "SWIFT T5/6 DZIRE T4/5 FRONT",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/5/_/5.jpg"
    },
    {
        id: 9,
        title: "DZIRE T2/3 REAR",
        description: "CBH-BB93/94",
        price: 75,
        model: "MSZ",
        submodel: "DZIRE T2/3 REAR",
        link: "/bumperbracket",
    },
    {
        id: 10,
        title: "DZIRE T4/5 REAR",
        description: "CBH-BB163/164",
        price: 100,
        model: "MSZ",
        submodel: "DZIRE T4/5 REAR",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/9/19_1.jpg"
    },
    {
        id: 11,
        title: "ALTO 800 T1/2/3 FRONT/K-10 T2",
        description: "CBH-BB01/02",
        price: 50,
        model: "MSZ",
        submodel: "ALTO 800 T1/2/3 FRONT/K-10 T2",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/1/11_2.jpg'
    },
    {
        id: 12,
        title: "ALTO 800 REAR/K-10 T2 REAR",
        description: "CBH-BB97/98",
        price: 75,
        model: "MSZ",
        submodel: "ALTO 800 REAR/K-10 T2 REAR",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/1/11_2.jpg"
    },
    {
        id: 13,
        title: "DZIRE T1 REAR",
        description: "CBH-BB75/76",
        price: 150,
        model: "MSZ",
        submodel: "DZIRE T1 REAR",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/5/_/5_1.jpg"
    },
    {
        id: 14,
        title: "WAGONR T6 FRONT",
        description: "CBH-BB131/132",
        price: 60,
        model: "MSZ",
        submodel: "WAGONR T6 FRONT",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/4/14.jpg'
    },
    {
        id: 15,
        title: "WAGONR T6 REAR",
        description: "CBH-BB159/160",
        price: 75,
        model: "MSZ",
        submodel: "WAGONR T6 REAR",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/4/14.jpg'
    },
    {
        id: 16,
        title: "BALENO T2/3 FRONT",
        description: "CBH-BB115/116",
        price: 75,
        model: "MSZ",
        submodel: "BALENO T2/3 FRONT",
        link: "/bumperbracket",
    },
    {
        id: 17,
        title: "BREZZA T1/2 FRONT",
        description: "CBH-BB117/118",
        price: 75,
        model: "MSZ",
        submodel: "BREZZA T1/2 FRONT",
        link: "/bumperbracket",
    },
    {
        id: 18,
        title: "CIAZ FRONT",
        description: "CBH-BB167/168",
        price: 75,
        model: "MSZ",
        submodel: "CIAZ FRONT",
        link: "/bumperbracket",
    },
    {
        id: 19,
        title: "ERTIGA T1/2 FRONT",
        description: "CBH-BB135/136",
        price: 80,
        model: "MSZ",
        submodel: "ERTIGA T1/2 FRONT",
        link: "/bumperbracket",
    },
    {
        id: 20,
        title: "I10 T1/T2 FRONT",
        description: "CBH-BB19/20",
        price: 50,
        model: "HYD",
        submodel: "I10 T1/T2",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/8/18.jpg'
    },
    {
        id: 21,
        title: "I20 T1 FRONT",
        description: "CBH-BB23/24",
        price: 50,
        model: "HYD",
        submodel: "I20 T1",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/8/18.jpg'
    },
    {
        id: 22,
        title: "I20 T2 FRONT",
        description: "CBH-BB25/26",
        price: 50,
        model: "HYD",
        submodel: "I20 T2",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/8/18.jpg'
    },
    {
        id: 23,
        title: "I10 GRAND T1/2 FRONT",
        description: "CBH-BB17/18",
        price: 50,
        model: "HYD",
        submodel: "I10 GRAND T1/2",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/9/_/9.jpg'
    },
    {
        id: 24,
        title: "I10 GRAND T1/2 REAR",
        description: "CBH-BB127/128",
        price: 100,
        model: "HYD",
        submodel: "I10 GRAND T1/2",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/9/_/9.jpg'
    },
    {
        id: 25,
        title: "I20 ELITE FRONT",
        description: "CBH-BB21/22",
        price: 75,
        model: "HYD",
        submodel: "I20 ELITE",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/2/32.jpg"
    },
    {
        id: 26,
        title: "EON",
        description: "CBH-BB99/100",
        price: 75,
        model: "HYD",
        submodel: "EON",
        link: "/bumperbracket",
    },
    {
        id: 27,
        title: "VERNA FLUIDIC FRONT",
        description: "CBH-BB085/86",
        price: 100,
        model: "HYD",
        submodel: "VERNA FLUIDIC",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/4/_/4_3.jpg"
    },
    {
        id: 28,
        title: "VERNA FLUDIC REAR",
        description: "CBH-BB27/28",
        price: 250,
        model: "HYD",
        submodel: "VERNA FLUIDIC",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/0/30.jpg'

    },
    {
        id: 29,
        title: "ELANTRA FLUIDIC FRONT",
        description: "CBH-BB71/72",
        price: 250,
        model: "HYD",
        submodel: "ELANTRA FLUIDIC",
        link: "/bumperbracket",
    },
    {
        id: 30,
        title: "ELANTRA FLUIDIC REAR",
        description: "CBH-BB153/154",
        price: 300,
        model: "HYD",
        submodel: "ELANTRA FLUIDIC",
        link: "/bumperbracket",
    },
    {
        id: 31,
        title: "XING FRONT",
        description: "CBH-BB13/14",
        price: 50,
        model: "HYD",
        submodel: "XING",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/2/12_3.jpg"
    },
    {
        id: 32,
        title: "VERNA T1 FRONT",
        description: "CBH-BB63/64",
        price: 150,
        model: "HYD",
        submodel: "VERNA T1",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/5/25.jpg'
    },
    {
        id: 33,
        title: "VERNA T1 REAR",
        description: "CBH-BB79/80",
        price: 200,
        model: "HYD",
        submodel: "VERNA T1",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/5/25.jpg'
    },
    {
        id: 34,
        title: "CRETA T1 FRONT",
        description: "CBH-BB87/88",
        price: 100,
        model: "HYD",
        submodel: "CRETA T1",
        link: "/bumperbracket",
    },
    {
        id: 35,
        title: "CRETA T1 HEAD LAMP BKT",
        description: "CBH-BB89/90",
        price: 200,
        model: "HYD",
        submodel: "CRETA T1",
        link: "/bumperbracket",
    },

    {
        id: 36,
        title: "SELTOS FRONT",
        description: "CBH-BB169/170",
        price: 200,
        model: "KA",
        submodel: "SELTOS",
        link: "/bumperbracket",
    },
    {
        id: 37,
        title: "SELTOS REAR",
        description: "CBH-BB171/172",
        price: 500,
        model: "KA",
        submodel: "SELTOS",
        link: "/bumperbracket",
    },
    {
        id: 38,
        title: "INNOVA T2 FRONT",
        description: "CBH-BB05/06",
        price: 75,
        model: "TYT",
        submodel: "INNOVA T2",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/3/23_2.jpg"
    },
    {
        id: 39,
        title: "INNOVA T3/T4 FRONT",
        description: "CBH-BB07/08",
        price: 75,
        model: "TYT",
        submodel: "INNOVA T3/T4",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/2/22_3.jpg"
    },
    {
        id: 40,
        title: "INNOVA T3/T4 REAR",
        description: "CBH-BB81/82",
        price: 100,
        model: "TYT",
        submodel: "INNOVA T3/T4",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/2/22_3.jpg'
    },
    {
        id: 41,
        title: "INNOVA CRYSTA FRONT",
        description: "CBH-BB121/122",
        price: 125,
        model: "TYT",
        submodel: "INNOVA CRYSTA",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/2/22_3.jpg'
    },
    {
        id: 42,
        title: "FORTUNER T3 FRONT",
        description: "CBH-BB157/158",
        price: 175,
        model: "TYT",
        submodel: "FORTUNER T3",
        link: "/bumperbracket",
    },
    {
        id: 43,
        title: "ETIOS FRONT",
        description: "CBH-BB03/04",
        price: 50,
        model: "TYT",
        submodel: "ETIOS",
        link: "/bumperbracket",
    },
    {
        id: 44,
        title: "ETIOS REAR",
        description: "CBH-BB155/156",
        price: 75,
        model: "TYT",
        submodel: "ETIOS",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/6/36_1.jpg"
    },
    {
        id: 45,
        title: "COROLLA FRONT LH/RH",
        description: "CBH-BB29/30",
        price: 200,
        model: "TYT",
        submodel: "COROLLA LH/RH",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/4/24.jpg'
    },
    {
        id: 46,
        title: "COROLLA REAR LH/RH",
        description: "CBH-BB31/32",
        price: 200,
        model: "TYT",
        submodel: "COROLLA LH/RH",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/2/8/28.jpg'
    },
    {
        id: 47,
        title: "ALTIS T1/2 FRONT LH/RH",
        description: "CBH-BB35/36",
        price: 200,
        model: "TYT",
        submodel: "ALTIS T1/2 LH/RH",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/5/35.jpg'
    },
    {
        id: 48,
        title: "ALTIS T1/2 REAR LH/RH",
        description: "CBH-BB37/38",
        price: 200,
        model: "TYT",
        submodel: "ALTIS T1/2 LH/RH",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/3/33_1.jpg'
    },
    {
        id: 49,
        title: "ALTIS T1/2 REAR BIG LH/RH",
        description: "CBH-BB105/106",
        price: 250,
        model: "TYT",
        submodel: "ALTIS T1/2 BIG LH/RH",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/3/33_1.jpg'
    },
    {
        id: 50,
        title: "ALTIS T3 FRONT",
        description: "CBH-BB55/56",
        price: 225,
        model: "TYT",
        submodel: "ALTIS T3",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/5/35.jpg'
    },
    {
        id: 51,
        title: "HONDA T3 FRONT",
        description: "CBH-BB47/48",
        price: 200,
        model: "HND",
        submodel: "HONDA T3",
        link: "/bumperbracket",
    },
    {
        id: 52,
        title: "HONDA T4 FRONT",
        description: "CBH-BB61/62",
        price: 200,
        model: "HND",
        submodel: "HONDA T4",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/1/31.jpg'
    },
    {
        id: 53,
        title: "HONDA T3/T4 REAR",
        description: "CBH-BB49/50",
        price: 150,
        model: "HND",
        submodel: "HONDA T3/T4",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/5/15_1.jpg'
    },
    {
        id: 54,
        title: "HONDA T5/6 HEAD LAMP BRACKET",
        description: "CBH-BB39/40",
        price: 150,
        model: "HND",
        submodel: "HONDA T5/6 HEAD LAMP BRACKET",
        link: "/bumperbracket",
    },
    {
        id: 55,
        title: "HONDA T5/6 REAR",
        description: "CBH-BB41/42",
        price: 300,
        model: "HND",
        submodel: "HONDA T5/6 REAR",
        link: "/bumperbracket",
    },
    {
        id: 56,
        title: "HONDA T7 FRONT",
        description: "CBH-BB141/142",
        price: 75,
        model: "HND",
        submodel: "HONDA T7",
        link: "/bumperbracket",
    },
    {
        id: 57,
        title: "HONDA T7 HEAD LAMP BRACKET",
        description: "CBH-BB53/54",
        price: 160,
        model: "HND",
        submodel: "HONDA T7 HEAD LAMP BRACKET",
        link: "/bumperbracket",
    },
    {
        id: 58,
        title: "HONDA T8 HEAD LAMP BRACKET",
        description: "CBH-BB113/114",
        price: 175,
        model: "HND",
        submodel: "HONDA T8 HEAD LAMP BRACKET",
        link: "/bumperbracket",
    },
    {
        id: 59,
        title: "HONDA T7/8 REAR",
        description: "CBH-BB143/144",
        price: 125,
        model: "HND",
        submodel: "HONDA T7/8 REAR",
        link: "/bumperbracket",
    },
    {
        id: 60,
        title: "AMAZE T1/2 FRONT",
        description: "CBH-BB95/96",
        price: 75,
        model: "HND",
        submodel: "AMAZE T1/2",
        link: "/bumperbracket",
    },
    {
        id: 61,
        title: "AMAZE T1/2 REAR",
        description: "CBH-119/120",
        price: 125,
        model: "HND",
        submodel: "AMAZE T1/2",
        link: "/bumperbracket",
    },
    {
        id: 62,
        title: "AMAZE T3 FRONT",
        description: "CBH-BB139/140",
        price: 75,
        model: "HND",
        submodel: "AMAZE T3",
        link: "/bumperbracket",
    },
    {
        id: 63,
        title: "WRV FRONT",
        description: "CBH-BB145/146",
        price: 150,
        model: "HND",
        submodel: "WRV",
        link: "/bumperbracket",
    },
    {
        id: 64,
        title: "CIVIC FRONT",
        description: "CBH-BB43/44",
        price: 250,
        model: "HND",
        submodel: "CIVIC",
        link: "/bumperbracket",
    },
    {
        id: 65,
        title: "CIVIC REAR",
        description: "CBH-BB45/46",
        price: 250,
        model: "HND",
        submodel: "CIVIC",
        link: "/bumperbracket",
    },
    {
        id: 66,
        title: "ACCORD FRONT",
        description: "CBH-BB33/34",
        price: 400,
        model: "HND",
        submodel: "ACCORD",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/6/16_1_2.jpg'
    },
    {
        id: 67,
        title: "TIAGO FRONT",
        description: "CBH-BB91/92",
        price: 100,
        model: "TAT",
        submodel: "TIAGO",
        link: "/bumperbracket",
    },
    {
        id: 68,
        title: "NEXON FRONT",
        description: "CBH-B129/130",
        price: 125,
        model: "TAT",
        submodel: "NEXON",
        link: "/bumperbracket",
    },
    {
        id: 69,
        title: "SCORPIO S SERIES",
        description: "CBH-BB123/124",
        price: 75,
        model: "MHD",
        submodel: "SCORPIO S SERIES",
        link: "/bumperbracket",
    },
    {
        id: 70,
        title: "CRUZE FRONT",
        description: "CBH-BB51/52",
        price: 100,
        model: "GM",
        submodel: "CRUZE",
        link: "/bumperbracket",
    },
    {
        id: 71,
        title: "POLO/VENTO FRONT",
        description: "CBH-BB65/66",
        price: 275,
        model: "VW",
        submodel: "POLO/VENTO",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/_/3_1.jpg'
    },
    {
        id: 72,
        title: "POLO T1 REAR",
        description: "CBH-BB147/148",
        price: 175,
        model: "VW",
        submodel: "POLO T1",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/_/3_1.jpg'
    },
    {
        id: 73,
        title: "POLO T2 REAR",
        description: "CBH-BB149/150",
        price: 250,
        model: "VW",
        submodel: "POLO T2",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/3/_/3_1.jpg'
    },
    {
        id: 74,
        title: "VENTO T1/2 REAR",
        description: "CBH-BB151/152",
        price: 550,
        model: "VW",
        submodel: "VENTO T1/2",
        link: "/bumperbracket",
    },
    {
        id: 75,
        title: "DUSTER FRONT",
        description: "CBH-BB101/102",
        price: 200,
        model: "RNT",
        submodel: "DUSTER",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_39.jpg'
    },
    {
        id: 76,
        title: "DUSTER REAR",
        description: "CBH-BB103/104",
        price: 250,
        model: "RNT",
        submodel: "DUSTER",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_39.jpg'
    },

    {
        id: 77,
        title: "KWID FRONT",
        description: "CBH-BB137/138",
        price: 75,
        model: "RNT",
        submodel: "KWID",
        link: "/bumperbracket",
    },
    {
        id: 78,
        title: "MICRA FRONT",
        description: "CBH-BB69/70",
        price: 200,
        model: "NSN",
        submodel: "MICRA",
        link: "/bumperbracket",
    },
    {
        id: 79,
        title: "SUNNY T1 FRONT",
        description: "CBH-BB83/84",
        price: 200,
        model: "NSN",
        submodel: "SUNNY T1",
        link: "/bumperbracket",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/1/0/10.jpg"
    },
    {
        id: 80,
        title: "LAURA",
        description: "CBH-BB73/74",
        price: 300,
        model: "SKD",
        submodel: "LAURA",
        link: "/bumperbracket",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/6/_/6.jpg'
    },
    {
        id: 81,
        title: "ECO SPORT FRONT",
        description: "CBH-BB77/78",
        price: 250,
        model: "Ford",
        submodel: "ECO",
        link: "/bumperbracket",
    },
    {
        id: 82,
        title: "ECO SPORT REAR",
        description: "CBH-BB109/110",
        price: 250,
        model: "Ford",
        submodel: "ECO",
        link: "/bumperbracket",
    }

]
export const MudFlapsproduct = [
    {
        id: 1,
        title: "ALTO",
        description: "CBH-MF101",
        price: 200,
        model: "MSZ",
        submodel: "ALTO",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_20.jpg'

    },
    {
        id: 2,
        title: "ALTO K10 T2",
        description: "CBH-MF102",
        price: 200,
        model: "MSZ",
        submodel: "ALTO",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_18.jpg'
    },
    {
        id: 3,
        title: "ALTO 800",
        description: "CBH-MF103",
        price: 200,
        model: "MSZ",
        submodel: "ALTO",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_20.jpg'
    },
    {
        id: 4,
        title: "WAGON R K SERIES",
        description: "CBH-MF104",
        price: 200,
        model: "MSZ",
        submodel: "WAGON R",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_13.jpg'
    },
    {
        id: 5,
        title: "WAGON R T6 (2019)",
        description: "CBH-MF123",
        price: 200,
        model: "MSZ",
        submodel: "WAGON R",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_13.jpg'
    },

    {
        id: 6,
        title: "SWIFT T1",
        description: "CBH-MF118",
        price: 200,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_1_12.jpg'
    },
    {
        id: 7,
        title: "SWIFT T2",
        description: "CBH-MF105",
        price: 200,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_1_12.jpg'
    },
    {
        id: 8,
        title: "SWIFT T3/T4",
        description: "CBH-MF106",
        price: 200,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_11.jpg'
    },
    {
        id: 9,
        title: "SWIFT T5",
        description: "CBH-MF107",
        price: 200,
        model: "MSZ",
        submodel: "SWIFT",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_21.jpg'
    },
    {
        id: 10,
        title: "SWIFT DZIRE T1",
        description: "CBH-MF108",
        price: 200,
        model: "MSZ",
        submodel: "SWIFT DZIRE",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_6_1.jpg"
    },
    {
        id: 11,
        title: "SWIFT DZIRE T2/T3",
        description: "CBH-MF109",
        price: 200,
        model: "MSZ",
        submodel: "SWIFT DZIRE",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_2.jpg"
    },
    {
        id: 12,
        title: "SWIFT DZIRE T4",
        description: "CBH-MF110",
        price: 200,
        model: "MSZ",
        submodel: "SWIFT DZIRE",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_2.jpg"
    },
    {
        id: 13,
        title: "EECO FRONT",
        description: "CBH-MF111",
        price: 250,
        model: "MSZ",
        submodel: "EECO",
        link: "/mudflaps",
    },
    {
        id: 14,
        title: "EECO REAR",
        description: "CBH-MF112",
        price: 200,
        model: "MSZ",
        submodel: "EECO",
        link: "/mudflaps",
    },
    {
        id: 15,
        title: "ERTIGA T1/2",
        description: "CBH-MF113",
        price: 225,
        model: "MSZ",
        submodel: "ERTIGA",
        link: "/mudflaps",
    },
    {
        id: 16,
        title: "ERTIGA T3",
        description: "CBH-MF124",
        price: 200,
        model: "MSZ",
        submodel: "ERTIGA",
        link: "/mudflaps",
    },
    {
        id: 17,
        title: "CELERIO T1",
        description: "CBH-MF114",
        price: 200,
        model: "MSZ",
        submodel: "CELERIO",
        link: "/mudflaps",
    },
    {
        id: 18,
        title: "CELERIO T2",
        description: "CBH-MF115",
        price: 200,
        model: "MSZ",
        submodel: "CELERIO",
        link: "/mudflaps",
    },
    {
        id: 19,
        title: "BALENO N/M",
        description: "CBH-MF116",
        price: 250,
        model: "MSZ",
        submodel: "BALENO",
        link: "/mudflaps",
    },
    {
        id: 20,
        title: "BREZZA",
        description: "CBH-MF117",
        price: 250,
        model: "MSZ",
        submodel: "BREZZA",
        link: "/mudflaps",
    },
    {
        id: 21,
        title: "ZEN OLD",
        description: "CBH-MF122",
        price: 200,
        model: "MSZ",
        submodel: "ZEN",
        link: "/mudflaps",
    },
    {
        id: 22,
        title: "ZEN ESTILO",
        description: "CBH-MF120",
        price: 200,
        model: "MSZ",
        submodel: "ZEN",
        link: "/mudflaps",
    },
    {
        id: 23,
        title: "ESTILO",
        description: "CBH-MF121",
        price: 200,
        model: "MSZ",
        submodel: "ESTILO",
        link: "/mudflaps",
    },
    {
        id: 24,
        title: "RITZ",
        description: "CBH-MF119",
        price: 200,
        model: "MSZ",
        submodel: "RITZ",
        link: "/mudflaps",
    },
    {
        id: 25,
        title: "WAGONR T7 (2019)",
        description: "CBH-MF123",
        price: 200,
        model: "MSZ",
        submodel: "WAGON R",
        link: "/mudflaps",
    },
    {
        id: 26,
        title: "I10 T1/T2",
        description: "CBH-MF201",
        price: 200,
        model: "HYD",
        submodel: "I10",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_4.jpg'
    },
    {
        id: 27,
        title: "I10 GRAND",
        description: "CBH-MF202",
        price: 200,
        model: "HYD",
        submodel: "I10 GRAND",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_4.jpg'
    },
    {
        id: 28,
        title: "I20 ELITE",
        description: "CBH-MF203",
        price: 200,
        model: "HYD",
        submodel: "I20 ELITE",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_27.jpg'
    },
    {
        id: 29,
        title: "XCENT",
        description: "CBH-MF204",
        price: 200,
        model: "HYD",
        submodel: "XCENT",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_3.jpg'
    },
    {
        id: 30,
        title: "EON",
        description: "CBH-MF205",
        price: 200,
        model: "HYD",
        submodel: "EON",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_23.jpg"
    },
    {
        id: 31,
        title: "I20",
        description: "CBH-MF206",
        price: 200,
        model: "HYD",
        submodel: "I20",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_28.jpg'
    },
    {
        id: 32,
        title: "ACCENT",
        description: "CBH-MF207",
        price: 200,
        model: "HYD",
        submodel: "ACCENT",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_29.jpg'
    },
    {
        id: 33,
        title: "CRETA",
        description: "CBH-MF208",
        price: 200,
        model: "HYD",
        submodel: "CRETA",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_6.jpg'
    },
    {
        id: 34,
        title: "CRETA T3",
        description: "CBH-MF209",
        price: 200,
        model: "HYD",
        submodel: "CRETA",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/000_1_6.jpg"
    },
    {
        id: 35,
        title: "TIGOR",
        description: "CBH-MF301",
        price: 200,
        model: "TAT",
        submodel: "TIGOR",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_30.jpg'
    },
    {
        id: 36,
        title: "TIAGO",
        description: "CBH-MF302",
        price: 200,
        model: "TAT",
        submodel: "TIAGO",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/004_2.jpg'
    },
    {
        id: 37,
        title: "NEXON",
        description: "CBH-MF303",
        price: 200,
        model: "TAT",
        submodel: "NEXON",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_32.jpg"
    },
    {
        id: 38,
        title: "PUNCH",
        description: "CBH-MF304",
        price: 250,
        model: "TAT",
        submodel: "PUNCH",
        link: "/mudflaps",
    },
    {
        id: 39,
        title: "XUV 500",
        description: "CBH-MF401",
        price: 300,
        model: "MHD",
        submodel: "XUV 500",
        link: "/mudflaps",
    },
    {
        id: 40,
        title: "M-HAWK REAR",
        description: "CBH-MF402",
        price: 225,
        model: "MHD",
        submodel: "M-HAWK",
        link: "/mudflaps",
    },
    {
        id: 41,
        title: "BOLERO",
        description: "CBH-MF403",
        price: 225,
        model: "MHD",
        submodel: "BOLERO",
        link: "/mudflaps",
    },
    {
        id: 42,
        title: "ETIOS",
        description: "CBH-MF501",
        price: 200,
        model: "TYT",
        submodel: "ETIOS",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_42.jpg'
    },
    {
        id: 43,
        title: "ETIOS LIVA",
        description: "CBH-MF502",
        price: 200,
        model: "TYT",
        submodel: "ETIOS LIVA",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_41.jpg"
    },
    {
        id: 44,
        title: "INNOVA CRYSTA",
        description: "CBH-MF503",
        price: 275,
        model: "TYT",
        submodel: "INNOVA CRYSTA",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_35.jpg'
    },
    {
        id: 45,
        title: "INNOVA",
        description: "CBH-MF504",
        price: 200,
        model: "TYT",
        submodel: "INNOVA",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_1_43.jpg'
    },
    {
        id: 46,
        title: "KWID",
        description: "CBH-MF602",
        price: 200,
        model: "RNT",
        submodel: "KWID",
        link: "/mudflaps",
    },
    {
        id: 47,
        title: "DUSTER T1",
        description: "CBH-MF601",
        price: 300,
        model: "RNT",
        submodel: "DUSTER",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_39.jpg'
    },
    {
        id: 48,
        title: "CITY ZX",
        description: "CBH-MF701",
        price: 250,
        model: "HND",
        submodel: "CITY",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_3_8.jpg'
    },
    {
        id: 49,
        title: "CITY T7 /8/IDTEC",
        description: "CBH-MF702",
        price: 250,
        model: "HND",
        submodel: "CITY",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/003_2_2.jpg'
    },
    {
        id: 50,
        title: "AMAZE T3",
        description: "CBH-MF703",
        price: 225,
        model: "HND",
        submodel: "AMAZE",
        link: "/mudflaps",
    },
    {
        id: 51,
        title: "CRUZE",
        description: "CBH-MF801",
        price: 250,
        model: "GM",
        submodel: "CRUZE",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_40.jpg"
    },
    {
        id: 52,
        title: "POLO T1/T2",
        description: "CBH-MF901",
        price: 275,
        model: "VW",
        submodel: "POLO",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_41.jpg'
    },
    {
        id: 53,
        title: "VENTO",
        description: "CBH-MF902",
        price: 275,
        model: "VW",
        submodel: "VENTO",
        link: "/mudflaps",
        image: 'https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/002_6_2.jpg'
    },

    {
        id: 54,
        title: "ECOSPORT",
        description: "CBH-MF903",
        price: 250,
        model: "FRD",
        submodel: "ECOSPORT",
        link: "/mudflaps",
        image: "https://www.jptglobalautomotive.com/media/catalog/product/cache/1/small_image/800x/9df78eab33525d08d6e5fb8d27136e95/0/0/001_2_42.jpg"
    },
]
export const subMirrorProduct = [
    {
        id: 1,
        title: "ALTO Vxi",
        description: "CBH-SM001/002",
        price: 57.5,
        model: "MSZ",
        submodel: "ALTO Vxi",
        link: "/submirror",
    },
    {
        id: 2,
        title: "ALTO K-10 T1",
        description: "CBH-SM003/004",
        price: 57.5,
        model: "MSZ",
        submodel: "ALTO K-10 T1",
        link: "/submirror",
    },
    {
        id: 3,
        title: "ZEN",
        description: "CBH-SM005/006",
        price: 57.5,
        model: "MSZ",
        submodel: "ZEN",
        link: "/submirror",
    },
    {
        id: 4,
        title: "IGNIS MANUAL",
        description: "CBH-SM007/008",
        price: 86.25,
        model: "MSZ",
        submodel: "IGNIS MANUAL",
        link: "/submirror",
    },
    {
        id: 5,
        title: "CELERIO MANUAL",
        description: "CBH-SM069/070",
        price: 86.25,
        model: "MSZ",
        submodel: "CELERIO MANUAL",
        link: "/submirror",
    },
    {
        id: 6,
        title: "RITZ",
        description: "CBH-SM061/062",
        price: 86.25,
        model: "MSZ",
        submodel: "RITZ",
        link: "/submirror",
    },
    {
        id: 7,
        title: "SWIFT T3 MANUAL",
        description: "CBH-SM059/060",
        price: 97.5,
        model: "MSZ",
        submodel: "SWIFT T3 MANUAL",
        link: "/submirror",
    },
    {
        id: 8,
        title: "DZIRE T2 MANUAL",
        description: "CBH-SM067/068",
        price: 97.5,
        model: "MSZ",
        submodel: "DZIRE T2 MANUAL",
        link: "/submirror",
    },
    {
        id: 9,
        title: "ERTIGA MANUAL",
        description: "CBH-SM079/080",
        price: 97.5,
        model: "MSZ",
        submodel: "ERTIGA MANUAL",
        link: "/submirror",
    },
    {
        id: 10,
        title: "SWIFT Vdi",
        description: "CBH-SM051/052",
        price: 101.25,
        model: "MSZ",
        submodel: "SWIFT Vdi",
        link: "/submirror",
    },
    {
        id: 11,
        title: "SWIFT T1/T2 DZIRE T1",
        description: "CBH-SM009/010",
        price: 72.5,
        model: "MSZ",
        submodel: "SWIFT T1/T2 DZIRE T1",
        link: "/submirror",
    },
    {
        id: 12,
        title: "ALTO K-10 T2",
        description: "CBH-SM077/078",
        price: 93.75,
        model: "MSZ",
        submodel: "ALTO K-10 T2",
        link: "/submirror",
    },
    {
        id: 13,
        title: "CIAZ",
        description: "CBH-SM003/004",
        price: 115,
        model: "MSZ",
        submodel: "CIAZ",
        link: "/submirror",
    },
    {
        id: 14,
        title: "SWIFT T5/DZIRE T4 MOTORIZED",
        description: "CBH-SM003/004",
        price: 115,
        model: "MSZ",
        submodel: "SWIFT T5/DZIRE T4 MOTORIZED",
        link: "/submirror",
    },
    {
        id: 15,
        title: "WAGONR T4/T5 MOTORIZED",
        description: "CBH-SM003/004",
        price: 86.25,
        model: "MSZ",
        submodel: "WAGONR T4/T5 MOTORIZED",
        link: "/submirror",
    },
    {
        id: 16,
        title: "SX4",
        description: "CBH-SM003/004",
        price: 107.5,
        model: "MSZ",
        submodel: "SX4",
        link: "/submirror",
    },
    {
        id: 17,
        title: "ALTO 800",
        description: "CBH-SM003/004",
        price: 65,
        model: "MSZ",
        submodel: "ALTO 800",
        link: "/submirror",
    },
    {
        id: 18,
        title: "INDICA T1",
        description: "CBH-SM011/012",
        price: 57.5,
        model: "TAT",
        submodel: "INDICA T1",
        link: "/submirror",
    },
    {
        id: 19,
        title: "INDICA LS",
        description: "CBH-SM087/088",
        price: 57.5,
        model: "TAT",
        submodel: "INDICA LS",
        link: "/submirror",
    },
    {
        id: 20,
        title: "INDICA VISTA/MANZA",
        description: "CBH-SM055/056",
        price: 86.25,
        model: "TAT",
        submodel: "INDICA VISTA/MANZA",
        link: "/submirror",
    },
    {
        id: 21,
        title: "INDICA V2",
        description: "CBH-SM091/092",
        price: 65,
        model: "TAT",
        submodel: "INDICA V2",
        link: "/submirror",
    },
    {
        id: 22,
        title: "NEXON/PUNCH",
        description: "CBH-SM011/012",
        price: 100,
        model: "TAT",
        submodel: "NEXON/PUNCH",
        link: "/submirror",
    },
    {
        id: 23,
        title: "i10 VX",
        description: "CBH-SM013/014",
        price: 86.25,
        model: "HYD",
        submodel: "i10 VX",
        link: "/submirror",
    },
    {
        id: 24,
        title: "i10 1.2",
        description: "CBH-SM015/016",
        price: 86.25,
        model: "HYD",
        submodel: "i10 1.2",
        link: "/submirror",
    },
    {
        id: 25,
        title: "i20 T2",
        description: "CBH-SM017/018",
        price: 86.25,
        model: "HYD",
        submodel: "i20 T2",
        link: "/submirror",
    },
    {
        id: 26,
        title: "i10 GRAND",
        description: "CBH-SM019/020",
        price: 86.25,
        model: "HYD",
        submodel: "i10 GRAND",
        link: "/submirror",
    },
    {
        id: 27,
        title: "XCENT",
        description: "CBH-SM021/022",
        price: 86.25,
        model: "HYD",
        submodel: "XCENT",
        link: "/submirror",
    },
    {
        id: 28,
        title: "i20 T1",
        description: "CBH-SM089/090",
        price: 86.25,
        model: "HYD",
        submodel: "i20 T1",
        link: "/submirror",
    },
    {
        id: 29,
        title: "XING",
        description: "CBH-SM039/040",
        price: 86.25,
        model: "HYD",
        submodel: "XING",
        link: "/submirror",
    },
    {
        id: 30,
        title: "i10 ERA",
        description: "CBH-SM013/014",
        price: 86.25,
        model: "HYD",
        submodel: "i10 ERA",
        link: "/submirror",
    },
    {
        id: 31,
        title: "EON",
        description: "CBH-SM015/016",
        price: 86.25,
        model: "HYD",
        submodel: "EON",
        link: "/submirror",
    },
    {
        id: 32,
        title: "i20 ELITE",
        description: "CBH-SM017/018",
        price: 86.25,
        model: "HYD",
        submodel: "i20 ELITE",
        link: "/submirror",
    },
    {
        id: 33,
        title: "CRETA T1/T2",
        description: "CBH-SM019/020",
        price: 100,
        model: "HYD",
        submodel: "CRETA T1/T2",
        link: "/submirror",
    },
    {
        id: 34,
        title: "VERNA FLUIDIC",
        description: "CBH-SM021/022",
        price: 93.75,
        model: "HYD",
        submodel: "VERNA FLUIDIC",
        link: "/submirror",
    },
    {
        id: 35,
        title: "CITY T3/T4",
        description: "CBH-SM023/024",
        price: 86.25,
        model: "HND",
        submodel: "CITY T3/T4",
        link: "/submirror",
    },
    {
        id: 36,
        title: "AMAZE T1/T2",
        description: "CBH-SM025/026",
        price: 86.25,
        model: "HND",
        submodel: "AMAZE T1/T2",
        link: "/submirror",
    },
    {
        id: 37,
        title: "BRIO",
        description: "CBH-SM047/048",
        price: 86.25,
        model: "HND",
        submodel: "BRIO",
        link: "/submirror",
    },
    {
        id: 38,
        title: "MOBILIO T1",
        description: "CBH-SM041/042",
        price: 86.25,
        model: "HND",
        submodel: "MOBILIO T1",
        link: "/submirror",
    },
    {
        id: 39,
        title: "CITY T7/T8 ALL NEW MODELS",
        description: "CBH-SM023/024",
        price: 101.25,
        model: "HND",
        submodel: "CITY T7/T8 ALL NEW MODELS",
        link: "/submirror",
    },
    {
        id: 40,
        title: "CITY T5/T6",
        description: "CBH-SM025/026",
        price: 93.75,
        model: "HND",
        submodel: "CITY T5/T6",
        link: "/submirror",
    },
    {
        id: 41,
        title: "FIGO",
        description: "CBHSM-027/028",
        price: 78.75,
        model: "FRD",
        submodel: "FIGO",
        link: "/submirror",
    },
    {
        id: 42,
        title: "FIESTA OLD",
        description: "CBH-SM063/064",
        price: 78.75,
        model: "FRD",
        submodel: "FIESTA OLD",
        link: "/submirror",
    },
    {
        id: 43,
        title: "ECOSPORT",
        description: "CBH-SM063/064",
        price: 122.5,
        model: "FRD",
        submodel: "ECOSPORT",
        link: "/submirror",
    },

    {
        id: 44,
        title: "BEAT",
        description: "CBH-SM029/030",
        price: 93.75,
        model: "GM",
        submodel: "BEAT",
        link: "/submirror",
    },
    {
        id: 45,
        title: "M-HAWK",
        description: "CBH-SM031/032",
        price: 107.5,
        model: "MHD",
        submodel: "M-HAWK",
        link: "/submirror",
    },
    {
        id: 46,
        title: "SCORPIO",
        description: "CBH-SM083/084",
        price: 115,
        model: "MHD",
        submodel: "SCORPIO",
        link: "/submirror",
    },
    {
        id: 47,
        title: "XUV 500",
        description: "CBH-SM045/046",
        price: 122.5,
        model: "MHD",
        submodel: "XUV 500",
        link: "/submirror",
    },
    {
        id: 48,
        title: "KUV 100",
        description: "CBH-SM085/086",
        price: 100,
        model: "MHD",
        submodel: "KUV 100",
        link: "/submirror",
    },
    {
        id: 49,
        title: "XYLO",
        description: "CBH-SM065/066",
        price: 107.5,
        model: "MHD",
        submodel: "XYLO",
        link: "/submirror",
    },
    {
        id: 50,
        title: "VERITO",
        description: "CBH-SM037/038",
        price: 107.5,
        model: "MHD",
        submodel: "VERITO",
        link: "/submirror",
    },
    {
        id: 51,
        title: "LOGAN",
        description: "CBH-SM037/038",
        price: 107.5,
        model: "MHD",
        submodel: "LOGAN",
        link: "/submirror",
    },
    {
        id: 52,
        title: "INNOVA WITH INDICATOR",
        description: "CBH-SM033/034",
        price: 107.5,
        model: "TYT",
        submodel: "INNOVA WITH INDICATOR",
        link: "/submirror",
    },
    {
        id: 53,
        title: "FORTUNER WITH INDICATOR",
        description: "CBH-SM035/036",
        price: 107.5,
        model: "TYT",
        submodel: "FORTUNER WITH INDICATOR",
        link: "/submirror",
    },
    {
        id: 54,
        title: "ETIOS",
        description: "CBH-SM049/050",
        price: 93.75,
        model: "TYT",
        submodel: "ETIOS",
        link: "/submirror",
    },
    {
        id: 55,
        title: "LIVA",
        description: "CBH-SM057/058",
        price: 93.75,
        model: "TYT",
        submodel: "LIVA",
        link: "/submirror",
    },
    {
        id: 56,
        title: "ETIOS T2/ALTIS",
        description: "CBH-SM057/058",
        price: 122.5,
        model: "TYT",
        submodel: "ETIOS T2/ALTIS",
        link: "/submirror",
    },
    {
        id: 57,
        title: "INNOVA W/O INDICATOR",
        description: "CBH-SM033/034",
        price: 107.5,
        model: "TYT",
        submodel: "INNOVA W/O INDICATOR",
        link: "/submirror",
    },
    {
        id: 58,
        title: "DUSTER T1",
        description: "CBH-SM037/038",
        price: 107.5,
        model: "RNT",
        submodel: "DUSTER T1",
        link: "/submirror",
    },
    {
        id: 59,
        title: "KWID",
        description: "CBH-SM037/038",
        price: 100,
        model: "RNT",
        submodel: "KWID",
        link: "/submirror",
    },
    {
        id: 60,
        title: "MICRA",
        description: "CBH-SM081/082",
        price: 107.5,
        model: "NSN",
        submodel: "MICRA",
        link: "/submirror",
    },
    {
        id: 61,
        title: "TERRANO",
        description: "CBH-SM081/082",
        price: 107.5,
        model: "NSN",
        submodel: "TERRANO",
        link: "/submirror",
    },
    {
        id: 62,
        title: "PULSE",
        description: "CBH-SM081/082",
        price: 107.5,
        model: "NSN",
        submodel: "PULSE",
        link: "/submirror",
    },
    {
        id: 63,
        title: "SUNNY T1",
        description: "CBH-SM081/082",
        price: 107.5,
        model: "NSN",
        submodel: "SUNNY T1",
        link: "/submirror",
    },
    {
        id: 64,
        title: "LODGY",
        description: "CBH-SM081/082",
        price: 107.5,
        model: "NSN",
        submodel: "LODGY",
        link: "/submirror",
    },
    {
        id: 65,
        title: "POLO T1/T2/VENTO",
        description: "CBH-SM053/054",
        price: 93.75,
        model: "VW",
        submodel: "POLO T1/T2/VENTO",
        link: "/submirror",
    },
]
export const SideMirrorProduct = [
    {
        id: 1,
        title: "OMNI SIDE MIRROR",
        description: "CBH-SM071/072",
        price: 122.5,
        model: "MSZ",
        submodel: "OMNI SIDE MIRROR",
        link: "/sidemirror",
    },
    {
        id: 2,
        title: "EECO SIDE MIRROR",
        description: "CBH-SM073/074",
        price: 158.75,
        model: "MSZ",
        submodel: "EECO SIDE MIRROR",
        link: "/sidemirror",
    },
    {
        id: 3,
        title: "BOLERO SIDE MIRROR",
        description: "CBH-SM075/076",
        price: 143.75,
        model: "MHD",
        submodel: "BOLERO SIDE MIRROR",
        link: "/sidemirror",
    },
]
export const CoolantBottlesCaps = [
    {
        id: 1,
        title: "ESTEEM",
        description: "CBH-CB001",
        price: 150,
        model: "MSZ",
        submodel: "ESTEEM",
        link: "/coolantbottlescaps",
    },
    {
        id: 2,
        title: "M8",
        description: "CBH-CB002",
        price: 100,
        model: "MSZ",
        submodel: "M8",
        link: "/coolantbottlescaps",
    },
    {
        id: 3,
        title: "ALTO",
        description: "CBH-CB004",
        price: 100,
        model: "MSZ",
        submodel: "ALTO",
        link: "/coolantbottlescaps",
    },
    {
        id: 4,
        title: "OMNI/CHAMPION",
        description: "CBH-CB006",
        price: 100,
        model: "MSZ",
        submodel: "OMNI/CHAMPION",
        link: "/coolantbottlescaps",
    },
    {
        id: 5,
        title: "WAGON R",
        description: "CBH-CB008",
        price: 100,
        model: "MSZ",
        submodel: "WAGON R",
        link: "/coolantbottlescaps",
    },
    {
        id: 6,
        title: "EECO",
        description: "CBH-CB010",
        price: 100,
        model: "MSZ",
        submodel: "EECO",
        link: "/coolantbottlescaps",
    },
    {
        id: 7,
        title: "WOGON R K-SERIES",
        description: "CBH-CB011",
        price: 100,
        model: "MSZ",
        submodel: "WOGON R K-SERIES",
        link: "/coolantbottlescaps",
    },
    {
        id: 8,
        title: "SWIFT T3 PETROL",
        description: "CBH-CB012",
        price: 100,
        model: "MSZ",
        submodel: "SWIFT T3 PETROL",
        link: "/coolantbottlescaps",
    },
    {
        id: 9,
        title: "SWIFT T2 DIESEL",
        description: "CBH-CB013",
        price: 500,
        model: "MSZ",
        submodel: "SWIFT T2 DIESEL",
        link: "/coolantbottlescaps",
    },
    {
        id: 10,
        title: "SWIFT T3/4 DZIRE T2/3 / ERTIGA",
        description: "CBH-CB014",
        price: 650,
        model: "MSZ",
        submodel: "SWIFT T3/4 DZIRE T2/3 / ERTIGA",
        link: "/coolantbottlescaps",
    },
    {
        id: 11,
        title: "SANTRO",
        description: "CBH-CB015",
        price: 150,
        model: "HYD",
        submodel: "SANTRO",
        link: "/coolantbottlescaps",
    },
    {
        id: 12,
        title: "INDICA",
        description: "CBH-CB016",
        price: 400,
        model: "TAT",
        submodel: "INDICA",
        link: "/coolantbottlescaps",
    },
    {
        id: 13,
        title: "OIL CAP INDICA",
        description: "CBH-CB019",
        price: 20,
        model: "TAT",
        submodel: "OIL CAP INDICA",
        link: "/coolantbottlescaps",
    },
    {
        id: 14,
        title: "INDICA VISTA QUATRAJET",
        description: "CBH-CB020",
        price: 550,
        model: "TAT",
        submodel: "INDICA VISTA QUATRAJET",
        link: "/coolantbottlescaps",
    },
    {
        id: 15,
        title: "INDICA EV2",
        description: "CBH-CB020",
        price: 400,
        model: "TAT",
        submodel: "INDICA EV2",
        link: "/coolantbottlescaps",
    },
    {
        id: 16,
        title: "TATA ACE",
        description: "CBH-CB021",
        price: 120,
        model: "TAT",
        submodel: "TATA ACE",
        link: "/coolantbottlescaps",
    },
    {
        id: 17,
        title: "QUALIS",
        description: "CBH-CB022",
        price: 250,
        model: "TYT",
        submodel: "QUALIS",
        link: "/coolantbottlescaps",
    },
    {
        id: 18,
        title: "INNOVA T2",
        description: "CBH-CB023",
        price: 450,
        model: "TYT",
        submodel: "INNOVA T2",
        link: "/coolantbottlescaps",
    },
    {
        id: 19,
        title: "INNOVA T3/4",
        description: "CBH-CB025",
        price: 650,
        model: "TYT",
        submodel: "INNOVA T3/4",
        link: "/coolantbottlescaps",
    },
    {
        id: 20,
        title: "FIESTA PETROL/DIESEL",
        description: "CBH-CB026",
        price: 500,
        model: "FRD",
        submodel: "FIESTA PETROL/DIESEL",
        link: "/coolantbottlescaps",
    },
    {
        id: 21,
        title: "FIESTA DIESEL 2 PIPE (N/M)",
        description: "CBH-CB027",
        price: 500,
        model: "FRD",
        submodel: "FIESTA DIESEL 2 PIPE (N/M)",
        link: "/coolantbottlescaps",
    },
    {
        id: 22,
        title: "TAVERA",
        description: "CBH-CB028",
        price: 120,
        model: "GM",
        submodel: "TAVERA",
        link: "/coolantbottlescaps",
    },
    {
        id: 23,
        title: "SPARK",
        description: "CBH-CB029",
        price: 700,
        model: "GM",
        submodel: "SPARK",
        link: "/coolantbottlescaps",
    },
    {
        id: 24,
        title: "AVEO",
        description: "CBH-CB030",
        price: 700,
        model: "GM",
        submodel: "AVEO",
        link: "/coolantbottlescaps",
    },
    {
        id: 25,
        title: "SCORPIO",
        description: "CBH-CB031",
        price: 150,
        model: "MHD",
        submodel: "SCORPIO",
        link: "/coolantbottlescaps",
    },
    {
        id: 26,
        title: "LOGAN/DUSTER",
        description: "CBH-CB033",
        price: 950,
        model: "RNT",
        submodel: "LOGAN/DUSTER",
        link: "/coolantbottlescaps",
    },
    {
        id: 27,
        title: "OCTAVIA",
        description: "CBH-CB034",
        price: 800,
        model: "SKD",
        submodel: "OCTAVIA",
        link: "/coolantbottlescaps",
    },
    {
        id: 28,
        title: "POLO T1",
        description: "CBH-CB035",
        price: 800,
        model: "VW",
        submodel: "POLO T1",
        link: "/coolantbottlescaps",
    },
    {
        id: 29,
        title: "CAP M8",
        description: "CBH-CB003",
        price: 20,
        model: "MSZ",
        submodel: "CAP M8",
        link: "/coolantbottlescaps",
    },
    {
        id: 30,
        title: "CAP ALTO",
        description: "CBH-CB005",
        price: 20,
        model: "MSZ",
        submodel: "CAP ALTO",
        link: "/coolantbottlescaps",
    },
    {
        id: 31,
        title: "CAP ESTEEM",
        description: "CBH-CB036",
        price: 20,
        model: "MSZ",
        submodel: "CAP ESTEEM",
        link: "/coolantbottlescaps",
    },
    {
        id: 32,
        title: "CAP OMNI/CHAMPION",
        description: "CBH-CB007",
        price: 20,
        model: "MSZ",
        submodel: "CAP OMNI/CHAMPION",
        link: "/coolantbottlescaps",
    },
    {
        id: 33,
        title: "CAP SWIFT/INNOVA",
        description: "CBH-CB038",
        price: 70,
        model: "MSZ",
        submodel: "CAP SWIFT/INNOVA",
        link: "/coolantbottlescaps",
    },
    {
        id: 34,
        title: "CAP SANTRO",
        description: "CBH-CB040",
        price: 20,
        model: "HYD",
        submodel: "CAP SANTRO",
        link: "/coolantbottlescaps",
    },
    {
        id: 35,
        title: "CAP INDICA",
        description: "CBH-CB017",
        price: 75,
        model: "TAT",
        submodel: "CAP INDICA",
        link: "/coolantbottlescaps",
    },
    {
        id: 36,
        title: "CAP VISTA QUATRAJET",
        description: "CBH-CB039",
        price: 100,
        model: "TAT",
        submodel: "CAP VISTA QUATRAJET",
        link: "/coolantbottlescaps",
    },
    {
        id: 37,
        title: "CAP SCORPIO",
        description: "CBH-CB032",
        price: 20,
        model: "MHD",
        submodel: "CAP SCORPIO",
        link: "/coolantbottlescaps",
    },
    {
        id: 38,
        title: "CAP FIESTA",
        description: "CBH-CB037",
        price: 100,
        model: "FRD",
        submodel: "CAP FIESTA",
        link: "/coolantbottlescaps",
    },
]
export const wiperBoltProduct = [
    {
        id: 1,
        title: "M8",
        description: "CBH-WB001",
        price: 120,
        model: "MSZ",
        submodel: "M8",
        link: "/wiperbolt",
    },
    {
        id: 2,
        title: "CAP M8",
        description: "CBH-WB002",
        price: 20,
        model: "MSZ",
        submodel: "CAP M8",
        link: "/wiperbolt",
    },
    {
        id: 3,
        title: "EECO/VERSA",
        description: "CBH-WB003",
        price: 160,
        model: "MSZ",
        submodel: "EECO/VERSA",
        link: "/wiperbolt",
    },
    {
        id: 4,
        title: "OMNI",
        description: "CBH-WB004",
        price: 100,
        model: "MSZ",
        submodel: "OMNI",
        link: "/wiperbolt",
    },
    {
        id: 5,
        title: "ALTO",
        description: "CBH-WB005",
        price: 180,
        model: "MSZ",
        submodel: "ALTO",
        link: "/wiperbolt",
    },
    {
        id: 6,
        title: "ALTO 800",
        description: "CBH-WB024",
        price: 175,
        model: "MSZ",
        submodel: "ALTO 800",
        link: "/wiperbolt",
    },
    {
        id: 7,
        title: "RITZ",
        description: "CBH-WB027",
        price: 500,
        model: "MSZ",
        submodel: "RITZ",
        link: "/wiperbolt",
    },
    {
        id: 8,
        title: "BALENO SINGLE HOLE",
        description: "CBH-WB026",
        price: 250,
        model: "MSZ",
        submodel: "BALENO SINGLE HOLE",
        link: "/wiperbolt",
    },
    {
        id: 9,
        title: "BALENO DOUBLE HOLE",
        description: "CBH-WB031",
        price: 250,
        model: "MSZ",
        submodel: "BALENO DOUBLE HOLE",
        link: "/wiperbolt",
    },
    {
        id: 10,
        title: "CELERIO",
        description: "CBH-WB033",
        price: 300,
        model: "MSZ",
        submodel: "CELERIO",
        link: "/wiperbolt",
    },
    {
        id: 11,
        title: "DZIRE O/M",
        description: "CBH-WB032",
        price: 250,
        model: "MSZ",
        submodel: "DZIRE O/M",
        link: "/wiperbolt",
    },
    {
        id: 12,
        title: "DZIRE N/M",
        description: "CBH-WB028",
        price: 250,
        model: "MSZ",
        submodel: "DZIRE N/M",
        link: "/wiperbolt",
    },
    {
        id: 13,
        title: "BREZZA",
        description: "CBH-WB023",
        price: 250,
        model: "MSZ",
        submodel: "BREZZA",
        link: "/wiperbolt",
    },
    {
        id: 14,
        title: "CIAZ",
        description: "CBH-WB025",
        price: 250,
        model: "MSZ",
        submodel: "CIAZ",
        link: "/wiperbolt",
    },
    {
        id: 15,
        title: "SX-4",
        description: "CBH-WB034",
        price: 250,
        model: "MSZ",
        submodel: "SX-4",
        link: "/wiperbolt",
    },
    {
        id: 16,
        title: "S-CROSS",
        description: "CBH-WB035",
        price: 250,
        model: "MSZ",
        submodel: "S-CROSS",
        link: "/wiperbolt",
    },
    {
        id: 17,
        title: "SANTRO SINGLE/DOUBLE HOLE",
        description: "CBH-WB006",
        price: 275,
        model: "HYD",
        submodel: "SANTRO SINGLE/DOUBLE HOLE",
        link: "/wiperbolt",
    },
    {
        id: 18,
        title: "I-10",
        description: "CBH-WB007",
        price: 500,
        model: "HYD",
        submodel: "I-10",
        link: "/wiperbolt",
    },
    {
        id: 19,
        title: "INDICA",
        description: "CBH-WB008",
        price: 250,
        model: "TAT",
        submodel: "INDICA",
        link: "/wiperbolt",
    },
    {
        id: 20,
        title: "INDICA PIPE",
        description: "CBH-WB009",
        price: 100,
        model: "TAT",
        submodel: "INDICA PIPE",
        link: "/wiperbolt",
    },
    {
        id: 21,
        title: "INDICA VISTA",
        description: "CBH-WB010",
        price: 250,
        model: "TAT",
        submodel: "INDICA VISTA",
        link: "/wiperbolt",
    },
    {
        id: 22,
        title: "INDICA VISTA PIPE",
        description: "CBH-WB011",
        price: 100,
        model: "TAT",
        submodel: "INDICA VISTA PIPE",
        link: "/wiperbolt",
    },
    {
        id: 23,
        title: "SCORPIO SINGLE/DOUBLE HOLE",
        description: "CBH-WB012",
        price: 250,
        model: "MHD",
        submodel: "SCORPIO SINGLE/DOUBLE HOLE",
        link: "/wiperbolt",
    },
    {
        id: 24,
        title: "HECTOR",
        description: "CBH-WB037",
        price: 600,
        model: "MG",
        submodel: "HECTOR",
        link: "/wiperbolt",
    },
    {
        id: 25,
        title: "INNOVA T1/2/3/4",
        description: "CBH-WB013",
        price: 1200,
        model: "TYT",
        submodel: "INNOVA T1/2/3/4",
        link: "/wiperbolt",
    },
    {
        id: 26,
        title: "INNOVA CRYSTA/FORTUNER T4",
        description: "CBH-WB019",
        price: 1000,
        model: "TYT",
        submodel: "INNOVA CRYSTA/FORTUNER T4",
        link: "/wiperbolt",
    },
    {
        id: 27,
        title: "ETIOS/LIVA",
        description: "CBH-WB020",
        price: 1000,
        model: "TYT",
        submodel: "ETIOS/LIVA",
        link: "/wiperbolt",
    },
    {
        id: 28,
        title: "POLO T1",
        description: "CBH-WB014",
        price: 850,
        model: "VW",
        submodel: "POLO T1",
        link: "/wiperbolt",
    },
    {
        id: 29,
        title: "POLO T2",
        description: "CBH-WB015",
        price: 850,
        model: "VW",
        submodel: "POLO T2",
        link: "/wiperbolt",
    },
    {
        id: 30,
        title: "AMAZE T1/2",
        description: "CBH-WB029",
        price: 250,
        model: "HND",
        submodel: "AMAZE T1/2",
        link: "/wiperbolt",
    },
    {
        id: 31,
        title: "AMAZE T3",
        description: "CBH-WB030",
        price: 400,
        model: "HND",
        submodel: "AMAZE T3",
        link: "/wiperbolt",
    },
    {
        id: 32,
        title: "CITY T3/4",
        description: "CBH-WB016",
        price: 700,
        model: "HND",
        submodel: "CITY T3/4",
        link: "/wiperbolt",
    },
    {
        id: 33,
        title: "DUSTER",
        description: "CBH-WB036",
        price: 1000,
        model: "RNT",
        submodel: "DUSTER",
        link: "/wiperbolt",
    },
]


export const bonnetProduct = [
    {
        id: 1,
        title: "CITY T5/T6",
        description: "CBH-BN002",
        price: 8000,
        model: "TYT",
        submodel: "CITY T5/T6",
        link: "/bonnet",
    },
    {
        id: 2,
        title: "CITY T7",
        description: "CBH-BN003",
        price: 8500,
        model: "TYT",
        submodel: "CITY T7",
        link: "/bonnet",
    },
    {
        id: 3,
        title: "INNOVA T3/4",
        description: "CBH-BN001",
        price: 7000,
        model: "TYT",
        submodel: "INNOVA T3/4",
        link: "/bonnet",
    },
    {
        id: 4,
        title: "FORTUNER T2",
        description: "CBH-BN004",
        price: 8500,
        model: "TYT",
        submodel: "FORTUNER T2",
        link: "/bonnet",
    },
    {
        id: 5,
        title: "POLO/VENTO",
        description: "CBH-BN005",
        price: 8500,
        model: "VW",
        submodel: "POLO/VENTO",
        link: "/bonnet",
    },
]

export const FENDERProduct = [
    {
        id: 1,
        title: "CITY T5 WITH HOLE RH/LH",
        description: "CBH-FD017/018",
        price: 3500,
        model: "HND",
        submodel: "CITY T5 WITH HOLE RH/LH",
        link: "/fender",
    },
    {
        id: 2,
        title: "CITY T6 W/O HOLE RH/LH",
        description: "CBH-FD005/006",
        price: 3500,
        model: "HND",
        submodel: "CITY T6 W/O HOLE RH/LH",
        link: "/fender",
    },
    {
        id: 3,
        title: "CITY T7 W/HOLE RH/LH",
        description: "CBH-FD009/010",
        price: 3950,
        model: "HND",
        submodel: "CITY T7 W/HOLE RH/LH",
        link: "/fender",
    },
    {
        id: 4,
        title: "CITY T7 W/O HOLE RH/LH",
        description: "CBH-FD007/008",
        price: 3950,
        model: "HND",
        submodel: "CITY T7 W/O HOLE RH/LH",
        link: "/fender",
    },
    {
        id: 5,
        title: "INNOVA T3/4 W/HOLE RH/LH",
        description: "CBH-FD003/004",
        price: 3700,
        model: "TYT",
        submodel: "INNOVA T3/4 W/HOLE RH/LH",
        link: "/fender",
    },
    {
        id: 6,
        title: "INNOVA T3/4 W/O HOLE RH/LH",
        description: "CBH-FD001/002",
        price: 3700,
        model: "TYT",
        submodel: "INNOVA T3/4 W/O HOLE RH/LH",
        link: "/fender",
    },
    {
        id: 7,
        title: "FORTUNER T2 RH/LH",
        description: "CBH-FD011/012",
        price: 3125,
        model: "TYT",
        submodel: "FORTUNER T2 RH/LH",
        link: "/fender",
    },
    {
        id: 8,
        title: "POLO T1 W/HOLE RH/LH",
        description: "CBH-FD015/016",
        price: 3450,
        model: "VW",
        submodel: "POLO T1 W/HOLE RH/LH",
        link: "/fender",
    },
    {
        id: 9,
        title: "POLO T2 W/O HOLE RH/LH",
        description: "CBH-FD013/014",
        price: 3450,
        model: "VW",
        submodel: "POLO T2 W/O HOLE RH/LH",
        link: "/fender",
    },
]
export const CLADDINGProduct = [
    {
        id: 1,
        title: "BOLERO MAXI TRUCK WHITE RH/LH",
        description: "CBH-CL001/002",
        price: 200,
        model: "MHD",
        submodel: "BOLERO MAXI TRUCK WHITE RH/LH",
        link: "/cladding",
    },
    {
        id: 2,
        title: "BOLERO PICKUP FB WHITE RH/LH",
        description: "CBH-CL003/004",
        price: 250,
        model: "MHD",
        submodel: "BOLERO PICKUP FB WHITE RH/LH",
        link: "/cladding",
    },
    {
        id: 3,
        title: "BOLERO CAMPER WHITE RH/LH",
        description: "CBH-CL005/006",
        price: 225,
        model: "MHD",
        submodel: "BOLERO CAMPER WHITE RH/LH",
        link: "/cladding",
    },
    {
        id: 4,
        title: "BOLERO MAXI TRUCK GREY RH/LH",
        description: "CBH-CL017/018",
        price: 300,
        model: "MHD",
        submodel: "BOLERO MAXI TRUCK GREY RH/LH",
        link: "/cladding",
    },
    {
        id: 5,
        title: "BOLERO PICKUP FB GREY RH/LH",
        description: "CBH-CL015/016",
        price: 350,
        model: "MHD",
        submodel: "BOLERO PICKUP FB GREY RH/LH",
        link: "/cladding",
    },
    {
        id: 6,
        title: "BOLERO CAMPER GREY RH/LH",
        description: "CBH-CL019/020",
        price: 300,
        model: "MHD",
        submodel: "BOLERO CAMPER GREY RH/LH",
        link: "/cladding",
    },
    {
        id: 7,
        title: "BOLERO SLE (SET OF 4) - BLACK RH/LH",
        description: "CBH-CL021",
        price: 1450,
        model: "MHD",
        submodel: "BOLERO SLE (SET OF 4) - BLACK RH/LH",
        link: "/cladding",
    },
    {
        id: 8,
        title: "BOLERO SLE FRONT (SET OF 2) - BLACK RH/LH",
        description: "CBH-CL022",
        price: 800,
        model: "MHD",
        submodel: "BOLERO SLE FRONT (SET OF 2) - BLACK RH/LH",
        link: "/cladding",
    },
    {
        id: 9,
        title: "BOLERO SLE REAR (SET OF 2) - BLACK RH/LH",
        description: "CBH-CL023",
        price: 800,
        model: "MHD",
        submodel: "BOLERO SLE REAR (SET OF 2) - BLACK RH/LH",
        link: "/cladding",
    },
    {
        id: 10,
        title: "BOLERO SLE (SET OF 4) - WHITE RH/LH",
        description: "CBH-CL024",
        price: 1800,
        model: "MHD",
        submodel: "BOLERO SLE (SET OF 4) - WHITE RH/LH",
        link: "/cladding",
    },
    {
        id: 11,
        title: "BOLERO SLE FRONT (SET OF 2) - WHITE RH/LH",
        description: "CBH-CL025",
        price: 900,
        model: "MHD",
        submodel: "BOLERO SLE FRONT (SET OF 2) - WHITE RH/LH",
        link: "/cladding",
    },
    {
        id: 12,
        title: "BOLERO SLE REAR (SET OF 2) - WHITE RH/LH",
        description: "CBH-CL026",
        price: 900,
        model: "MHD",
        submodel: "BOLERO SLE REAR (SET OF 2) - WHITE RH/LH",
        link: "/cladding",
    },
    {
        id: 13,
        title: "FORTUNER T2 RH/LH",
        description: "CBH-CL007/008",
        price: 1400,
        model: "TYT",
        submodel: "FORTUNER T2 RH/LH",
        link: "/cladding",
    },
]
export const otherPartsProduct = [
    {
        id: 1,
        title: "OIL CHAMBER KWID",
        description: "CBH-OC001",
        price: 900,
        model: "RNT",
        submodel: "OIL CHAMBER KWID",
        link: "/other",
    },
    {
        id: 2,  // Starting from 2
        title: "HOOD/BONNET GARNISH INNOVA T3/4",
        description: "CBH-BN008",
        price: 550,
        model: "TYT",
        submodel: "HOOD/BONNET GARNISH INNOVA T3/4",
        link: "/other",  // Set link to '/other'
    },
    {
        id: 3,  // Starting from 3
        title: "HOOD HINGE INNOVA",
        description: "CBH-BN009/010",
        price: 575,
        model: "TYT",
        submodel: "HOOD HINGE INNOVA",
        link: "/other",  // Set link to '/other'
    },
    {
        id: 4,  // Starting from 4
        title: "HOOD GARNISH SAFARI STORME",
        description: "CBH-BN011",
        price: 2700,
        model: "TAT",
        submodel: "HOOD GARNISH SAFARI STORME",
        link: "/other",  // Set link to '/other'
    },
    {
        id: 5,
        title: "TRUNK GARNISH WAGONR T5",
        description: "CBH-TG007",
        price: 1250,
        model: "MSZ",
        submodel: "TRUNK GARNISH WAGONR T5",
        link: "/other",  // Link set to '/other'
    },
    {
        id: 6,
        title: "TRUNK GARNISH BALENO",
        description: "CBH-TG001",
        price: 1750,
        model: "MSZ",
        submodel: "TRUNK GARNISH BALENO",
        link: "/other",
    },
    {
        id: 7,
        title: "TRUNK GARNISH CIAZ",
        description: "CBH-TG003",
        price: 500,
        model: "MSZ",
        submodel: "TRUNK GARNISH CIAZ",
        link: "/other",
    },
    {
        id: 8,
        title: "TRUNK GARNISH DZIRE T1 OEM QLTY",
        description: "TRUNK GARNISH DZIRE T1 OEM QLTY",
        price: 1100,
        model: "MSZ",
        submodel: "TRUNK GARNISH DZIRE T1 OEM QLTY",
        link: "/other",
    },
    {
        id: 9,
        title: "TRUNK GARNISH DZIRE T3",
        description: "CBH-TG006",
        price: 575,
        model: "MSZ",
        submodel: "TRUNK GARNISH DZIRE T3",
        link: "/other",
    },
    {
        id: 10,
        title: "TRUNK GARNISH DZIRE T3 OEM QLTY",
        description: "CBH-TG005",
        price: 1050,
        model: "MSZ",
        submodel: "TRUNK GARNISH DZIRE T3 OEM QLTY",
        link: "/other",
    },
    {
        id: 11,
        title: "TRUNK GARNISH DZIRE T4",
        description: "CBH-TG002",
        price: 1625,
        model: "MSZ",
        submodel: "TRUNK GARNISH DZIRE T4",
        link: "/other",
    },
    {
        id: 12,
        title: "TRUNK GARNISH BREZZA",
        description: "CBH-TG009",
        price: 1350,
        model: "MSZ",
        submodel: "TRUNK GARNISH BREZZA",
        link: "/other",
    },
    {
        id: 13,
        title: "TRUNK GARNISH SCORPIO S-SERIES - BLACK",
        description: "CBH-TG008",
        price: 1550,
        model: "MHD",
        submodel: "TRUNK GARNISH SCORPIO S-SERIES - BLACK",
        link: "/other",
    },
    {
        id: 14,
        title: "TRUNK GARNISH WRV",
        description: "CBH-TG004",
        price: 1375,
        model: "HND",
        submodel: "TRUNK GARNISH WRV",
        link: "/other",
    },
]

export const wiperBladeProduct = [
    {
        id: 1,
        title: "12\"",
        description: "CBH-WB 001",
        price: 125,
        model: "UNIVERSAL",
        submodel: "12",
        link: "/wiperblade",
    },
    {
        id: 2,
        title: "13\"",
        description: "CBH-WB 002",
        price: 125,
        model: "UNIVERSAL",
        submodel: "13",
        link: "/wiperblade",
    },
    {
        id: 3,
        title: "14\"",
        description: "CBH-WB 003",
        price: 125,
        model: "UNIVERSAL",
        submodel: "14",
        link: "/wiperblade",
    },
    {
        id: 4,
        title: "15\"",
        description: "CBH-WB 004",
        price: 125,
        model: "UNIVERSAL",
        submodel: "15",
        link: "/wiperblade",
    },
    {
        id: 5,
        title: "16\"",
        description: "CBH-WB 005",
        price: 125,
        model: "UNIVERSAL",
        submodel: "16",
        link: "/wiperblade",
    },
    {
        id: 6,
        title: "17\"",
        description: "CBH-WB 006",
        price: 150,
        model: "UNIVERSAL",
        submodel: "17",
        link: "/wiperblade",
    },
    {
        id: 7,
        title: "18\"",
        description: "CBH-WB 007",
        price: 150,
        model: "UNIVERSAL",
        submodel: "18",
        link: "/wiperblade",
    },
    {
        id: 8,
        title: "19\"",
        description: "CBH-WB 008",
        price: 200,
        model: "UNIVERSAL",
        submodel: "19",
        link: "/wiperblade",
    },
    {
        id: 9,
        title: "20\"",
        description: "CBH-WB 009",
        price: 200,
        model: "UNIVERSAL",
        submodel: "20",
        link: "/wiperblade",
    },
    {
        id: 10,
        title: "21\"",
        description: "CBH-WB 010",
        price: 200,
        model: "UNIVERSAL",
        submodel: "21",
        link: "/wiperblade",
    },
    {
        id: 11,
        title: "22\"",
        description: "CBH-WB 011",
        price: 200,
        model: "UNIVERSAL",
        submodel: "22",
        link: "/wiperblade",
    },
    {
        id: 12,
        title: "24\"",
        description: "CBH-WB 012",
        price: 200,
        model: "UNIVERSAL",
        submodel: "24",
        link: "/wiperblade",
    },
    {
        id: 13,
        title: "26\"",
        description: "CBH-WB 013",
        price: 250,
        model: "UNIVERSAL",
        submodel: "26",
        link: "/wiperblade",
    },
]
export const REARWIPERBLADEProduct = [
    {
        id: 1,
        title: "REAR WIPER - TIAGO",
        description: "CBH-RWB 001",
        price: 250,
        model: "TAT",
        submodel: "REAR WIPER - TIAGO",
        link: "/rearwiperblade",
    },
    {
        id: 2,
        title: "REAR WIPER - HEXA",
        description: "CBH-RWB 002",
        price: 250,
        model: "TAT",
        submodel: "REAR WIPER - HEXA",
        link: "/rearwiperblade",
    },
    {
        id: 3,
        title: "REAR WIPER - 33333333333",
        description: "CBH-RWB 003",
        price: 250,
        model: "TAT",
        submodel: "REAR WIPER - 33333333333",
        link: "/rearwiperblade",
    },
    {
        id: 4,
        title: "REAR WIPER - HARRIER",
        description: "CBH-RWB 004",
        price: 250,
        model: "TAT",
        submodel: "REAR WIPER - HARRIER",
        link: "/rearwiperblade",
    },
    {
        id: 5,
        title: "REAR WIPER - NEXON",
        description: "CBH-RWB 005",
        price: 250,
        model: "TAT",
        submodel: "REAR WIPER - NEXON",
        link: "/rearwiperblade",
    },
    {
        id: 6,
        title: "REAR WIPER - FIGO",
        description: "CBH-RWB 005",
        price: 250,
        model: "FRD",
        submodel: "REAR WIPER - FIGO",
        link: "/rearwiperblade",
    },
    {
        id: 7,
        title: "REAR WIPER - FIGO NEW",
        description: "CBH-RWB 006",
        price: 250,
        model: "FRD",
        submodel: "REAR WIPER - FIGO NEW",
        link: "/rearwiperblade",
    },
    {
        id: 8,
        title: "REAR WIPER - ECOSPORT NEW",
        description: "CBH-RWB 007",
        price: 250,
        model: "FRD",
        submodel: "REAR WIPER - ECOSPORT NEW",
        link: "/rearwiperblade",
    },
    {
        id: 9,
        title: "REAR WIPER - ECOSPORT",
        description: "CBH-RWB 008",
        price: 250,
        model: "FRD",
        submodel: "REAR WIPER - ECOSPORT",
        link: "/rearwiperblade",
    },
    {
        id: 10,
        title: "REAR WIPER - ENDEAVOUR",
        description: "CBH-RWB 009",
        price: 250,
        model: "FRD",
        submodel: "REAR WIPER - ENDEAVOUR",
        link: "/rearwiperblade",
    },
    {
        id: 11,
        title: "REAR WIPER - FIESTA",
        description: "CBH-RWB 010",
        price: 250,
        model: "FRD",
        submodel: "REAR WIPER - FIESTA",
        link: "/rearwiperblade",
    },
    {
        id: 12,
        title: "REAR WIPER - ENDEAVOUR TITANIUM",
        description: "CBH-RWB 011",
        price: 250,
        model: "FRD",
        submodel: "REAR WIPER - ENDEAVOUR TITANIUM",
        link: "/rearwiperblade",
    },
    {
        id: 13,
        title: "REAR WIPER - WRV",
        description: "CBH-RWB 012",
        price: 250,
        model: "HND",
        submodel: "REAR WIPER - WRV",
        link: "/rearwiperblade",
    },
    {
        id: 14,
        title: "REAR WIPER - CRV LATEST",
        description: "CBH-RWB 013",
        price: 250,
        model: "HND",
        submodel: "REAR WIPER - CRV LATEST",
        link: "/rearwiperblade",
    },
    {
        id: 15,
        title: "REAR WIPER - JAZZ",
        description: "CBH-RWB 014",
        price: 250,
        model: "HND",
        submodel: "REAR WIPER - JAZZ",
        link: "/rearwiperblade",
    },
    {
        id: 16,
        title: "REAR WIPER - ENJOY",
        description: "CBH-RWB 015",
        price: 250,
        model: "GM",
        submodel: "REAR WIPER - ENJOY",
        link: "/rearwiperblade",
    },
    {
        id: 17,
        title: "REAR WIPER - BEAT",
        description: "CBH-RWB 016",
        price: 250,
        model: "GM",
        submodel: "REAR WIPER - BEAT",
        link: "/rearwiperblade",
    },
    {
        id: 18,
        title: "REAR WIPER - PUNTO",
        description: "CBH-RWB 017",
        price: 250,
        model: "Ft",
        submodel: "REAR WIPER - PUNTO",
        link: "/rearwiperblade",
    },
    {
        id: 19,
        title: "REAR WIPER - PALIO",
        description: "CBH-RWB 018",
        price: 250,
        model: "Ft",
        submodel: "REAR WIPER - PALIO",
        link: "/rearwiperblade",
    },
    {
        id: 20,
        title: "REAR WIPER - MICRA",
        description: "CBH-RWB 019",
        price: 250,
        model: "NSN",
        submodel: "REAR WIPER - MICRA",
        link: "/rearwiperblade",
    },
    {
        id: 21,
        title: "REAR WIPER - XTRAIL OLD",
        description: "CBH-RWB 020",
        price: 250,
        model: "NSN",
        submodel: "REAR WIPER - XTRAIL OLD",
        link: "/rearwiperblade",
    },
    {
        id: 22,
        title: "REAR WIPER - XTRAIL NEW",
        description: "CBH-RWB 021",
        price: 250,
        model: "NSN",
        submodel: "REAR WIPER - XTRAIL NEW",
        link: "/rearwiperblade",
    },
    {
        id: 23,
        title: "REAR WIPER - LODGY",
        description: "CBH-RWB 022",
        price: 250,
        model: "RNT",
        submodel: "REAR WIPER - LODGY",
        link: "/rearwiperblade",
    },
    {
        id: 24,
        title: "REAR WIPER - DUSTER NEW",
        description: "CBH-RWB 023",
        price: 250,
        model: "RNT",
        submodel: "REAR WIPER - DUSTER NEW",
        link: "/rearwiperblade",
    },
    {
        id: 25,
        title: "REAR WIPER - WAGONR",
        description: "CBH-RWB 024",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - WAGONR",
        link: "/rearwiperblade",
    },
    {
        id: 26,
        title: "REAR WIPER - SWIFT",
        description: "CBH-RWB 025",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - SWIFT",
        link: "/rearwiperblade",
    },
    {
        id: 27,
        title: "REAR WIPER - S CROSS",
        description: "CBH-RWB 026",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - S CROSS",
        link: "/rearwiperblade",
    },
    {
        id: 28,
        title: "REAR WIPER - ERTIGA",
        description: "CBH-RWB 027",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - ERTIGA",
        link: "/rearwiperblade",
    },
    {
        id: 29,
        title: "REAR WIPER - ZEN ESTILO",
        description: "CBH-RWB 028",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - ZEN ESTILO",
        link: "/rearwiperblade",
    },
    {
        id: 30,
        title: "REAR WIPER - IGNIS",
        description: "CBH-RWB 029",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - IGNIS",
        link: "/rearwiperblade",
    },
    {
        id: 31,
        title: "REAR WIPER - BALENO",
        description: "CBH-RWB 030",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - BALENO",
        link: "/rearwiperblade",
    },
    {
        id: 32,
        title: "REAR WIPER - BALENO NEW",
        description: "CBH-RWB 031",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - BALENO NEW",
        link: "/rearwiperblade",
    },
    {
        id: 33,
        title: "REAR WIPER - GRAND VITARA",
        description: "CBH-RWB 032",
        price: 250,
        model: "MSZ",
        submodel: "REAR WIPER - GRAND VITARA",
        link: "/rearwiperblade",
    },
    {
        id: 34,
        title: "REAR WIPER - I10",
        description: "CBH-RWB 033",
        price: 250,
        model: "HYD",
        submodel: "REAR WIPER - I10",
        link: "/rearwiperblade",
    },
    {
        id: 35,
        title: "REAR WIPER - I10 GRAND",
        description: "CBH-RWB 034",
        price: 250,
        model: "HYD",
        submodel: "REAR WIPER - I10 GRAND",
        link: "/rearwiperblade",
    },
    {
        id: 36,
        title: "REAR WIPER - I20",
        description: "CBH-RWB 035",
        price: 250,
        model: "HYD",
        submodel: "REAR WIPER - I20",
        link: "/rearwiperblade",
    },
    {
        id: 37,
        title: "REAR WIPER - I20 ELITE",
        description: "CBH-RWB 036",
        price: 250,
        model: "HYD",
        submodel: "REAR WIPER - I20 ELITE",
        link: "/rearwiperblade",
    },
    {
        id: 38,
        title: "REAR WIPER - CRETA",
        description: "CBH-RWB 037",
        price: 250,
        model: "HYD",
        submodel: "REAR WIPER - CRETA",
        link: "/rearwiperblade",
    },
    {
        id: 39,
        title: "REAR WIPER - ETIOS LIVA",
        description: "CBH-RWB 038",
        price: 250,
        model: "TYT",
        submodel: "REAR WIPER - ETIOS LIVA",
        link: "/rearwiperblade",
    },
    {
        id: 40,
        title: "REAR WIPER - FORTUNER",
        description: "CBH-RWB 039",
        price: 250,
        model: "TYT",
        submodel: "REAR WIPER - FORTUNER",
        link: "/rearwiperblade",
    },
    {
        id: 41,
        title: "REAR WIPER - INNOVA",
        description: "CBH-RWB 040",
        price: 250,
        model: "TYT",
        submodel: "REAR WIPER - INNOVA",
        link: "/rearwiperblade",
    },
    {
        id: 42,
        title: "REAR WIPER - XUV 500",
        description: "CBH-RWB 041",
        price: 250,
        model: "MHD",
        submodel: "REAR WIPER - XUV 500",
        link: "/rearwiperblade",
    },
    {
        id: 43,
        title: "REAR WIPER - TUV 300",
        description: "CBH-RWB 042",
        price: 250,
        model: "MHD",
        submodel: "REAR WIPER - TUV 300",
        link: "/rearwiperblade",
    },
    {
        id: 44,
        title: "REAR WIPER - FABIA",
        description: "CBH-RWB 043",
        price: 250,
        model: "SKD",
        submodel: "REAR WIPER - FABIA",
        link: "/rearwiperblade",
    },
    {
        id: 45,
        title: "REAR WIPER - KODIAQ",
        description: "CBH-RWB 044",
        price: 250,
        model: "SKD",
        submodel: "REAR WIPER - KODIAQ",
        link: "/rearwiperblade",
    },
    {
        id: 46,
        title: "REAR WIPER - POLO",
        description: "CBH-RWB 045",
        price: 250,
        model: "SKD",
        submodel: "REAR WIPER - POLO",
        link: "/rearwiperblade",
    },
    {
        id: 47,
        title: "REAR WIPER - TIGUAN",
        description: "CBH-RWB 046",
        price: 250,
        model: "SKD",
        submodel: "REAR WIPER - TIGUAN",
        link: "/rearwiperblade",
    },
]



