import React from "react";
import { FaPhoneAlt } from "react-icons/fa";

const CallButton = () => {
  return (
    <a
      href="tel:+919351014634"
      className="fixed bottom-20 right-6 bg-blue-600 text-white p-3 rounded-full shadow-lg flex items-center justify-center hover:bg-blue-700 transition duration-300"
    >
      <FaPhoneAlt className="text-xl" />
    </a>
  );
};

export default CallButton;
